import React, { ChangeEvent } from 'react';
import CSS from 'csstype';
import { useTranslation } from 'react-i18next';

const bRadius = '10px';
const bColor = '#ced4da';
const iColor = '#000000';
const icColor = '#243a76';

interface ICustomSearchBoxStyles {
    button?: CSS.Properties,
    icon?: CSS.Properties,
    input?: CSS.Properties;
}

interface ICustomSearchBox {
    customStyles?: ICustomSearchBoxStyles,
    name?: string,
    id?: string,
    // value: string,
    placeholder?: string,
    borderRadius?: string,
    borderColor?: string,
    inputColor?: string,
    iconColor?: string,
    defaultValue?: string | number,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const getStyles = (
    borderColor = bColor,
    borderRadius = bRadius,
    inputColor = iColor,
    iconColor = icColor
): ICustomSearchBoxStyles => {
    const styles: ICustomSearchBoxStyles = {
        button: {
            borderColor,
            borderRight: 'none',
            borderTopLeftRadius: borderRadius,
            borderBottomLeftRadius: borderRadius,
            zIndex: 0
        },
        icon: {
            fontSize: '1rem',
            color: iconColor
        },
        input: {
            borderLeft: 'none',
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            borderColor,
            color: inputColor
        }
    };
    return styles;
};

const CustomSearchBox = ({ customStyles, onChange, name, id, placeholder, borderRadius, borderColor, inputColor, iconColor, defaultValue }: ICustomSearchBox) => {
    const { t } = useTranslation();
    const updatedStyles: ICustomSearchBoxStyles = {
        ...getStyles(borderColor, borderRadius, inputColor, iconColor),
        ...customStyles
    };

    return (
        <div className="input-group mb-3">
            <button disabled className="btn" type="button" id="button-addon1" style={updatedStyles.button}>
                <i className="bi bi-search" style={updatedStyles.icon}></i>
            </button>
            <input
                style={updatedStyles.input}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (onChange) {
                        onChange(e);
                    }
                }}
                type="text"
                className="form-control"
                placeholder={placeholder || t("common:search")}
                aria-label="Search"
                aria-describedby="search-input"
                name={name || 'search'}
                id={id || 'CustomSearchBox'}
                defaultValue={defaultValue || undefined}
            />
        </div>
    );
};

export default CustomSearchBox;

import EventEmitter from 'eventemitter3'
export const $showNotification = new EventEmitter()
export const $dismissNotification = new EventEmitter()
export const $editNotification = new EventEmitter()

type notificationSystemLevel = 'success' | 'error' | 'warning' | 'info'

/**
* Show notification
*
* @param {notificationSystemLevel} level
* @param {String} message
* @param {String} [uid]
* @param {Number=} timeout - 4, Hide notification after timeout
*/
export const showNotification = (
  level: notificationSystemLevel,
  message: string,
  uid?: string,
  timeout: number | undefined = 4
) => {
  console.log('showNotification', new Date())
  $showNotification.emit('showNotification', { level, message, uid, timeout })
}

export const editNotification = (uid: string, message: string) => {
  $editNotification.emit('editNotification', { uid, message })
}

export const dismissNotification = (uid: string) => {
  $dismissNotification.emit('dismissNotification', uid)
}

import { postData, getData, getDataWithQuery, deleteData } from '../api';

import { API_END_POINT } from '../constants';

export const saveContact = (data: any) => {
    return postData(API_END_POINT.contact.save, data);
};

export const getContactById = (id: string) => {
    return getData(API_END_POINT.contact.getById, id);
};

export const getAllContact = (data: any) => {
    return getDataWithQuery(API_END_POINT.contact.getAll, data);
};

export const deleteContact = (id: string) => {
    return deleteData(API_END_POINT.contact.delete, id);
};

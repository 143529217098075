import React from 'react'
import Modal from 'react-modal';

interface IPopupStyle {
    content: React.CSSProperties,
    overlay: React.CSSProperties
}

interface IPopupProps {
    isOpen: boolean,
    children: React.ReactNode,
    onCloseModal: () => void,
    customStyles?: Partial<IPopupStyle>
}

const defaultCustomStyles = {
    content: {
      top: '50px',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: 9
    }
  };

const Popup = ({ isOpen, children, onCloseModal, customStyles }: IPopupProps) => {
    const styles = {
        ...defaultCustomStyles,
        ...customStyles,
        content: {
            ...defaultCustomStyles.content,
            ...customStyles?.content
        }
    }

  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        style={styles}
        contentLabel="Modal"
      >
        {children}
      </Modal>
  )
}

export default Popup

import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import InputCtrl from '../../controllers/InputCtrl';
import SelectCtrl from '../../controllers/SelectCtrl';
import { IApiCallStatus } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getAllCategory, saveManualJournal, saveTransaction } from '../../services';
import { DEFAULT_PAGE_SIZE, dismissNotification, groupArray, resetAppbar, showNotification, updateAppbar } from '../../utils';
const PaymentDetails = (props: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        formState: { errors },
        getValues
    } = useForm({
        mode: 'onBlur',
        defaultValues: useMemo(() => {
            return {
                categoryId: '',
                date: '',
                amount: props.transactionDetails?.amount
            };
        }, [props.transactionDetails?.amount])
    });

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });
    const [categories, setCategories] = useState<any[]>([]);
    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };
    useEffect(() => {
        fetchAllCategory();
        updateAppbar({
            leftLink: '/transaction',
            leftIcon: 'Back',
            title: t("common:make_payment"),
            showAddIcon: false,
            addLink: ''
        });
        return () => {
            resetAppbar();
        };
    }, []);
    const fetchAllCategory = async (
        pageNumber = 1,
        query = '',
        pageSize = DEFAULT_PAGE_SIZE
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                query,
                pageNumber,
                pageSize
            };
            const usersResponse = await getAllCategory(data);
            if (usersResponse.status === 200) {
                const filtered = usersResponse.data.items?.reduce((result: { trans: any[], payment: any[] }, { type, category, _id, typeLabel }: any) => {
                    if (!result.trans) result.trans = [];
                    if (!result.payment) result.payment = [];
                    if (['Income', 'Expense'].includes(type)) {
                        result.trans.push({
                            label: category,
                            value: _id,
                            typeLabel: typeLabel
                        });
                    } else if (['Asset', 'Equity', 'Liability'].includes(type)) {
                        result.payment.push({
                            label: category,
                            value: _id,
                            typeLabel: typeLabel
                        });
                    }
                    return result;
                }, { trans: [], payment: [] });
                console.log('filtered', filtered)
                const groupLisPayment = groupArray(filtered.payment, 'typeLabel', 'options', 'label');
                setCategories(groupLisPayment);
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };
    const onSubmit = async () => {
        const values = getValues();
        const transDetails = props.transactionDetails
        const manualJournalEntries = []
        if (transDetails.type === 'Expense') {
            const expenseId = categories.reduce((options: string[], item) => {
                if (item.label === "Liabilities") {
                    const accountPayablesOption = item.options.reduce(
                        (value: string, option: { label: string; value: string; }) => {
                            if (option.label === "Accounts Payables ") {
                                return option.value;
                            }
                            return value;
                        }, ''
                    );
                    return [...options, accountPayablesOption];
                }
                return options;
            }, []);
            console.log(expenseId)
            const expenseRecord = { accountId: expenseId.toString(), creditAmount: 0, debitAmount: transDetails.amount, description: 'expenseTransaction' }
            const paymentMethod = { accountId: values.categoryId, creditAmount: values.amount, debitAmount: 0, description: 'expenseTransaction' }
            manualJournalEntries.push(expenseRecord, paymentMethod)
        } else if (transDetails.type === "Income") {
            const incomeId = categories.reduce((options: string[], item) => {
                if (item.label === "Assets") {
                    const accountReceivablesOption = item.options.reduce(
                        (value: string, option: { label: string; value: string; }) => {
                            if (option.label === "Account Receivables") {
                                return option.value;
                            }
                            return value;
                        }, ''
                    );
                    return [...options, accountReceivablesOption];
                }
                return options;
            }, []);
            const incomeRecord = { accountId: incomeId.toString(), creditAmount: transDetails.amount, debitAmount: 0, description: transDetails.description }
            const paymentMethod = { accountId: values.categoryId, creditAmount: 0, debitAmount: values.amount, description: transDetails.description }
            manualJournalEntries.push(incomeRecord, paymentMethod)
        }
        const paymentEntryRecord = { contactId: transDetails.contactId, date: values.date, narration: `Payment Rec.- ${transDetails.description}`, manualJournalEntries: manualJournalEntries }
        changeApiStatus(true, '');
        onSave({ ...paymentEntryRecord }, transDetails);
        // setShowPayment(true)
    };

    const sumArrayObject = (array: Array<any>, key: string) => {
        return array.reduce((acc, curr: any) => acc + Number(curr[key]) || 0, 0);
    };

    const onSave = async (data: any, transData: any) => {
        const notificationUid = 'SaveTransactionDetails';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            console.log(transData)
            const saveCategoryRes = await saveManualJournal({
                transactionId: transData._id,
                narration: data.narration,
                date: data.date,
                contactId: data.contactId || "",
                manualJournalEntries: data.manualJournalEntries.map((item: any) => {
                    return {
                        ...item,
                        id: null
                    };
                }),
                totalDebitAmount: sumArrayObject(
                    data.manualJournalEntries,
                    'debitAmount'
                ),
                totalCreditAmount: sumArrayObject(
                    data.manualJournalEntries,
                    'creditAmount'
                )
            });
            dismissNotification(notificationUid);
            if (saveCategoryRes.status === 200) {
                showNotification('success', saveCategoryRes.message);
                console.log(saveCategoryRes)
                // navigate(-1)
                const saveTransactionRes = await saveTransaction({
                    id: transData._id || undefined,
                    categoryId: transData.categoryId,
                    date: transData.date,
                    amount: transData.amount,
                    paymentType: transData.paymentType,
                    contactId: transData.contactId || '',
                    fileIds: transData.fileIds || [],
                    isPaymentComplete: true
                });
                console.log(saveTransactionRes)
                if (saveTransactionRes.status === 200) {
                    showNotification('success', saveTransactionRes.message);
                    navigate(-1);
                } else {
                    throw new Error(saveTransactionRes.error);
                }
            } else {
                throw new Error(saveCategoryRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };
    const showError = (_fieldName: string) => {
        const error = (errors as any)[_fieldName];
        return error
            ? (
                <div className='error-block text-danger mt-2'>
                    {error.message || t("common:field_require")}
                </div>
            )
            : null;
    };
    return (
        <div className='login-page'>
            <div className='row'>
            <div className={'col-md-4 col-sm-12 offset-md-4 text-center'}>
            {/* <div className='container shadow-lg mt-4 p-3 mb-5 bg-white rounded'> */}
                <h4 className='mt-5'>{t("common:make_payment_heading")}</h4>
                <div className='col'>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className='mt-4'
                    >
                        <div className='col'>
                            <div className='col'>
                                <label className='form-label text-start d-block'>
                                    {t("common:amount_paid")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={props.transactionDetails?.amount}
                                    required={false}
                                    control={control}
                                    name='amount'
                                    disabled={true}
                                    className='form-control'
                                    readOnly={true}
                                />

                            </div>
                            <div className='col'>
                                <label className='form-label text-start d-block'>
                                    {t("common:date_paid")}
                                </label>
                                <InputCtrl
                                    type='date'
                                    showError={showError}
                                    placeholder={t("common:date_paid")}
                                    required
                                    control={control}
                                    name='date'
                                    disabled={false}
                                    minDate={moment.utc(props.transactionDetails.date).format('YYYY-MM-DD')}
                                    className='form-control'
                                />
                            </div>
                            <div className='col'>
                                <label className='form-label text-start d-flex justify-content-between'>
                                    {t("common:paid_from")}
                                    <Link
                                        to='/category'
                                        className='text-decoration-none'
                                    >
                                        {t("common:manage")}
                                    </Link>
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:paid_from")}
                                    required
                                    control={control}
                                    name='categoryId'
                                    disabled={false}
                                    selectClassNamePrefix='search-select'
                                    options={categories}
                                />
                            </div>
                            <div className='mt-4 d-flex flex-row-reverse'>
                                <div className="p-2 col-6"><button
                                    type='submit'
                                    className='btn btn-secondary login-btn mt-3'
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:make_payment")}
                                </button></div>
                                <div className="p-2 col-6"><button
                                    type='reset'
                                    className='btn btn-secondary login-btn cancel-btn mt-3'
                                    disabled={apiStatus.inProgress}
                                    onClick={() => navigate('/transaction')}
                                >
                                    {t("common:cancel")}
                                </button></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
        </div>
    );
};

export default PaymentDetails;

import React, { useState, useEffect } from "react";
// import { AiOutlinePrinter, AiOutlineDownload } from 'react-icons/ai';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Form, FormGroup, FormFeedback, Input, List, Table, Row, Container, Col, Modal, ModalBody, ModalHeader, Alert, Spinner } from 'reactstrap';
import html2pdf from 'html2pdf.js';
import { showNotification, dismissNotification } from '../../utils';
import { IApiCallStatus } from '../../interfaces';
import { getAllContact, getInvoiceById, saveInvoice, deleteInvoice } from "../../services";
import useAppbar from '../../hooks/useAppbar';
import NewContactModal from "./newContactModal";
import './Invoice.scss';
import InplaceConfirm from "../../components/InplaceConfirm";
import { useTranslation } from "react-i18next";

export default function InvoiceComponent () {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateAppbar, resetAppbar } = useAppbar({});
  const params = useParams();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const initialFormData = {
    status: 'draft',
    companyName: null,
    invoiceDate: formattedDate,
    dueDate: formattedDate,
    invoiceContact: '',
    items: [],
    notes: '',
    totalAmount: 0,
    subTotal: 0,
    others: 0
     // Other form fields...
  };
   const [showHiddenDiv, setShowHiddenDiv] = useState(false);
   const [contactsList, setContactsList] = useState([]);
   const [isModalOpen, setModalOpen] = useState(false);
   const [isContactsLoaded, setContactsLoaded] = useState(false);
   const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
    failMessage: '',
    failed: false,
    inProgress: false
  });
  const [formData, setFormData]: any = useState(initialFormData);
  const [hoveredRow, setHoveredRow]: any = useState(null);
  const [errors, setErrors]: any = useState({});

  const changeApiStatus = (progress: boolean, message: string) => {
      setApiStatus({
          inProgress: progress,
          failed: !!message,
          failMessage: message
      });
  };

  const handleDateFormat = (date: string) => {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const roundFloatValue = (originalNumber: number) => {
    if (Number.isFinite(originalNumber) && Math.floor(originalNumber) !== originalNumber) {
        // Check if the number is finite and has a decimal part
        const roundedNumber = originalNumber.toFixed(2);
        return parseFloat(roundedNumber);
      } else {
       return originalNumber;
      }
  }

  const floatRegex = /^[+-]?\d+(\.\d+)?$/;
  const isValidFloat = (input: string) => {
    return floatRegex.test(input);
  };

  const calculateSubTotal = (items: any) => {
    let total = 0;
    items.forEach((element: any) => {
      total += parseFloat(element.quantity) * parseFloat(element.unitPrice)
    });
    return isNaN(total) ? 0 : roundFloatValue(total);
}
  const handleMouseEnter = (index: number) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleInputChange = (field: string, value: any) => {
    setErrors((prev: any) => ({
      ...prev,
      [field]: undefined
    }))
    if(field === 'contact' && value === 'new') {
        setFormData((prevData: any) => ({
          ...prevData,
          [field]: ""
        }));
        setModalOpen(true);
      } else {
        setFormData((prevData: any) => ({
          ...prevData,
          [field]: value
        }));
      }
  };

  const handleItemChange = (index: number, field: string, value: any) => {
    const newItems: any = [...formData.items];
    newItems[index][field] = value;
    if((field === 'quantity' || field === 'unitPrice')) {
        console.log("typeof value", typeof value)
    }
    // setItems(newItems);
    handleInputChange('items', newItems);
    handleInputChange('subTotal', calculateSubTotal(newItems));
  };

  const addItem = () => {
    // setItems([...formData.items, { description: '', quantity: 0, unitPrice: 0 }]);
    handleInputChange('items', [...formData.items, { description: '', quantity: 0, unitPrice: 0 }]);
  };

  const deleteItem: any = (index: number) => {
    const newItems = [...formData.items];
    newItems.splice(index, 1);
    // setItems(newItems);
    handleInputChange('items', newItems);
    handleInputChange('subTotal', calculateSubTotal(newItems));
  };

  const getInvoiceData = async (id: any) => {
    const notificationUid = 'GetInvoiceData';
    try {
        changeApiStatus(true, '');
        showNotification('info', t("common:load"), notificationUid);
        const getInvoiceByIdRes = await getInvoiceById(id);
        dismissNotification(notificationUid);
        changeApiStatus(false, '');
        if (getInvoiceByIdRes.status === 200) {
            setFormData({ ...getInvoiceByIdRes.data, invoiceDate: handleDateFormat(getInvoiceByIdRes.data.invoiceDate), dueDate: handleDateFormat(getInvoiceByIdRes.data.dueDate) });
        } else {
            throw new Error(getInvoiceByIdRes.error);
        }
    } catch (err: any) {
        showNotification('error', err.message);
        changeApiStatus(false, err.message);
    }
};
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // console.log("Form DATA is: ===========>", formData);
    const newErrors: any = {};
    // Validation checks
    if (!formData.companyName) {
      newErrors.companyName = t("common:req_company_name");
    }
    if (!formData.invoiceDate) {
      newErrors.invoiceDate = t("common:req_invoice_date");
    }
    if (!formData.dueDate) {
      newErrors.dueDate = t("common:req_due_date");
    }
    if (!formData.status) {
      newErrors.status = t("common:req_status");
    }
    if (!formData.invoiceContact || formData.invoiceContact === '') {
      newErrors.invoiceContact = t("common:req_contact");
    }
    if (formData.others && !isValidFloat(formData.others)) {
      newErrors.others = t("common:allow_decimal");
    }
    if (formData.items.length < 1) {
      // console.log("formData.items < 1 ===>", formData.items);
      newErrors.items = t("common:items_list");
    }
    if(formData.items.length >= 1) {
      let descError: boolean = false;
      let quantityError: boolean = false;
      let priceError: boolean = false;
      // console.log("filtered Items: >=1", formData.items);

      const filteredItems = formData.items.filter((item: any) => item.description || item.quantity !== 0 || item.unitPrice !== 0)
      // console.log("filtered Items: >=1", filteredItems);
      filteredItems.forEach((item: any) => {
        if(item.description === "") {
          descError = true;
        }if(isNaN(item.quantity) || item.quantity === 0) {
          quantityError = true;
        }if(isNaN(item.unitPrice) || item.unitPrice === 0) {
          priceError = true;
        }
      });
      if(descError) {
        newErrors.items = t("common:items_description");
      }else if(priceError) {
        newErrors.items = t("common:invalid_price");
      }else if(quantityError) {
        newErrors.items = t("common:invalid_quantity");
      }if(descError && quantityError && priceError) {
          newErrors.items = t("common:invalid_invoice");
      }if(filteredItems.length < 1) {
        newErrors.items = t("common:items_list");
      }
    }

    // If there are errors, update the state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else{
    const notificationUid = 'SaveContactDetails';
    try {
        changeApiStatus(true, '');
        showNotification(
            'info',
            t("common:request_process"),
            notificationUid
        );
        const saveInvoiceRes = await saveInvoice({
            ...formData,
            id: formData._id || undefined,
            notes: formData.notes ? formData.notes : undefined,
            items: formData.items.filter((item: any) => item.description || item.quantity !== 0 || item.unitPrice !== 0),
            totalAmount: isNaN(parseFloat(formData.others)) ? roundFloatValue(formData.subTotal) : roundFloatValue(parseFloat(formData.others) + parseFloat(formData.subTotal))
        });
        dismissNotification(notificationUid);
        if (saveInvoiceRes.status === 200) {
            showNotification('success', saveInvoiceRes.message);
            navigate('/invoices');
        } else {
            throw new Error(saveInvoiceRes.error);
        }
    } catch (err: any) {
        showNotification('error', err.message);
        changeApiStatus(false, err.message);
    }
  }
  };

  const onDelete = async () => {
    const notificationUid = 'DeleteContact';
    try {
        changeApiStatus(true, '');
        showNotification(
            'info',
            t("common:request_process"),
            notificationUid
        );
        const deleteContactRes = await deleteInvoice(params?.id || '');
        dismissNotification(notificationUid);
        if (deleteContactRes.status === 200) {
            showNotification('success', deleteContactRes.message);
            navigate('/invoices');
        } else {
            throw new Error(deleteContactRes.error);
        }
    } catch (err: any) {
        showNotification('error', err.message);
        changeApiStatus(false, err.message);
    }
};

  // const handlePrint = () => {
  //   window.print(); // Trigger the print dialog
  // };

  const handleDownload = () => {
    // setShowHiddenDiv(true);
    const content = document.getElementById('downloading-content');
    if (content) {
      // content.style.display='block';
      const opt = {
        margin: 10,
        filename: 'downloaded-file.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
      // Old monolithic-style usage:
      html2pdf(content, opt);
      setShowHiddenDiv(false);
    }
  };

  const fetchData = async (
    pageNumber = 1,
    pageSize = 20,
    search = ''
  ) => {
    try {
        const data = {
            pageNumber,
            pageSize,
            filter: search
        };
        const usersResponse = await getAllContact(data);
        if (usersResponse.status === 200) {
            setContactsList(usersResponse.data.items);
            setContactsLoaded(true);
        } else {
            throw new Error(usersResponse.error);
        }
    } catch (error: any) {
        showNotification('error', error.message);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    if (params.id && params.id !== 'new') {
      getInvoiceData(params.id);
  }

  updateAppbar({
      leftLink: '/invoices',
      leftIcon: 'Back',
      title: `${params.id !== 'new' ? t("common:edit") : t("common:new")} ${t("common:invoice")}`,
      showAddIcon: false,
      addLink: '',
      // rightComponent: null
      rightComponent: params.id !== 'new'
          ? <InplaceConfirm
              HTMLComponent={<i className="bi bi-trash text-end" style={{ fontSize: '1.5rem', color: 'red', cursor: 'pointer' }}></i>}
              action={() => {
                  onDelete();
              }}
              target="del-icon"
              text={t("common:are_you_sure")}
          />
          : null
  });
  return () => {
      resetAppbar();
  };
}, []);

  useEffect(() => {
    if(showHiddenDiv) handleDownload();
  }, [showHiddenDiv])

  return (
    <>
    {/* Render this div on Browser */}
    <div className="print-hide py-2">
      <Card className="p-4">
        <Form onSubmit={handleSubmit}>
          <Container className="mb-2 mt-3">
            <Row className="d-flex align-items-baseline">
              <Col xl="12">
                {formData.invoiceNumber &&
                <p style={{ color: "#7e8d9f", fontSize: "20px" }}>
                  <strong>{t("common:invoice_no")}:    {formData.invoiceNumber}</strong>
                </p>
                }
              </Col>
              {/* <Col xl="3" className="float-end">
              <Button color="primary" className="float-end" onClick={() => setShowHiddenDiv(true)}><AiOutlineDownload size={'1.3rem'}/> Export</Button>
              <Button color="primary" className="float-end me-2" onClick={handlePrint}><AiOutlinePrinter size={'1.3rem'}/> Print</Button>
              </Col> */}
            </Row>
          </Container>
          <Container>
            <Col md="12" className="text-center">
               <FormGroup>
                <Input style={{ width: '100%', minWidth: '8rem' }} plaintext={true} className="border-0 text-center" bsSize="lg" type = "text" placeholder = {t("common:company_name")} value = {formData.companyName} invalid={!!errors.companyName} onChange={(e: any) =>
                          handleInputChange('companyName', e.target.value)
                      }/>
                <FormFeedback>{errors.companyName}</FormFeedback>
               </FormGroup>
            </Col>
          </Container>
          <Row>
            <Col xl="8">
              <List type="unstyled">
                <li className="text-muted">
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <span className="fw-bold ms-1 mt-2">{t("common:invoice_contact")}: &nbsp; </span>
                {(isContactsLoaded && contactsList.length >= 1) &&
                    <div style={{ width: 'fit-content' }}>
                      <FormGroup>
                        <Input
                          type="select"
                          name="invoiceContact"
                          id="contact"
                          value={formData.invoiceContact}
                          invalid={!!errors.invoiceContact}
                          onChange={(e: any) => handleInputChange('invoiceContact', e.target.value)}
                        >
                          <option value="" defaultChecked disabled>
                            {t("common:select_contact")}
                          </option>
                          {contactsList.map((item: any) =>
                            <option value={item.contactName} key={item.contactName}>
                              {item.contactName}
                            </option>
                          )}
                        </Input>
                        <FormFeedback>{errors.invoiceContact}</FormFeedback>
                      </FormGroup>
                    </div>
                }
                <span
                        className="ms-1 mt-2"
                        style={{ cursor: 'pointer', color: '#243A77' }}
                        onClick={() => {
                          setErrors((prev: any) => ({
                          ...prev,
                          invoiceContact: undefined
                        }));
                        setModalOpen(true);
                      }}
                      >
                        &nbsp;+ {t("common:new_")}
                      </span>
                {contactsList?.length === 0 &&
                <span className="ms-1 mt-2"
                style={{ color: 'red' }}
                >
                  {errors.invoiceContact}
                </span>
                }
                </div>
                </li>

                <li className="text-muted">
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <span className="fw-bold ms-1 mt-2">{t("common:invoice_date")}: &nbsp; </span>
                  <FormGroup>
                  <Input type = "date" name="invoiceDate" value = {formData.invoiceDate} invalid={!!errors.invoiceDate} onChange = {(e: any) => handleInputChange('invoiceDate', e.target.value)}/>
                  <FormFeedback>{errors.invoiceDate}</FormFeedback>
                  </FormGroup>
                </div>
                </li>
                <li className="text-muted">
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <span className="fw-bold ms-1 mt-2">{t("common:due_date")}: &nbsp; </span>
                  <FormGroup>
                  <Input type = "date" value = {formData.dueDate} invalid={!!errors.dueDate} onChange={(e: any) => handleInputChange('dueDate', e.target.value)}/>
                  <FormFeedback>{errors.dueDate}</FormFeedback>
                  </FormGroup>
                </div>
                </li>
                <li className="text-muted mb-2">
                  <span className="fw-bold ms-1">{t("common:status")}: &nbsp; </span>
                  {/* <span className="badge bg-warning text-black fw-bold ms-1"> */}
                  <Input type="select" style={{ display: 'inline-block', width: 'fit-content' }} name = "status" id = "status" value = {formData.status} onChange = {(e: any) => handleInputChange('status', e.target.value)}>
                    <option value="draft">Draft</option>
                    <option value="approved">Approved</option>
                    <option value="sent">Sent</option>
                    <option value="void">Void</option>
                  </Input>
                  {/* </span> */}
                </li>
              </List>
            </Col>
          </Row>
          <Row className="my-2 justify-content-center">
            <Table responsive striped hover>
              <thead
                className="text-white"
                style={{ backgroundColor: "#84B0CA" }}
              >
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t("common:description")}</th>
                  <th scope="col">{t("common:quantity")}</th>
                  <th scope="col">{t("common:unit_price")}</th>
                  <th scope="col">{t("common:amount")}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody style={{ verticalAlign: 'middle' }}>
                {formData.items.map((item: any, index: number) => (
                   <tr key={index}
                   onMouseEnter={() => handleMouseEnter(index)}
                   onMouseLeave={handleMouseLeave}>
                   <th scope="row">{index + 1}</th>
                   <td>
                    <Input style={{ width: '90%', minWidth: '8rem' }} type = "text" placeholder = {t("common:item_name")} value = {item.description} onChange={(e: any) =>
                      handleItemChange(index, 'description', e.target.value)
                      }/>
                    </td>
                   <td><Input style={{ width: '50%', minWidth: '4rem' }} type = "number" min = "0" value = {item.quantity} onChange={(e: any) =>
                      handleItemChange(index, 'quantity', e.target.value)
                      }
                      />
                    </td>
                   <td><Input style={{ width: '50%', minWidth: '4rem' }} type = "number" min = "0" step=".01" value = {item.unitPrice} onChange={(e: any) =>
                      handleItemChange(index, 'unitPrice', e.target.value)
                      }/></td>
                   <td>{isNaN(parseFloat(item.unitPrice) * parseFloat(item.quantity)) ? 0 : roundFloatValue(parseFloat(item.unitPrice) * parseFloat(item.quantity))}</td>
                    <td>
                    {hoveredRow === index && (
                     <Button close onClick={() => { deleteItem(index) }}/>
                    )}
                    </td>
                 </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          {errors.items &&
          <Row className="px-4">
            <Alert color="danger">
             {errors.items}
            </Alert>
          </Row>
          }
          <Row>
          <div onClick={addItem} className="mt-0 mb-2 ms-3" style={{ cursor: 'pointer', width: 'fit-content' }}>
                  + {t("common:add_new_item")}
            </div>
          </Row>
          <Row>
            <Col xl="8">
            <Input type="textarea" placeholder={t("common:add_notes")} id="notes" name="notes" value={formData.notes} onChange={(e: any) => handleInputChange('notes', e.target.value)}>
            </Input>
            </Col>
            <Col xl="4">
              <List type="unstyled" className="float-end">
                <li className="text-muted ms-3">
                  <span className="text-black me-4">{t("common:subtotal")}  </span>{roundFloatValue(formData.subTotal)}
                </li>
                <li className="text-muted ms-3 mt-2">
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <span className="text-black me-3 mt-2">{t("common:others")}</span>
                  <FormGroup style={{ width: '50%', marginLeft: '1.5rem' }}>
                  <Input type = "text" value = {formData.others} invalid={!!errors.others} onChange={(e: any) => handleInputChange('others', e.target.value)}/>
                  <FormFeedback>{errors.others}</FormFeedback>
                  </FormGroup>
                </div>
                </li>
                <li className="text-black ms-3 mt-2" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                  <span className="text-black me-4">{t("common:total_amount")}  </span> HK$ {isNaN(parseFloat(formData.others)) ? roundFloatValue(formData.subTotal) : roundFloatValue(parseFloat(formData.others) + parseFloat(formData.subTotal))}
                </li>
              </List>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xl="9">
            </Col>
            <Col xl="3">
              <div className='float-end'>
              <Button className='mx-3 btn btn-secondary' onClick={() => navigate('/invoices') } disabled={apiStatus.inProgress}>{t("common:cancel")}</Button>
              <Button style={{ background: 'rgb(36, 58, 118)', color: '#ffffff' }} className='me-3' type="submit" disabled={apiStatus.inProgress}>{t("common:save")}</Button>
              </div>
            </Col>
          </Row>
          </Form>
      </Card>
       {/* Modal for custom data entry */}
       <Modal isOpen={isModalOpen} toggle={() => {
       setModalOpen(false);
       handleInputChange('contact', "");
        }
        }>
        <ModalHeader toggle={() => setModalOpen(false)}>{t("common:add_new_contact")}</ModalHeader>
        <ModalBody style={{ overflowY: 'scroll' }}>
          <NewContactModal setModalOpen={setModalOpen} setContactsList={setContactsList} />
          {/* <FormGroup>
            <Label for="customValue">Custom Value:</Label>
            <Input
              type="text"
              id="customValue"
              value={customValue}
              onChange={(e) => setCustomValue(e.target.value)}
            />
          </FormGroup> */}
          {/* <Button color="primary" onClick={() => {}}>Submit</Button> */}
        </ModalBody>
      </Modal>
      {/* Modal showing wile loading */}
      <Modal isOpen={apiStatus.inProgress} backdrop="static" keyboard={false} centered>
            <ModalBody>
            <div className="text-center py-3">
            <Spinner color="secondary" style={{
                height: '3rem',
                width: '3rem'
                }}>
                    {t("common:load")}
            </Spinner>
            </div>
            </ModalBody>
        </Modal>
    </div>
    {/* Show this div only while printing */}
    <Container className="print-only py-2">
       {/* For printing Only */}
      <Card className="p-4">
          <Form onSubmit={handleSubmit}>
          <Container className="mb-2 mt-3">
            <Row className="d-flex align-items-baseline">
              <Col xl="9">
                <p style={{ color: "#7e8d9f", fontSize: "18px" }}>
                  <strong>{t("common:invoice_id")}:    INV-00001</strong>
                </p>
              </Col>
            </Row>
          </Container>
          <Container>
            <Col md="12" className="text-center">
              <h5 className="pt-0">{ formData.companyName ? formData.companyName : t("common:company_name") }</h5>
            </Col>
          </Container>
          <Row>
            <Col xl="8">
              <List type="unstyled">
                {/* <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">ID:  </span>#123-456
                </li> */}
                <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">{t("common:invoice_contact")}:   </span>{formData.contact}
                </li>
                <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">{t("common:invoice_date")}:  </span>{formData.invoiceDate}
                </li>
                <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">{t("common:due_date")}:  </span>{formData.dueDate}
                </li>
                <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">{t("common:status")}:  </span>
                  <span className="badge bg-warning text-black fw-bold ms-1">
                  {formData.status.charAt(0).toUpperCase() + formData.status.slice(1)}
                  </span>
                </li>
              </List>
            </Col>
          </Row>
          <Row className="my-2 mx-1 justify-content-center">
            <Table striped>
              <thead
                className="text-white"
                style={{ backgroundColor: "#84B0CA" }}
              >
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t("common:description")}</th>
                  <th scope="col">{t("common:qty")}</th>
                  <th scope="col">{t("common:unit_price")}</th>
                  <th scope="col">{t("common:amount")}</th>
                </tr>
              </thead>
              <tbody>
                {formData.items.map((item: any, index: number) => (
                   <tr key={index}>
                   <th scope="row">{index + 1}</th>
                   <td>
                   {item.name}
                    </td>
                   <td>{item.quantity}
                    </td>
                   <td>{item.unitAmount}</td>
                   <td>{roundFloatValue(item.unitAmount * item.quantity)}</td>
                 </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <Row>
          <Col xl="7">
              <List type="unstyled" className="float-end">
                <li className="text-muted ms-3">
                  <span className="text-black me-3">{t("common:subtotal")}  </span>{formData.subTotal}
                </li>
                <li className="text-muted ms-3 mt-2">
                  <span className="text-black me-4">{t("common:others")}   </span> {formData.others}
                </li>
                <li className="text-black fw-bold ms-3 mt-2">
                  <span className="text-black me-3">{t("common:total_amount")}   </span> HK$ {parseFloat(formData.others) + parseFloat(formData.subTotal)}
                </li>
              </List>
            </Col>
            <Col xl="12">
            <p className="fw-bold">{t("common:notes")}:</p>
            <p style={{ fontSize: '10px' }}>
              {formData.notes}
            </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xl="10">
              {/* <p>Invoice Contact : 09090908080</p> */}
            </Col>
          </Row>
          </Form>
      </Card>
    </Container>

    {/* Div which should be download */}
    {showHiddenDiv &&
    <Container id="downloading-content" className="print-hide py-2">
       {/* For printing Only */}
      <Card className="p-4">
          <Form onSubmit={handleSubmit}>
          <Container className="mb-2 mt-3">
            <Row className="d-flex align-items-baseline">
              <Col xl="9">
                <p style={{ color: "#7e8d9f", fontSize: "18px" }}>
                  <strong>{t("common:invoice_id")}:     INV-00001</strong>
                </p>
              </Col>
            </Row>
          </Container>
          <Container>
            <Col md="12" className="text-center">
              <h5 className="pt-0">{ formData.companyName ? formData.companyName : t("common:company_name") }</h5>
            </Col>
          </Container>
          <Row>
            <Col xl="8">
              <List type="unstyled">
                {/* <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">ID:  </span>#123-456
                </li> */}
                <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">{t("common:invoice_contact")}:   </span>{formData.contact}
                </li>
                <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">{t("common:invoice_date")}:  </span>{formData.invoiceDate}
                </li>
                <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">{t("common:due_date")}:  </span>{formData.dueDate}
                </li>
                <li className="text-muted mb-1">
                  <span className="fw-bold ms-1">{t("common:status")}:  </span>
                  <span className="badge bg-warning text-black fw-bold ms-1">
                  {formData.status.charAt(0).toUpperCase() + formData.status.slice(1)}
                  </span>
                </li>
              </List>
            </Col>
          </Row>
          <Row className="my-2 mx-1 justify-content-center">
            <Table striped>
              <thead
                className="text-white"
                style={{ backgroundColor: "#84B0CA" }}
              >
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t("common:description")}</th>
                  <th scope="col">{t("common:qty")}</th>
                  <th scope="col">{t("common:unit_price")}</th>
                  <th scope="col">{t("common:amount")}</th>
                </tr>
              </thead>
              <tbody>
                {formData.items.map((item: any, index: number) => (
                   <tr key={index}>
                   <th scope="row">{index + 1}</th>
                   <td>
                   {item.name}
                    </td>
                   <td>{item.quantity}
                    </td>
                   <td>{item.unitAmount}</td>
                   <td>{roundFloatValue(item.unitAmount * item.quantity)}</td>
                 </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          <Row>
          <Col xl="12">
              <List type="unstyled" className="float-end">
                <li className="text-muted ms-3">
                  <span className="text-black me-3">{t("common:subtotal")}  </span>{formData.subTotal}
                </li>
                <li className="text-muted ms-3 mt-2">
                  <span className="text-black me-4">{t("common:others")}   </span> {formData.others}
                </li>
                <li className="text-black fw-bold ms-3 mt-2">
                  <span className="text-black me-3">{t("common:total_amount")}   </span> HK$ {(parseFloat(formData.others) + parseFloat(formData.subTotal))}
                </li>
              </List>
            </Col>
            <Col xl="12">
            <p className="fw-bold">{t("common:notes")}:</p>
            <p style={{ fontSize: '10px' }}>
              {formData.notes}
            </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xl="10">
              {/* <p>Invoice Contact : 09090908080</p> */}
            </Col>
          </Row>
          </Form>
      </Card>
    </Container>
    }
    </>
  );
}

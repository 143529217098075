import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { getRandomInt } from '../utils';

interface ICustomTooltip {
    label: React.ReactNode,
    id?: string,
    children: React.ReactChild;
}

const CustomTooltip = ({ label, id, children }: ICustomTooltip) => {
    const tId = id || `custom-tooltip-${getRandomInt()}`
    return (
        <>
            <span id={tId}>{label}</span>
            <UncontrolledTooltip target={tId}>
                {children}
            </UncontrolledTooltip>
        </>
    );
};

export default CustomTooltip;

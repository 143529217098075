import React, { useEffect, useMemo } from 'react';
import { getAllBankTransaction } from '../../services';
import {
    DEFAULT_PAGE_SIZE,
    summarizeText
} from '../../utils';
import ScrollToTop from 'react-scroll-to-top';
import Table from '../../components/Table';
import useAppbar from '../../hooks/useAppbar';
import CustomTooltip from '../../components/CustomTooltip';
import { useList } from "../../hooks/useList";
import { useTranslation } from 'react-i18next';

const Reconciliation = () => {
    const { t } = useTranslation();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [reconciled, reconciledApiHit, reconciledApiStatus] = useList<Array<any>, { [x: string]: any; }>({
        api: getAllBankTransaction,
        apiParam: {
            pageSize: DEFAULT_PAGE_SIZE,
            pageNumber: 1,
            filterReconcile: 'Reconciled'
        },
        results: [],
        autoApiHit: false
    });

    const [unreconciled, unreconciledApiHit, unreconciledApiStatus] = useList<Array<any>, { [x: string]: any; }>({
        api: getAllBankTransaction,
        apiParam: {
            pageSize: DEFAULT_PAGE_SIZE,
            pageNumber: 1,
            filterReconcile: 'Unreconciled'
        },
        results: [],
        autoApiHit: false
    });

    const cellRender = (key: string, row: any) => {
        return row[key] || 'N/A';
    };

    const columns = useMemo(
        () => [
            {
                Header: t("common:date"),
                accessor: (originalRow: any) => {
                    return cellRender('transactionFromDateValue', originalRow);
                }
            },
            {
                Header: t("common:description"),
                accessor: (originalRow: any) => {
                    const val = originalRow.transactionSummary;
                    const label = summarizeText(cellRender('transactionSummary', originalRow), 10);
                    return <CustomTooltip label={label}>{val || ''}</CustomTooltip>;
                }
            },
            {
                Header: t("common:account"),
                // accessor: 'accountName',
                accessor: (originalRow: any) => {
                    const val = originalRow.accountName;
                    const label = summarizeText(cellRender('accountName', originalRow), 10);
                    return <CustomTooltip label={label}>{val || ''}</CustomTooltip>;
                }
            },
            {
                Header: t("common:amount"),
                accessor: (originalRow: any) => {
                    let val = '';
                    if (originalRow.transactionCreditAmountValue) {
                        val = 'transactionCreditAmountValue';
                    } else if (originalRow.transactionDebitAmountValue) {
                        val = 'transactionDebitAmountValue';
                    }
                    return val ? cellRender(val, originalRow) : '';
                }
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        reconciledApiHit();
        unreconciledApiHit();
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: t("common:reconcilation"),
            showAddIcon: false,
            addLink: '',
            rightComponent: null
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log('reconciledList', reconciled, reconciledApiStatus);

    return (
        <div className='transation-list'>
            <div className='row'>
                {/* <div className='col-md-3 mb-2'>Total {totalValue}</div> */}
            </div>
            <div className="d-flex w-100" style={{
                overflowX: 'scroll'
            }}>
                <div className="me-4 w-100">
                    <h4>{t("common:reconciled")}</h4>
                {reconciledApiStatus.inProgress
                ? (
                    t("common:load")
                )
                : (
                    <>
                        <div className='page-body'>
                            <ScrollToTop
                                smooth
                                className='ScrollToTop'
                                component={
                                    <i className='bi bi-arrow-up-circle'></i>
                                }
                            />
                            <Table columns={columns} data={reconciled} />
                        </div>
                    </>
                )}
                </div>
                <div className="w-100">
                <h4>{t("common:unreconciled")}</h4>
                {unreconciledApiStatus.inProgress
                ? (
                    t("common:load")
                )
                : (
                    <>
                        <div className='page-body'>
                            <ScrollToTop
                                smooth
                                className='ScrollToTop'
                                component={
                                    <i className='bi bi-arrow-up-circle'></i>
                                }
                            />
                            <Table columns={columns} data={unreconciled} />
                        </div>
                    </>
                )}
                </div>
            </div>
        </div>
    );
};

export default Reconciliation;

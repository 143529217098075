import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
// import CustomHeader from '../../components/CustomHeader';
import InplaceConfirm from '../../components/InplaceConfirm';
// import { parentCategoryOptions } from '../../constants';
import InputCtrl from '../../controllers/InputCtrl';
import SelectCtrl from '../../controllers/SelectCtrl';
import useAppbar from '../../hooks/useAppbar';
import { IApiCallStatus } from '../../interfaces';
import { saveCategory, getCategoryById, deleteCategory, getAllCategoryType, getAllParentCategory, getAllBank } from '../../services';
import { dismissNotification, showNotification } from '../../utils';
import { useTranslation } from 'react-i18next';

const defaultValues = {
    category: '',
    parentCategory: '',
    type: '',
    bankName: '',
    accountNumber: ''
};
const CategoryDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const params = useParams();
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        watch
    } = useForm({
        mode: 'onBlur',
        defaultValues
    });
    const [categoryDetails, setCategoryDetails] = useState<any>();
    const [categoryType, setCategoryType] = useState<Array<{ label: string, value: string; }>>([]);
    const [categoryParent, setCategoryParent] = useState<Array<{ label: string, value: string; parent: string; }>>([]);
    const [categoryParentOptions, setCategoryParentOptions] = useState<Array<{ label: string, value: string; parent: string; }>>([]);
    const [banks, setBanks] = useState<Array<{ label: string, value: string; }>>([]);

    const parentCategory = watch('parentCategory');

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const onDelete = async () => {
        const notificationUid = 'DeleteCategory';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const deleteCategoryRes = await deleteCategory(categoryDetails?._id);
            dismissNotification(notificationUid);
            if (deleteCategoryRes.status === 200) {
                showNotification('success', deleteCategoryRes.message);
                navigate('/category');
            } else {
                throw new Error(deleteCategoryRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const onSubmit = async (data: any) => {
        const notificationUid = 'SaveCategoryDetails';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const saveCategoryRes = await saveCategory({
                id: data._id || undefined,
                category: data.category,
                parentCategory: data.parentCategory,
                type: data.type,
                bankName: data.bankName,
                accountNumber: data.accountNumber
            });
            dismissNotification(notificationUid);
            if (saveCategoryRes.status === 200) {
                showNotification('success', saveCategoryRes.message);
                navigate('/category');
            } else {
                throw new Error(saveCategoryRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const getBanks = async () => {
        const notificationUid = 'GetBank';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getAllBanksRes = await getAllBank();

            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getAllBanksRes.status === 200) {
                const items = getAllBanksRes.data || [];
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    tempList.push({
                        label: item.label,
                        value: item.value
                    });
                    return item;
                });
                tempList.push({
                    label: t("common:none"),
                    value: ''
                });
                setBanks(tempList);
            } else {
                throw new Error(getAllBanksRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const getCategoryType = async () => {
        const notificationUid = 'GetCategoryType';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getAllCategoryTypeRes = await getAllCategoryType();

            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getAllCategoryTypeRes.status === 200) {
                const items = getAllCategoryTypeRes.data || [];
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    tempList.push({
                        label: item.label,
                        value: item.value
                    });
                    return item;
                });
                setCategoryType(tempList);
            } else {
                throw new Error(getAllCategoryTypeRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const getParentCategory = async () => {
        const notificationUid = 'GetParentCategory';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getAllCategoryTypeRes = await getAllParentCategory();

            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getAllCategoryTypeRes.status === 200) {
                const items = getAllCategoryTypeRes.data || [];
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    tempList.push({
                        label: item.label,
                        value: item.value,
                        parent: item.parent
                    });
                    return item;
                });
                setCategoryParent(tempList);
            } else {
                throw new Error(getAllCategoryTypeRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const getCategoryDetails = async (id: any) => {
        const notificationUid = 'GetCategoryDetails';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getCategoryByIdRes = await getCategoryById(id);

            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getCategoryByIdRes.status === 200) {
                setCategoryDetails(getCategoryByIdRes.data);
                reset({
                    ...getCategoryByIdRes.data
                });
            } else {
                throw new Error(getCategoryByIdRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const onChangeType = useCallback((type: string) => {
        const tempList: Array<any> = [];
        if (type) {
            categoryParent.filter((item: any) => {
                if (type === item.parent) {
                    tempList.push({
                        label: item.label,
                        value: item.value,
                        parent: item.parent
                    });
                }
                return item;
            });
            setCategoryParentOptions(tempList);
        }

        return [];
    }, [categoryParent]);

    const watchType = watch('type');

    useEffect(() => {
        if (watchType !== undefined) {
            onChangeType(watchType);
        }
    }, [onChangeType, watchType]);

    const showError = (_fieldName: string) => {
        const error = (errors as any)[_fieldName];
        return error
            ? (
                <div className='error-block text-danger mt-2'>
                    {error.message || t("common:field_require")}
                </div>
            )
            : null;
    };

    useEffect(() => {
        (async () => {
            await getCategoryType();
            await getParentCategory();
            await getBanks();
        })();
        if (params.id && params.id !== 'new') {
            getCategoryDetails(params.id);
        }
        console.log('banks', banks);
        updateAppbar({
            leftLink: '/category',
            leftIcon: 'Back',
            title: `${params.id !== 'new' ? t("common:edit") : t("common:new")} ${t("common:account")}`,
            showAddIcon: false,
            addLink: ''
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='login-page'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 col-sm-12 offset-md-4 p-0 text-center'>
                        {/* <CustomHeader
							title={`${
								categoryDetails?._id ? 'Edit' : 'Add New'
							} Category`}
							backFn={() => navigate('/category')}
						/> */}
                        {/* <h2 className='heading m-0 position-relative'> <button type='button' className='backbutton border-0 position-absolute p-0 '><i onClick={() => navigate('/category')} className='bi bi-chevron-left'></i></button> Add category</h2> */}
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className='mt-4'
                        >
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                    {t("common:type")}
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:type")}
                                    required
                                    control={control}
                                    name='type'
                                    disabled={false}
                                    // className='form-control'
                                    selectClassNamePrefix='search-select'
                                    options={categoryType}
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:transaction_type")}
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:transaction_type")}
                                    required
                                    control={control}
                                    name='parentCategory'
                                    disabled={false}
                                    // className='form-control'
                                    selectClassNamePrefix='search-select'
                                    options={categoryParentOptions}
                                />
                            </div>
                            {parentCategory === 'Bank' &&
                                <div>
                                    <div className='col-12 input-group d-block'>
                                        <label className='form-label text-start d-block'>
                                            {t("common:bank_type")}
                                        </label>
                                        <SelectCtrl
                                            showError={showError}
                                            placeholder={t("common:bank_type")}
                                            required={false}
                                            control={control}
                                            name='bankName'
                                            disabled={false}
                                            // className='form-control'
                                            selectClassNamePrefix='search-select'
                                            options={banks}
                                        />
                                    </div>
                                    <div className='col-12 input-group d-block'>
                                        <label className='form-label text-start d-block'>
                                            {t("common:account_no")}
                                        </label>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:account_no")}
                                            required
                                            control={control}
                                            name='accountNumber'
                                            disabled={false}
                                            className='form-control'
                                        />
                                    </div>
                                </div>}
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:name")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:name")}
                                    required
                                    control={control}
                                    name='category'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className='btn btn-secondary login-btn'
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:save")}
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-secondary login-btn cancel-btn mt-3'
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:cancel")}
                                </button>
                            </div>
                            {categoryDetails?._id && <div className='mt-4 mr-3'>
                                <InplaceConfirm
                                    HTMLComponent={<i className="bi bi-trash text-end" style={{ fontSize: '2rem', color: 'red' }}></i>}
                                    action={() => {
                                        console.log('onAction');
                                        onDelete();
                                    }}
                                    target="del-icon"
                                    text={t("common:are_you_sure")}
                                />
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryDetails;

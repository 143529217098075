import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IApiCallStatus } from '../interfaces';
import { checkOTP, startOTP } from '../services';
import { allowedRoleNames } from '../constants';
import { SHAREDCOOKIE, UserDataKey, createCookie, getLocalUserData, showNotification } from '../utils';
import useAuth from '../hooks/useAuth';
import { ValueFromUserData } from '../utils/localstorage';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';

const SendOTP = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    let mobileNo = auth.user?.phoneNumber;
    mobileNo = mobileNo?.slice(-4);
    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [showOTPInput, setShowOTPInput] = useState(false);
    const [showSendButton, setShowSendButton] = useState(true);
    const [otp, setOtp] = useState('');

    // const classes = useStyles();

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const sendOtp = async () => {
        changeApiStatus(true, '');
        try {
            const phoneNumber = ValueFromUserData('phoneNumber');
            const data = { to: phoneNumber, channel: 'sms' };
            const sendOtpResp = await startOTP(data);
            if (sendOtpResp.status === 200) {
                setShowSendButton(false);
                setShowOTPInput(true);
            } else {
                throw new Error(sendOtpResp.error);
            }
        } catch (err: any) {
            changeApiStatus(false, err.message);
            showNotification('error', err.message);
        }
    };

    const verifyOtp = async () => {
        changeApiStatus(true, '');
        try {
            // const role = ValueFromUserData('role') || 0;
            const phoneNumber = ValueFromUserData('phoneNumber');
            const data = { to: phoneNumber, code: otp };
            const verifyOtpResp = await checkOTP(data);
            const cookie = getLocalUserData();
            if (verifyOtpResp.status === 200) {
                cookie.verify2FA = true;
                if (allowedRoleNames.includes(cookie.roleName)) {
                    showNotification('success', verifyOtpResp.message);
                    createCookie(UserDataKey, JSON.stringify(cookie), SHAREDCOOKIE);
                    auth.signin(cookie, () => {
                        setLoading(true);
                        navigate('/transaction', { replace: true });
                    });
                } else {
                    throw new Error(t("common:unauthorized_access"));
                }
            } else {
                throw new Error(verifyOtpResp.error);
            }
        } catch (err: any) {
            changeApiStatus(false, err.message);
            showNotification('error', err.message);
        }
    };

    return (
        <div className="login-page">
            <div className="container h-100">
                {
                loading
                ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <Spinner type="grow" />
                    </div>
                )
                : (
                    <div className="row h-100 align-items-center">
                        <div className="col-md-4 col-sm-12 offset-md-4 text-center">
                            <div className="d-logo">
                                <img alt="img" src="./images/logo.png" width="200px" height="100%" />
                            </div>
                             {showSendButton && (
								<p>{t("common:otp_mobile")} {mobileNo}</p>
							)}
                            {showOTPInput && (
								<p>{t("common:otp_mobile_verify")} {mobileNo}</p>
							)}
                            <div className="col-12 input-group d-block">
                                {showSendButton && (
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            onClick={sendOtp}
                                            className="btn btn-secondary login-btn"
                                            disabled={apiStatus.inProgress}
                                        >
                                            {t("common:send_otp")}
                                        </button>
                                    </div>
                                )}
                                {showOTPInput && (
                                    <>
                                        <div className="txet-area">
                                            <input
                                                type="text"
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                                placeholder="Enter OTP"
                                                style={{
                                                    padding: '16px',
                                                    marginBottom: '16px',
                                                    height: '50px'
                                                }}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={verifyOtp}
                                            className="btn btn-secondary login-btn"
                                            style={{
                                                borderRadius: '5px',
                                                height: '50px'
                                            }}
                                        >
                                            {t('common:verify_otp')}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SendOTP;

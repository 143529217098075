import React, { useEffect, useState, useMemo } from 'react';
import { IApiCallStatus } from '../../interfaces';
import { deleteBankTransaction, getAllBankTransaction } from '../../services';
import {
    DEFAULT_PAGE_SIZE,
    dismissNotification,
    isValidYear,
    showNotification,
    summarizeText
} from '../../utils';
import ScrollToTop from 'react-scroll-to-top';
import Table from '../../components/Table';
import useAppbar from '../../hooks/useAppbar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InplaceConfirm from '../../components/InplaceConfirm';
import CustomSearchBox from '../../components/CustomSearchBox';
import { DEBOUNCE_TIME, monthNames } from '../../constants';
import CustomTooltip from '../../components/CustomTooltip';
import usePagination from "../../hooks/usePagination";
import { useTranslation } from 'react-i18next';
let currentPagination = 1;
let distinctUntilChange: any = null;
const BankTransactions = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [params, setParams] = useState({})
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [bankTransactions, page, onUpdateData, onUpdatePage] = usePagination({
        pageSize: DEFAULT_PAGE_SIZE
    })

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const cellRender = (key: string, row: any) => {
        return row[key] || 'N/A';
    };

    const columns = useMemo(
        () => [
            {
                Header: t("common:index"),
                accessor: (originalRow: any, index: any, c: any, d: any) => {
                    return <>{index + 1}</>;
                }
            },
            {
                Header: t("common:date"),
                accessor: (originalRow: any) => {
                    return cellRender('transactionFromDateValue', originalRow);
                }
            },
            // {
            //     Header: 'To Date',
            //     // accessor: 'transactionToDate',
            //     accessor: (originalRow: any) => {
            //         return cellRender('transactionToDateValue', originalRow);
            //     }
            // },
            {
                Header: t("common:summary"),
                // accessor: 'transactionSummary',
                accessor: (originalRow: any) => {
                    const val = originalRow.transactionSummary;
                    const label = summarizeText(cellRender('transactionSummary', originalRow), 10);
                    return <CustomTooltip label={label}>{val || ''}</CustomTooltip>;
                }
            },
            {
                Header: t("common:deposit"),
                // accessor: 'transactionCreditAmount',
                accessor: (originalRow: any) => {
                    return cellRender(
                        'transactionCreditAmountValue',
                        originalRow
                    );
                }
            },
            {
                Header: t("common:withdrawl"),
                // accessor: 'transactionDebitAmount',
                accessor: (originalRow: any) => {
                    return cellRender(
                        'transactionDebitAmountValue',
                        originalRow
                    );
                }
            },
            {
                Header: t("common:balance"),
                // accessor: 'transactionTotalAmount',
                accessor: (originalRow: any) => {
                    return cellRender(
                        'transactionTotalAmountValue',
                        originalRow
                    );
                }
            },
            {
                Header: t("common:bank"),
                // accessor: 'bank',
                accessor: (originalRow: any) => {
                    return cellRender('bank', originalRow);
                }
            },
            {
                Header: t("common:account"),
                // accessor: 'accountName',
                accessor: (originalRow: any) => {
                    const val = originalRow.accountName;
                    const label = summarizeText(cellRender('accountName', originalRow), 10);
                    return <CustomTooltip label={label}>{val || ''}</CustomTooltip>;
                }
            },
            {
                Header: t("common:reconcile"),
                accessor: (originalRow: any) => {
                    const reconcileIds = originalRow.reconcileIds || [] as Array<any>;
                    const finalReconcileIds = originalRow.finalReconcileIds || [] as Array<any>;
                    return (
                        <>
                            {finalReconcileIds.length
                                ? <div className='d-flex justify-content-around'>
                                    <i className="bi bi-check2-square"
                                        style={{
                                            fontSize: '1.5rem',
                                            color: 'green'
                                        }}></i>
                                    <i className="bi bi-eye-fill hand"
                                        onClick={() => goto('/bank-transactions/reconcile/' + originalRow._id)}
                                        style={{
                                            fontSize: '1.5rem',
                                            color: 'blue'
                                        }}></i>
                                </div>
                                : reconcileIds.length
                                    ? <button type="button" className="btn btn-success btn-sm w-100" onClick={() => goto('/bank-transactions/reconcile/' + originalRow._id)}>Potential Match</button>
                                    : <button type='button' className='btn btn-secondary btn-sm w-100' onClick={() => goto('/bank-transactions/reconcile/' + originalRow._id)}>Find Match</button>
                            }
                        </>
                    );
                }
            },
            {
                Header: t("common:actions"),
                accessor: (originalRow: any) => {
                    return (
                        <>
                            <InplaceConfirm
                                HTMLComponent={
                                    <i
                                        className='bi bi-trash text-end hand'
                                        style={{
                                            fontSize: '1rem',
                                            color: 'red'
                                        }}
                                    ></i>
                                }
                                action={() => {
                                    console.log('onAction');
                                    onDelete(originalRow._id);
                                }}
                                target={`del-icon-${originalRow._id}`}
                                text={t("common:are_you_sure")}
                            />
                        </>
                    );
                }
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const [totalValue, setTotalValue] = useState('$0');
    const [querySearch, setQuery] = useState('');
    const [filterMonth, setFilterMonth] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const fetchData = async (
        pageNumber = page.current,
        pageSize = page.pageSize,
        summary = querySearch,
        month = filterMonth,
        year = filterYear,
        searchParams = params,
        cb?: (data: any) => void
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                pageNumber,
                pageSize,
                filter: summary,
                filterMonth: month,
                filterYear: isValidYear(year) ? year : '',
                sortByDate: 1,
                ...searchParams
            };
            const bankTransResp = await getAllBankTransaction(data);
            if (bankTransResp.status === 200) {
                onUpdateData({ ...bankTransResp.data, currentPage: pageNumber })
                const resData = bankTransResp.data;
                if (resData.totalValue) {
                    setTotalValue(resData.totalValue);
                }
                if (cb) {
                    cb(bankTransResp.data);
                }
                // setBankTransactions(resData.items);
                changeApiStatus(false, '');
            } else {
                throw new Error(bankTransResp.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        if (distinctUntilChange) {
            clearTimeout(distinctUntilChange);
            distinctUntilChange = null;
        }
        distinctUntilChange = setTimeout(() => {
            setQuery(value);
            currentPagination = 1;
            onUpdatePage({ ...page, current: currentPagination });
            fetchData(1, undefined, value.trim(), filterMonth);
        }, DEBOUNCE_TIME);
    };
    const handleMonthSearch = (event: any) => {
        const value = event.target.value;
        setFilterMonth(value);
        currentPagination = 1;
        onUpdatePage({ ...page, current: currentPagination });
        fetchData(1, undefined, querySearch, value.trim(), filterYear);
    };
    const handleYearSearch = (event: any) => {
        const value = event.target.value.trim();
        // if(value === '') {
        setFilterYear(value);
        // }
        // setFilterYear(value);
        if (isValidYear(value) || value === '') {
            currentPagination = 1;
            onUpdatePage({ ...page, current: currentPagination });
            fetchData(1, undefined, querySearch, filterMonth, value);
        }
    };

    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        console.log(currentParams); // get new values onchange
        if(currentParams) {
            setParams(currentParams)
        }
        let isMenu = true;
        if(currentParams) {
            if(currentParams.statementId) {
                isMenu = false;
            }
        }
        fetchData(undefined, undefined, undefined, undefined, undefined, currentParams);
        updateAppbar({
            leftLink: '',
            leftIcon: isMenu ? 'Menu' : 'Back',
            title: t("common:bank_transactions"),
            showAddIcon: false,
            addLink: '',
            rightComponent: null
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const goto = (path: any) => {
        navigate(path);
    };

    const onDelete = async (id: string) => {
        const notificationUid = 'DeleteStatement';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const saveStatementRes = await deleteBankTransaction(id);
            dismissNotification(notificationUid);
            if (saveStatementRes.status === 200) {
                showNotification('success', saveStatementRes.message);
                fetchData(1);
                // navigate('/transaction');
            } else {
                throw new Error(saveStatementRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };
    return (
        <div className='transation-list'>
            <div className='row'>
                <div className='col-md-3 mb-2'>{t("common:total")} {totalValue}</div>
                <div className='col-md-3'>
                    <CustomSearchBox key={'search'} onChange={handleSearch} />
                </div>
                <div className='col-md-3'>
                    <CustomSearchBox
                        key={'year'}
                        placeholder={t("common:year")}
                        borderColor={filterYear.length > 0 && !isValidYear(filterYear) ? 'red' : '#ced4da'}
                        inputColor={filterYear.length > 0 && !isValidYear(filterYear) ? 'red' : '#000000'}
                        iconColor={filterYear.length > 0 && !isValidYear(filterYear) ? 'red' : '#243a76'}
                        onChange={handleYearSearch}
                    />
                </div>
                <div className='col-md-3'>
                    <select
                        className='form-select mb-2'
                        value={filterMonth}
                        onChange={handleMonthSearch}
                        style={{
                            borderRadius: '10px'
                        }}
                    >
                        <option value=''>{t("common:all")}</option>
                        {monthNames.map((val, index) => {
                            return (
                                <option key={index} value={index + 1 + ''}>
                                    {val}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
            {apiStatus.inProgress
                ? (
                    t("common:load")
                )
                : (
                    <>
                        <div className='page-body'>
                            <ScrollToTop
                                smooth
                                className='ScrollToTop'
                                component={
                                    <i className='bi bi-arrow-up-circle'></i>
                                }
                            />
                            <Table columns={columns} data={bankTransactions} />
                        </div>
                    </>
                )}
        </div>
    );
};

export default BankTransactions;

import { postData } from '../api';

import { API_END_POINT } from '../constants';

export const saveMultiPdf = (data: any) => {
	return postData(API_END_POINT.pdf.saveMultiPdf, data);
};

export const saveHSBCAccountTypesData = (data: any) => {
	return postData(API_END_POINT.pdf.saveHSBCAccountTypesData, data);
};

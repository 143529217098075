import { IAppHeader } from '../interfaces';
import { updateAppbar as updateAppHeader } from '../utils';

interface AppbarProps {
    readonly changeAppbar?: () => void;
}

interface AppbarInfo {
    readonly appHeader: IAppHeader;
    readonly updateAppbar: (appHeader: IAppHeader) => void;
    readonly resetAppbar: () => void;

}

const defaultAppHeader: IAppHeader = {
    leftIcon: 'Menu',
    leftLink: '',
    title: '',
    showAddIcon: false,
    addLink: ''
};

let appHeader: IAppHeader = defaultAppHeader;

const useAppbar = ({ changeAppbar }: AppbarProps): AppbarInfo => {
    const updateAppbar = (data: IAppHeader) => {
        updateAppHeader(data);
        appHeader = data;
        if (changeAppbar) {
            changeAppbar();
        }
    };

    const resetAppbar = () => {
        updateAppHeader(defaultAppHeader);
        appHeader = defaultAppHeader;
    };
    return { appHeader, updateAppbar, resetAppbar };
};

export default useAppbar;

import React, { useEffect, useState } from 'react';
import { IApiCallStatus, IFinancialReports, IFRSubItem, IFRTrans, IPagination } from '../../interfaces';
import {
    DEFAULT_PAGE_SIZE,
    showNotification
    // summarizeText,
} from '../../utils';
import ScrollToTop from 'react-scroll-to-top';
import useAppbar from '../../hooks/useAppbar';
import { useParams, useNavigate } from 'react-router-dom';
import { getFinancialReports } from '../../services/manualJournal.service';
import { getDefaultFinancialEndDate } from './../../utils/index';
import { useTranslation } from 'react-i18next';
import html2pdf from 'html2pdf.js';
const TransUI = ({ tran, onRowClick }: { tran: IFRTrans; onRowClick?: () => void }) => {
    return <div >
        <div className={`ms-3 my-2 d-flex justify-content-between`} onClick={onRowClick}>
            <div className={`${tran.isTotal ? 'fw-bold' : 'fw-normal fs-6'} ${onRowClick ? 'text-primary hand' : ''}`}>{tran.label}</div>
            <div className={`${tran.isTotal ? 'fw-bold' : 'fw-normal fs-6'}`}>{tran.amountValue ?? '$0'}</div>
        </div>
        <hr className='ms-3 my-1' />
    </div>;
};

interface IFinReportsInfo {
    companyName: string,
    endDate: string,
    startDate: string,
    financialStartDate: string,
    financialEndDate: string,
    previousFinancialEndDate: string,
    previousFinancialStartDate: string
}

const BalanceSheet = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams()
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [financialReports, setFinancialReports] = useState<any[]>([]);
    const [financialReportsInfo, setFinancialReportsInfo] = useState<Partial<IFinReportsInfo>>();
    const [dfaultFinancialEndDate] = useState(getDefaultFinancialEndDate())

    const [page, setPage] = useState<IPagination>({
        totalItems: 0,
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: 0,
        query: ''
    });

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const fetchData = async (
        pageNumber = page.current,
        endDate = dfaultFinancialEndDate,
        cb?: (data: any) => void
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                endDate
            };
            const bankTransResp = await getFinancialReports(data);
            console.log('bankTransResp', bankTransResp);
            if (bankTransResp.status === 200) {
                setPage({
                    ...page,
                    current: pageNumber,
                    totalItems: bankTransResp.data.totalItems
                });
                const resData = bankTransResp.data;
                if (cb) {
                    cb(bankTransResp.data);
                }
                // setBankTransactions(resData.items);
                setFinancialReportsInfo({
                    companyName: resData.companyName,
                    endDate: resData.endDate,
                    startDate: resData.startDate,
                    financialStartDate: resData.financialStartDate,
                    financialEndDate: resData.financialEndDate,
                    previousFinancialEndDate: resData.previousFinancialEndDate,
                    previousFinancialStartDate: resData.previousFinancialStartDate
                });
                setFinancialReports(resData.financialReports);
                changeApiStatus(false, '');
            } else {
                throw new Error(bankTransResp.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const gotoManualJournals = (data: any) => {
        console.log(data)
        const startDate = data.startDate;
        const endDate = data.endDate;
        const accountId = data.accountId;
        const searchStartDate = `${startDate ? '&startDate=' + startDate : ''}`
        const searchEndDate = `${endDate ? '&endDate=' + endDate : ''}`
        const searchAccountId = `${accountId ? '&accountId=' + accountId : ''}`
        navigate('/manual-journals?' + `${searchStartDate}${searchEndDate}${searchAccountId}`)
    }

    useEffect(() => {
        if(params.endDate) {
            fetchData(undefined, params.endDate);
        }
        updateAppbar({
            leftLink: '/financial-reports',
            leftIcon: 'Back',
            title: t("common:balance_sheet"),
            showAddIcon: false,
            addLink: '/',
            rightComponent: null
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.endDate]);

    const handleDownload = (rowData: any) => {
        const content = document.getElementById('download-pdf');
        if (content) {
            const opt = {
                margin: 10,
                filename: `${rowData.companyName}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            };
            html2pdf(content, opt);
        }
    };

    // const gotoDetailsPage = (id: string) => {
    //     navigate('/manual-journals/' + id);
    // };
    return (
        <div className='transation-list'>
            <div className='row'>
                <div className='col-md-3'>
                    {/* <CustomSearchBox key={'search'} onChange={handleSearch} /> */}
                </div>
            </div>
            {apiStatus.inProgress
? (
                t("common:load")
            )
: (
                <>
                    <div className='page-body'>
                        <ScrollToTop
                            smooth
                            className='ScrollToTop'
                            component={
                                <i className='bi bi-arrow-up-circle'></i>
                            }
                        />
                         <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'flex-end'
                            }}>
                                <button id='pdf-btn' style={{
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    backgroundColor: '#243a77',
                                    color: 'white',
                                    border: "none",
                                    boxShadow: '0 0px 7px 3px #243a7759',
                                    height: "30px",
                                    borderRadius: '5px'
                                }} onClick={() => { handleDownload(financialReportsInfo) }}> {t("common:download_pdf")}    <i style={{ color: 'white' }} className="bi bi-download"></i></button>
                            </div>
                        <div className='' id="download-pdf">
                            <h5>{t("common:balance_sheet")}</h5>
                            <h6>{financialReportsInfo?.companyName}</h6>
                            <h6>{"as it " + financialReportsInfo?.endDate}</h6>
                            <br />
                            <div className='text-end'>
                                <span >{financialReportsInfo?.endDate}</span>
                                <hr className='mt-1' />
                            </div>
                            {financialReports.length
? financialReports.map((list: IFinancialReports, idx: number) =>
                                <div key={idx}>
                                    <b>{list.heading}</b>
                                    <hr className='my-1' />
                                    {list.subItems
? list.subItems.map((subItem: IFRSubItem, idx2: number) =>
                                        <div className='ms-3' key={idx2}>
                                            {subItem.subHeading && <b>{subItem.subHeading}</b>}
                                            <hr className='my-1' />
                                            {subItem.trans.map((tran: IFRTrans, idx3: number) =>
                                                <TransUI key={idx3} tran={tran} onRowClick={!tran.isTotal ? () => gotoManualJournals({ accountId: tran.accountId, startDate: financialReportsInfo?.financialStartDate, endDate: financialReportsInfo?.financialEndDate }) : undefined}/>
                                            )}
                                        </div>
                                    )
: list.trans
? list.trans.map((tran: IFRTrans, idx3: number) =>
                                        <TransUI key={idx3} tran={tran} onRowClick={!tran.isTotal
? () => {
                                            const {
                                                financialStartDate,
                                                financialEndDate,
                                                // previousFinancialStartDate,
                                                previousFinancialEndDate
                                            } = financialReportsInfo || {}
                                            let startDate = financialStartDate;
                                            let endDate = financialEndDate;
                                            if(tran.label !== 'Current Year Earnings') {
                                                // startDate = financialStartDate;
                                                endDate = previousFinancialEndDate;
                                                startDate = ""
                                            }
                                            gotoManualJournals({ startDate, endDate })
                                        }
: undefined} />
                                    )
: null}
                                    <div>
                                        <div className={`ms-3 my-2 d-flex justify-content-between`}>
                                            <div className={`fw-bold`}>{t("common:total")} {list.heading}</div>
                                            <div className={`fw-bold`}>{list.amountValue ?? '$0'}</div>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            )
: <div className='text-center'>{t("common:no_transaction_found")}</div>}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BalanceSheet;

import React from 'react';

const CustomHeader = ({
    rootClasses,
    title,
    titleClasses,
    rightItem,
    rightItemClasses,
    backFn
}: {
    rootClasses?: string;
    title: string;
    titleClasses?: string;
    rightItem?: React.ReactNode;
    rightItemClasses?: string;
    backFn?: () => void;
}) => {
    return (
        <div className={rootClasses || 'd-flex justify-content-between'}>
            <div className=''>
                {backFn && (
                    <img
                        width="24px"
                        height="24px"
                        src='/icons/chevron-left.svg'
                        onClick={backFn}
                        className='bi bi-chevron-left align-items-lg-baseline'
                        alt='chevron-left'
                    />
                )}
            </div>
            <div className={titleClasses || 'h2'}>{title}</div>
            <div className={rightItemClasses || ''}>{rightItem}</div>
        </div>
    );
};

export default CustomHeader;

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
    dismissNotification,
    editNotification,
    readFileAsDataUrl,
    showNotification
} from '../../utils';
import {
    uploadFile
} from '../../services';
import { IApiCallStatus, IFile } from '../../interfaces';
import Popup from '../../components/Popup';
import CustomHeader from '../../components/CustomHeader';
import { postData } from '../../api';
import { useNavigate } from 'react-router-dom';
import { API_END_POINT } from '../../constants';
import useAppbar from '../../hooks/useAppbar';
import { saveAs } from 'file-saver';
function CompanyDetails () {
    const [images, setImages] = useState<IFile[]>([]);
    const [openFile, setOpenFile] = useState<IFile>();
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };
    const upload = (file: any, cb: (data: any) => void) => {
        // console.log('File in upload is: ', upload);
        const notificationUid = 'SaveFileUpload';
        try {
            showNotification('info', t("common:file_upload"), notificationUid);
            return uploadFile(file, (event: any) => {
                const uploadRes = event.data;
                if (event.event === 'laod') {
                    editNotification(
                        notificationUid,
                        `${t("common:uploading")} ${event.progressValue}%`
                    );
                } else if (event.event === 'complete') {
                    dismissNotification(notificationUid);
                    if (uploadRes.status === 200) {
                        cb(uploadRes.data);
                        return uploadRes.data;
                    } else {
                        throw new Error(uploadRes.error);
                    }
                }
            });
        } catch (err: any) {
            showNotification('error', err.message);
        }
    };
    const onSubmit = async (e: any) => {
        e.preventDefault()
            changeApiStatus(true, '');
            const fileIds = [];
            console.log('images')
            if (images.length) {
                for (const img of images) {
                    console.log(img)
                    if (img.tempId) {
                        await upload(img.blob, (data: any) => {
                            fileIds.push(data._id);
                        });
                    } else {
                        fileIds.push(img.fileId);
                    }
                }
            }
            const body = { fileIds: [...fileIds] || [] }
            // console.log('Body is: ', body);
            const notificationUid = 'uploadFiles';
            try{
                changeApiStatus(true, '');
                showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
                const response = await postData(API_END_POINT.users.update, body)
                dismissNotification(notificationUid);
                if (response.status === 200) {
                    showNotification('success', response.message);
                    navigate('/company-docs');
                } else {
                    throw new Error("Upload Unsuccessfull")
                }
                changeApiStatus(false, '');
            } catch (err: any) {
                changeApiStatus(false, err.message);
                showNotification('error', err.message);
            }
    };
    const viewImage = (file: IFile) => {
        setOpenFile(file);
        setPopupIsOpen(true);
    };

    const removeFile = (file: any) => {
        const fileId = file.fileId || file.tempId;
        const tempFiles = images.filter((item: any) => {
            const itemId = item.fileId || item.tempId;
            console.log(fileId, itemId);
            if (itemId !== fileId) {
                return item;
            }
            return false;
        });
        setImages(tempFiles);
    };
    const onImageChange = async (e: any) => {
        try {
            const files = [...e.target.files];
            const tempFiles = [];
            for (const file of files) {
                const valueOf = new Date().valueOf();
                const tempId = '' + valueOf;
                const url = await readFileAsDataUrl(file);
                tempFiles.push({ tempId, blob: file, fileUrl: url, fileName: file.name, fileId: '' });
            }
            setImages([...images, ...tempFiles]);
            // console.log('Images are: ', images);
        } catch (err: any) {
            console.log(err);
        }
    };
    useEffect(() => {
        updateAppbar({
            leftLink: '/contact',
            leftIcon: 'Back',
            title: t("common:company_documents"),
            showAddIcon: false,
            addLink: ''
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadFile = (fileUrl: any, fileName: any) => {
        saveAs(fileUrl, fileName);
    };

    useEffect(() => {
        if (popupIsOpen && openFile && openFile?.blob && openFile?.blob.name && openFile?.blob.name.split('.').pop() === 'pdf') {
            const pdfWindow = window.open("");
            pdfWindow?.document.write("<iframe width='100%' height='100%' src='" + openFile.fileUrl + "'></iframe>");
            setPopupIsOpen(false);
        }
    }, [popupIsOpen, openFile]);

    return (
        <div className='login-page'>
            <div className='container' style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60%" }}>
                <div className='row' style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "600px",
                    height: "auto",
                    boxShadow: "0 0 5px",
                    borderRadius: "5px",
                    padding: "20px",
                    boxSizing: "border-box"
                }}>
                    <input
                        type='file'
                        accept='image/*'
                        name='camera'
                        id='opencamera'
                        onChange={onImageChange}
                        capture="environment"
                        hidden
                        multiple
                    />
                    <input
                        type='file'
                        accept='*'
                        name='gallery'
                        id='opengallery'
                        onChange={onImageChange}
                        hidden
                        multiple
                    />

                    <Popup
                        isOpen={popupIsOpen}
                        onCloseModal={() => setPopupIsOpen(false)}
                    >
                        <CustomHeader
                            title=''
                            backFn={() => setPopupIsOpen(false)}
                        />
                        <div style={{
                    width: "100%",
                    height: "100%"
                }}>
                    {openFile && openFile.fileUrl && (
                        <>
                            {openFile.fileUrl.split(".").pop() === "pdf"
                                ? <iframe src={openFile.fileUrl} style={{
                                    width: "100%",
                                    height: "100%"
                                }} />
                                : <>
                                <div style={{ width: "100%", textAlign: "right", marginRight: '50px' }}>
                                <button className="btn p-0" style={{ color: "blue" }} onClick={() => { downloadFile(openFile.fileUrl, openFile.fileName) }}>
                                    {t("common:download_my_file")}
                                </button>
                                </div>
                                <div style={{ width: "100%", height: "100%" }}>
                                <img width="100%" height="auto" src={openFile.fileUrl} alt="text" className="mt-4 mb-3" />
                                </div>
                                </>}
                        </>)}

                </div>
                    </Popup>
                    <form
                        className='mt-4 mb-4 '
                        onSubmit={onSubmit}
                    >
                        <div>
                            <label className='form-label text-start d-block'>
                                {t("common:upload_file")}
                            </label>
                        </div>
                        <div className='col-12 input-group d-block' style={{
                            zIndex: 0
                        }}>
                            <div className='text-start mt-3' style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                width: "100%"
                            }}>
                                <label
                                    className='w-25 btn btn-secondary login-btn mr-4 hand'
                                    style={{
                                        marginRight: "10px",
                                        flex: "0 0 120px",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        padding: "10px 5px",
                                        marginBottom: "10px"
                                    }}
                                    htmlFor='opencamera'
                                >
                                    {t("common:camera")}
                                </label>
                                <label
                                    className='w-25 btn btn-secondary login-btn cancel-btn hand'
                                    htmlFor='opengallery'
                                    style={{
                                        flex: "0 0 120px",
                                        textAlign: "center",
                                        fontSize: "14px",
                                        padding: "10px 5px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    {t("common:upload")}
                                </label>
                            </div>
                        </div>
                        <div className='mt-3'>
                            {images?.map((file: any, idx: number) => {
                                return <div className='mt-1' key={idx}>
                                    <div className='d-flex justify-content-between mb-4'>
                                    <div className='text-blue hand' style={{ pointerEvents: (file?.blob?.type?.split('/')[0] === 'image' || (file?.fileName?.split('.').pop() !== "zip")) ? "auto" : "none" }} onClick={() => viewImage(file)}>{file.fileName}</div>
                                        <div>
                                            <img
                                                className='hand'
                                                width={18}
                                                height={18}
                                                src='/icons/xmark.svg'
                                                alt='img-icon'
                                                onClick={() => removeFile(file)}
                                            />
                                        </div>
                                    </div>
                                </div>;
                            })}
                        </div>
                        <div className='mt-4'>
                            <button
                                type='submit'
                                className='btn btn-secondary login-btn'
                                disabled={apiStatus.inProgress || images.length === 0}
                            >
                                {t("common:save")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CompanyDetails

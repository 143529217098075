import { getLocalUserData } from "./cookie";

declare global {
  interface Window {
    runConfig: any
    reloadHeader: any
  }
}

export * from './notification' // for notification on top right toast
export * from './cookie'
export * from './appHeader'

export const BASE_URL = window.runConfig.BaseURL
export const ROLES = window.runConfig.roles || {
  admin: 1,
  user: 2
}
export const ENVIRONMENT = window.runConfig.environment || ''
export const DEBOUNCE_TIME = 700
export const UserDataKey = window.runConfig.Keys.UserData
export const SHAREDCOOKIE = '/'
export const LANDING_VIDEO_URL = window.runConfig.LandingVideourl || 'https://api1.teampro.dev/public/upload/7emtqpc3q8Image_from_iOS.MP4'
export const DEFAULT_PAGE_SIZE = 100000

export const dateFormatForInput = (date = '') => date.split('T')[0]

export const readFileAsDataUrl = async (file: any): Promise<string> => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
        resolve(reader.result as string)
    }
    reader.readAsDataURL(file)
  })
}

export const isValidYear = (year: string) => {
  return /^(19|20)\d{2}$/.test(year)
}

export const summarizeText = (text: string, count: number) => {
  const tempText = text || ''
  if (tempText.length <= count) {
    return text
  }
  return tempText.substring(0, count) + '...'
}

/**
* Gets random int
* @param min 1
* @param max 999999999
* @returns random int - min & max inclusive
*/
export const getRandomInt = (min = 1, max = 999999999): number => {
  const tempMin = Math.ceil(min)
  const tempMax = Math.floor(max)
  return Math.floor(Math.random() * (tempMax - tempMin + 1)) + tempMin
}

/**
* Group Array array
* @param arr []
* @param key string
* @param newArrNew string, group array key
* @param heading string, for heading key
* @returns group array array, [{heading:'heading', newArrNew:[] }]
*/
export const groupArray = (arr: Array<{ [x: string]: any }>, key: string, newArrNew: string, heading = 'heading') => {
  const result = arr.reduce((acc: any, d: any) => {
    const found: any = acc.find((a: any) => a[key] === d[key])
    const value = { ...d } // the element in data property
    if (!found) {
      acc.push({ [heading]: d[key], [key]: d[key], [newArrNew]: [value] }) // not found, so need to add data property
    } else {
      found[newArrNew].push(value) // if found, that means data property exists, so just push new element to found.data.
    }
    return acc
  }, [])
  return result.sort((a: any, b: any) => a[heading].localeCompare(b[heading]))
}

export const getDefaultFinancialEndDate = () => {
  const currentYear = new Date().getFullYear()
//   const currentMont = new Date().getMonth();
  const userData = getLocalUserData() || {};
  const today = new Date();
  const fYearEndDay = userData.financialYearEndDay || "31"
  const fYearEndMonth = userData.financialYearEndMonth || "03";
  const fYearEndDate = new Date(`${currentYear}-${fYearEndMonth}-${fYearEndDay}`)

  const todayValue = today.valueOf();
  const fYearEndDateValue = fYearEndDate.valueOf();
//   console.log(todayValue, fYearEndDateValue);
  let date = ''
  if(todayValue >= fYearEndDateValue) {
    date = `${currentYear}-${fYearEndMonth}-${fYearEndDay}`
  } else {
    date = `${currentYear - 1}-${fYearEndMonth}-${fYearEndDay}`
  }
  return date
}

export const text = () => {
  return true
}

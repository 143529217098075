import { postData, getData, getDataWithQuery, deleteData } from '../api';

import { API_END_POINT } from '../constants';

export const saveInvoice = (data: any) => {
    return postData(API_END_POINT.invoice.save, data);
};

export const getInvoiceById = (id: string) => {
    return getData(API_END_POINT.invoice.getById, id);
};

export const getAllInvoices = (data: any) => {
    return getDataWithQuery(API_END_POINT.invoice.getAll, data);
};

export const deleteInvoice = (id: string) => {
    return deleteData(API_END_POINT.invoice.delete, id);
};

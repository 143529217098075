import { useState } from 'react';
import { IPagination } from '../interfaces';

const defaultPgae: IPagination = {
    totalItems: 0,
    current: 1,
    pageSize: 20,
    pageNumber: 0,
    query: ''
};

type TUsePaginationReturn = [Array<any>, IPagination, (data: any) => void, (data: IPagination) => void];
const usePagination = (initialPgae?: Partial<IPagination>): TUsePaginationReturn => {
    const [data, setData] = useState<Array<any>>([]);
    const [page, setPage] = useState<IPagination>({
        ...defaultPgae,
        ...initialPgae
    });
    const onUpdateData = (data: any) => {
        if (data && Array.isArray(data.items)) {
            setData(data.items);
            setPage({
                ...page,
                current: data.pageNumber,
                totalItems: data.totalItems
            });
        }
    };
    const onUpdatePage = (data: Partial<IPagination>) => {
        setPage({
            ...page,
            ...data
        });
    };
    return [data, page, onUpdateData, onUpdatePage];
}

export default usePagination;

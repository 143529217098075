export const API_END_POINT = {
    login: '/api/User/Login',
    users: {
        me: '/api/user/me',
        adminLogin: '/api/user/validateAdminAfterRedirection',
        getAll: '/api/User/GetAll',
        getById: '/api/User/Get',
        save: '/api/User/Save',
        delete: '/api/User/Delete',
        blocked: '/api/User/Blocked',
        update: "/api/user/update",
        otpStart: "/api/user/start-verify",
        otpCheck: "/api/user/check-verify"
    },
    file: {
        upload: '/api/file/save',
        delete: '/api/file/delete'
    },
    report: {
        saveReport: '/api/Report/SaveReport',
        shareReport: '/api/Report/ShareReport',
        generatePDF: '/api/Report/GeneratePDF',
        removePDF: '/api/User/RequestAgain'
    },
    category: {
        getAll: '/api/category/getAll',
        getById: '/api/category/get',
        save: '/api/category/save',
        delete: '/api/category/delete',
        type: '/api/category/type',
        parent: '/api/category/parent',
        banks: '/api/category/banks'
    },
    transaction: {
        getAll: '/api/trans/getAll',
        getById: '/api/trans/get',
        save: '/api/trans/save',
        delete: '/api/trans/delete',
        getAllDateRange: '/api/trans/getAllDateRange',
        bankTrans: '/api/transactions/getAll',
        bankTranDelete: '/api/transactions/delete',
        bankTranById: '/api/transactions/get',
        bankTranReconcile: '/api/transactions/reconcile'
    },
    country: {
        getAllCountries: '/api/Country/GetCountries',
        getAllStates: '/api/Country/GetStates',
        getAllCities: '/api/Country/GetCities'
    },
    pdfUpload: {
        pdf: '/api/pdf',
        hsbc: '/api/pdf/parse-hsbc',
        schk: '/api/pdf/parse-schk',
        hsb: '/api/pdf/parse-hsb',
        dbs: '/api/pdf/parse-dbs',
        image: '/api/image',
        pao: '/api/image/parse-pao',
        scb: '/api/image/parse-scb',
        checkPdfAccounts: '/api/pdf/parse-multi-pdf',
        getHSBCAccountType: '/api/pdf/get-hsbc-account-types'
    },
    saveBankTransactions: {
        saveBocTransactions: '/api/image/save-boc',
        savePaoTransactions: '/api/image/save-pao',
        saveScbTransactions: '/api/image/save-scb'
    },
    pdf: {
        saveMultiPdf: "/api/pdf/save-multi-pdf",
        saveHSBCAccountTypesData: '/api/pdf/save-hsbc-account-types-data'
    },
    statement: {
        getAll: '/api/statements/getAll',
        delete: '/api/statements/delete'
    },
    manualJournal: {
        getAll: '/api/manualJournal/getAll',
        getById: '/api/manualJournal/get',
        save: '/api/manualJournal/save',
        delete: '/api/manualJournal/delete',
        getFinancialReports: '/api/manualJournal/getFinancialReports'
    },
    contact: {
        getAll: '/api/contact/getAll',
        getById: '/api/contact/get',
        save: '/api/contact/save',
        delete: '/api/contact/delete'
    },
    invoice: {
        getAll: '/api/invoice/getAll',
        getById: '/api/invoice/get',
        save: '/api/invoice/save',
        delete: '/api/invoice/delete'
    }
};

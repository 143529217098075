import { fileUpload, responseHandler } from '../api';
import { API_END_POINT } from '../constants';
import { IPdfUploadEndpoint } from '../interfaces';

export const uploadFile = (file: any, onUploadProgress: any) => {
    const formData = new FormData();
    formData.append("file", file);
    return fileUpload(API_END_POINT.file.upload, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress: (progressEvent: any) => {
            onUploadProgress({ event: 'load', progressValue: `${Math.ceil((progressEvent.loaded / progressEvent.total) * 100)}`, data: progressEvent });
        }
    }).then((val) => {
        console.log(val);
        onUploadProgress({ event: 'complete', data: responseHandler(val) });
    });
};

const pdfUploadEndpoint: IPdfUploadEndpoint = {
    '': '',
    pdf: API_END_POINT.pdfUpload.pdf,
    hsbc: API_END_POINT.pdfUpload.hsbc,
    schk: API_END_POINT.pdfUpload.schk,
    hsb: API_END_POINT.pdfUpload.hsb,
    checkPdfAccounts: API_END_POINT.pdfUpload.checkPdfAccounts,
    getHSBCAccountType: API_END_POINT.pdfUpload.getHSBCAccountType,
    dbs: API_END_POINT.pdfUpload.dbs,
    image: API_END_POINT.pdfUpload.image,
    pao: API_END_POINT.pdfUpload.pao,
    scb: API_END_POINT.pdfUpload.scb
};

export const uploadWithEndpoint = (endpoint: keyof IPdfUploadEndpoint, file: any, statement: any, onUploadProgress: any, fileName = "file", isMultiUpload = false) => {
// export const uploadWithEndpoint = (endpoint: keyof IPdfUploadEndpoint, file: any, onUploadProgress: any, fileName = "file", isMultiUpload = false) => {
    const formData = new FormData();
    if(isMultiUpload) {
        const tempFiles: Array<any> = file;
        for (let i = 0; i < tempFiles.length; i++) {
            formData.append(fileName, tempFiles[i]);
            if(statement) {
                formData.append("statement", JSON.stringify(statement));
            }
        }
    } else {
        formData.append(fileName, file);
            if(statement) {
            formData.append("statement", JSON.stringify(statement));
            }
    }
    try {
        return fileUpload(pdfUploadEndpoint[endpoint], formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": '*'
            },
            onUploadProgress: (progressEvent: any) => {
                onUploadProgress({ event: 'load', progressValue: `${Math.ceil((progressEvent.loaded / progressEvent.total) * 100)}`, data: progressEvent });
            }
        }).then((val) => {
            const resData = responseHandler(val);
            if (resData.status === 200) {
                onUploadProgress({ event: 'complete', data: resData });
            } else {
                throw new Error(resData.error);
            }
        });
    } catch (err) {
        onUploadProgress({ event: 'error', error: err });
    }
};

import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/global.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import AuthProvider from './context/AuthProvider'
import Modal from 'react-modal'
import './i18n'

Modal.setAppElement('#root')
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  // <React>
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path='/*' element={<App />} />
                </Routes>
            </AuthProvider>
        </Router>
  // </React>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { IApiCallStatus, IPagination, IPdfUploadEndpoint } from '../../interfaces';
import { deleteStatement, getAllBank, getAllCategory, getAllStatement, saveHSBCAccountTypesData, saveMultiPdf, uploadWithEndpoint, saveBocTransactions, savePaoTransactions, saveScbTransactions, uploadFile } from '../../services';
import { DEFAULT_PAGE_SIZE, dismissNotification, editNotification, groupArray, showNotification } from '../../utils';
import ScrollToTop from 'react-scroll-to-top';
import { Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Button, Form, FormGroup, Input, Label, Alert } from 'reactstrap';

import Table from '../../components/Table';
import TransactionsForm from '../../components/TransactionsForm';
import PaoTransactionsForm from '../../components/PaoTransactionsForm';
import ScbTransactionsForm from '../../components/ScbTransactionsForm';
import useAppbar from '../../hooks/useAppbar';
import InplaceConfirm from '../../components/InplaceConfirm';
import { DEBOUNCE_TIME } from '../../constants';
import CustomSearchBox from '../../components/CustomSearchBox';
import Popup from '../../components/Popup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
// import { fileUpload } from '../../api';
let currentPagination = 1;
let distinctUntilChange: any = null;

type IOpenPopupType = "" | "Multi" | "NoAccount" | "Single";
const tempAcTypes: Array<{ label: string, value: string, isChecked: boolean;[x: string]: any; }> = [];

interface StatementInfo {
    fileUrl?: string;
    originalName?: string;
    fileName?: string;
    bank?: string;
    addedBy?: string;
}
const UploadedBankStatements = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [statements, setStatements] = useState<any[]>([]);
    const [selectedFile, setSelectedFile] = useState<StatementInfo | undefined>();
    const [multipleBankAccounts, setMultipleBankAccounts] = useState<Array<{ label: string, value: string, isChecked: boolean; isMatchCOA: boolean, [x: string]: any; }>>([]);
    const [statementBankInfo, setStatementBankInfo] = useState<{ [x: string]: any; }>();
    const [statementAccountType, setStatementAccountType] = useState<Array<{ label: string, value: string, isChecked: boolean;[x: string]: any; }>>([...tempAcTypes]);
    const [popupTitle, setPopupTitle] = useState<string>('');
    const [popupSubTitle, setPopupSubTitle] = useState<string>('');
    const [popupText, setPopupText] = useState<string>(t("common:bank_name"));
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    const [openFileTypePopup, setOpenFileTypePopup] = useState<boolean>(false);
    const [selectedFileType, setSelectedFileType] = useState('');
    const [fileError, setFileError] = useState<boolean>(false);
    const [selectedBank, setSelectedBank]: any = useState(null);
    const [openPopUpType, setOpenPopupType] = useState<IOpenPopupType>("");
    const [isFileMultiple, setIsFileMultiple] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [errorMessage, setErrorMessage]: any = useState(null);
    const [isImageProcessed, setIsImageProcessed] = useState(false);
    const [imageProcessingState, setImageProcessingState] = useState('');
    const [bankName, setBankName] = useState<string>('');
    const [openLoadingModal, setOpenLoadingModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [isTransactionReviewed, setTransactionsReviewed] = useState(false);
    const [transactionToReview, setTransactionsToReview] = useState(false);
    const [imageTransactionsData, setImageTransactionsData] = useState([]);
    const [selectedTransactions, setSelectedTransactions] = useState([]);
    const [statementInfo, setStatementInfo] = useState({});
    const [myStatements, setMyStatements] = useState<Array<{ fileUrl: string, originalName: string }>>([]);
    let myStatement = {};
    const [querySearch, setQuery] = useState('');
    const [page, setPage] = useState<IPagination>({
        totalItems: 0,
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: 0,
        query: ''
    });

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const openPopup = (popupType: IOpenPopupType) => {
        setOpenPopupType(popupType);
        setPopupIsOpen(true);
    };

    const closePopup = () => {
        setOpenPopupType("");
        setPopupIsOpen(false);
    };

    const onChangeCheckbox = (idx: number) => {
        const tempList = [...multipleBankAccounts];
        tempList[idx] = {
            ...tempList[idx],
            isChecked: !tempList[idx].isChecked
        };
        setMultipleBankAccounts(tempList);
    };

    const onCkeckAccountType = (idx: number) => {
        const tempList = [...statementAccountType];
        tempList[idx] = {
            ...tempList[idx],
            isChecked: !tempList[idx].isChecked
        };
        setStatementAccountType(tempList);
    };

    const goto = (path: string) => {
        navigate(path);
    };

    const showFileInputPopup = (option: any) => {
        setOpenFileTypePopup(true);
        setSelectedBank(option);
        // setBankName(option.value);
    }

    const closeFileInputPopup = () => {
        setOpenFileTypePopup(false);
        setFileError(false);
        setSelectedFileType('');
    }

    const handleFileTypeCheck = (e: any) => {
        setSelectedFileType(e.target.value);
        setFileError(false);
    }

    const handleFileTypeFormSubmit = () => {
        if (selectedFileType === '') {
            setFileError(true);
            return;
        }
        setOpenFileTypePopup(false);
        if (selectedBank.value === 'pdf' && selectedFileType === 'scanned') {
            setSelectedFileType('');
            handleFileOpen('image');
        } else {
            setSelectedFileType('');
            handleFileOpen(selectedBank.value);
        }
    }

    const saveTransactionsData = async () => {
        try {
            let response;
            if (bankName === 'image') {
                console.log("BOC CAlled");
                response = await saveBocTransactions({ transactions: selectedTransactions, statement: { ...statementInfo, fileUrl: selectedFile?.fileUrl, originalName: selectedFile?.originalName } });
            } else if (bankName === 'pao') {
                response = await savePaoTransactions({ transactions: selectedTransactions, statement: { ...statementInfo, fileUrl: selectedFile?.fileUrl, originalName: selectedFile?.originalName } });
            } else if (bankName === 'scb') {
                response = await saveScbTransactions({ transactions: selectedTransactions, statement: { ...statementInfo, fileUrl: selectedFile?.fileUrl, originalName: selectedFile?.originalName } });
            }
            if (response?.status === 200) {
                fetchData();
            } else {
                throw new Error(response?.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        } finally {
            setIsImageProcessed(false);
            setOpenWarningModal(false);
            setTransactionsReviewed(false);
            setTransactionsToReview(false);
        }
    }

    const fetchData = useCallback(async (
        pageNumber = page.current,
        query = querySearch ?? '',
        pageSize = page.pageSize,
        cb?: (data: any) => void
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                pageNumber,
                pageSize,
                filter: query
            };
            const usersResponse = await getAllStatement(data);
            if (usersResponse.status === 200) {
                setPage({
                    ...page,
                    current: pageNumber,
                    totalItems: usersResponse.data.totalItems
                });
                const resData = usersResponse.data;
                if (cb) {
                    cb(usersResponse.data);
                }
                setStatements(resData.items);
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileOpen = (val: keyof IPdfUploadEndpoint) => {
        setBankName(val);
        if (val === "hsbc") {
            setIsFileMultiple(false);
            setIsImage(false);
            setTimeout(() => {
                openFile("getHSBCAccountType");
            }, 500);
        } else if (val === "image") {
            setIsFileMultiple(false);
            setIsImage(true);
            setTimeout(() => {
                openFile("image");
            }, 500);
        } else if (val === "pao") {
            setIsFileMultiple(false);
            setIsImage(true);
            setTimeout(() => {
                openFile("pao");
            }, 500);
        } else if (val === "scb") {
            setIsFileMultiple(false);
            setIsImage(true);
            setTimeout(() => {
                openFile("scb");
            }, 500);
        } else {
            setIsFileMultiple(true);
            setIsImage(false);
            setTimeout(() => {
                openFile(val);
            }, 500);
        }
    };

    const getCategories = async (bList: Array<any>) => {
        try {
            changeApiStatus(true, '');
            const data = {
                pageNumber: 1,
                pageSize: 10000,
                isBank: true
            };
            const usersResponse = await getAllCategory(data);
            if (usersResponse.status === 200) {
                const items = usersResponse.data.items || [];
                // console.log("Items are ------->> :", items);
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    const tepmItem = bList.find((itemB) => item.bankName === itemB.value);
                    tempList.push({
                        label: item.category,
                        value: tepmItem ? tepmItem.path : '',
                        path: tepmItem ? tepmItem.path : ''
                    });
                    return item;
                });
                // console.log("Templist :", tempList);
                // const index = tempList.findIndex(item => item['value'] === 'pdf');
                // if(index !== -1) {
                //     tempList.splice(index + 1, 0, { label: `${tempList[index].label} (Scanned)`, value: 'image', path: 'image' });
                // }
                // if(tempList.findIndex(item => item?.value === 'pdf') !== -1) {
                //     tempList.push({
                //         label: 'BOCHK (Scanned)',
                //         value: 'image',
                //         path: 'image'
                //     });
                // }
                // if(tempList.findIndex(item => item?.value === 'pao') !== -1) {
                //     tempList.push({
                //         label: 'PAO (Scanned)',
                //         value: 'pao',
                //         path: 'pao'
                //     });
                // }

                changeApiStatus(false, '');
                if (tempList.length) {
                    const rightComponent = <div className="dropdown">
                        <button onClick={() => setIsImageProcessed(false)} className="btn p-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-plus-lg"></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{
                            height: 'auto'
                        }}>
                            {
                                tempList.map((opt, index) => {
                                    return <li key={index} onClick={() => showFileInputPopup(opt)}>
                                        <span className="dropdown-item hand">{opt.label}</span>
                                    </li>;
                                })
                            }
                            <hr className="m-0" />
                            <li onClick={() => handleFileOpen("checkPdfAccounts")}>
                                <span className='dropdown-item hand'>{t("common:multiple")}</span>
                            </li>
                            {/* List item to upload image for text extraction */}
                            {/* <hr className="m-0" />
                            <li onClick={() => handleFileOpen("image")}>
                                <span className='dropdown-item hand'>BOCHK (Scanned)</span>
                            </li>
                            <li onClick={() => handleFileOpen("pao")}>
                                <span className='dropdown-item hand'>PAO (Scanned)</span>
                            </li> */}
                        </ul>
                    </div>;
                    updateAppbar({
                        leftLink: '',
                        leftIcon: 'Menu',
                        title: t("common:uploaded_bank_statements"),
                        showAddIcon: false,
                        addLink: '',
                        rightComponent
                    });
                }
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const getBanks = async (cb: (data: Array<any>) => void) => {
        try {
            const getAllBanksRes = await getAllBank();
            if (getAllBanksRes.status === 200) {
                const items = getAllBanksRes.data || [];
                const tempList: Array<any> = [];
                let tempText = t("common:text_message");
                const itemLength = items.length;
                items.map((item: any, idx: number) => {
                    tempList.push({
                        label: item.label,
                        value: item.value,
                        path: item.path
                    });
                    if (idx && itemLength === idx + 1) {
                        tempText += t("common:and") + item.label;
                    } else {
                        tempText += `${item.label}, `;
                    }
                    return item;
                });
                setPopupText(tempText);
                cb(tempList);
            } else {
                throw new Error(getAllBanksRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    useEffect(() => {
        fetchData();
        getBanks(getCategories);
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: t("common:uploaded_bank_statements"),
            showAddIcon: false,
            addLink: '',
            rightComponent: <div className="dropdown">
                <button className="btn p-0" type="button" onClick={() => openPopup("NoAccount")}>
                    <i className="bi bi-plus-lg"></i>
                </button>
            </div>
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [uploadInProgress, setUploadProgress] = useState(false);
    const [typeSelector, setTypeSelector] = useState<keyof IPdfUploadEndpoint>('pdf');

    const myUpload = async (file: any, cb: (data: any) => void) => {
        // console.log('File in upload is: ', upload);
        const notificationUid = 'SaveFileUpload';
        try {
            showNotification('info', t("common:file_upload"), notificationUid);
            return await uploadFile(file, (event: any) => {
                const uploadRes = event.data;
                if (event.event === 'laod') {
                    editNotification(
                        notificationUid,
                        `${t("common:uploading")} ${event.progressValue}%`
                    );
                } else if (event.event === 'complete') {
                    dismissNotification(notificationUid);
                    if (uploadRes.status === 200) {
                        cb(uploadRes.data);
                        return uploadRes.data;
                    } else {
                        throw new Error(uploadRes.error);
                    }
                }
            });
        } catch (err: any) {
            showNotification('error', err.message);
        }
    };
    const processFile = (event: any) => {
        const file = event.target.files;
        if (file.length) {
            if (typeSelector === "checkPdfAccounts") {
                upload(file, typeSelector, (data: any) => {
                    const fileDetails = data.fileDetails;
                    if ((fileDetails && Array.isArray(fileDetails)) && fileDetails.length) {
                        const tempData = groupArray(data.fileDetails || [], "label", "groupData");
                        setMultipleBankAccounts(
                            [...tempData].map(item => ({
                                ...item,
                                label: item.label,
                                value: item.label,
                                isMatchCOA: item.groupData[0]?.isMatchCOA,
                                isChecked: false
                            }))
                        );
                        setPopupTitle(t("common:multiple_accounts"));
                        setPopupSubTitle(t("common:account_upload"))
                        openPopup("Multi");
                    } else {
                        setPopupTitle(t("common:not_supported"));
                        setPopupSubTitle(t("common:file_support"))
                        setMultipleBankAccounts([])
                        openPopup("Multi");
                    }
                }, "", true, "pdfFile", true);
                // if(file) {
                //     myUpload(file, (data: any) => {
                //         setSelectedFile(data);
                //     });
                // }
            } else if (typeSelector === "getHSBCAccountType") {
                upload(file, typeSelector, (data: any) => {
                    if (data.results) {
                        const accountTypesData = data.results;
                        if (accountTypesData.length) {
                            const tempAcTypes: Array<any> = [];
                            accountTypesData.map((item: any) => {
                                const { bankInfo, result = [], year } = item;
                                result.map((item2: any) => {
                                    const tempObj = {
                                        bankInfo,
                                        ...item2,
                                        year,
                                        fileName: bankInfo.fileName,
                                        accountType: item2.accountNumber.value,
                                        label: item2.accountNumber.value,
                                        value: item2.accountNumber.value,
                                        isChecked: false
                                    };
                                    tempAcTypes.push(tempObj);
                                    setPopupText(bankInfo.label || "");
                                    setStatementBankInfo(bankInfo);
                                    return item2;
                                });
                                return item;
                            });
                            setStatementAccountType(tempAcTypes);
                            openPopup("Single");
                        }
                    }
                }, "", true, "pdfFile", true);
                // if(file) {
                //     myUpload(file, (data: any) => {
                //         setSelectedFile(data);
                //     });
                // }
            } else {
                uploadFileToServer(file, typeSelector);
            }
        }
    };

    const onSaveAccountTypes = async () => {
        closePopup();
        const tempData = statementAccountType.filter(item => item.isChecked);
        if (tempData.length) {
            const tempGroupData = groupArray(tempData || [], "fileName", "result");
            const results = tempGroupData.map((item: any) => {
                return {
                    result: item.result.map((item2: any) => ({ data: item2.data, accountNumber: item2.accountNumber })),
                    year: item.result[0].year,
                    bankInfo: item.result[0].bankInfo,
                    fileName: item.fileName,
                    fileUrl: selectedFile?.fileUrl,
                    originalName: selectedFile?.fileUrl
                };
            });
            const notificationUid = 'SaveMultiPdf';
            try {
                changeApiStatus(true, '');
                showNotification(
                    'info',
                    t("common:request_process"),
                    notificationUid
                );
                const saveHSBCAccountTypesDataRes = await saveHSBCAccountTypesData({ results });
                dismissNotification(notificationUid);
                if (saveHSBCAccountTypesDataRes.status === 200) {
                    showNotification('success', saveHSBCAccountTypesDataRes.message);
                    fetchData(1);
                } else {
                    throw new Error(saveHSBCAccountTypesDataRes.error);
                }
            } catch (err: any) {
                showNotification('error', err.message);
                changeApiStatus(false, err.message);
            }
            setStatementAccountType([]);
        }
    };

    const onSaveMultiPdf = async () => {
        closePopup();
        let dataList: Array<any> = [];
        if (multipleBankAccounts.length) {
            multipleBankAccounts.forEach(item => {
                item.groupData = item.groupData.map((data: { fileName: string; }) => {
                    const matchingStatement = myStatements.find(statement => statement.originalName === data.fileName);
                    if (matchingStatement) {
                        return {
                            ...data,
                            fileUrl: matchingStatement.fileUrl,
                            originalName: matchingStatement.originalName
                        };
                    }
                    return data;
                });
            });
        }
        const checkedList: Array<any> = multipleBankAccounts.filter(item => item.isChecked);
        if (checkedList.length) {
            checkedList.forEach(item => {
                dataList = [...dataList, ...item.groupData];
            });
            const notificationUid = 'SaveMultiPdf';
            try {
                changeApiStatus(true, '');
                showNotification(
                    'info',
                    t("common:request_process"),
                    notificationUid
                );
                const saveMultiPdfRes = await saveMultiPdf({ list: dataList });
                dismissNotification(notificationUid);
                if (saveMultiPdfRes.status === 200) {
                    showNotification('success', saveMultiPdfRes.message);
                    fetchData(1);
                } else {
                    throw new Error(saveMultiPdfRes.error);
                }
            } catch (err: any) {
                showNotification('error', err.message);
                changeApiStatus(false, err.message);
            }
        }
    };

    const openFile = (val: keyof IPdfUploadEndpoint) => {
        const value = val;
        setTypeSelector(value);
        if (value) {
            document.getElementById('form-upload')?.click();
        }
    };

    const notificationId = 'SaveFileUpload';
    const upload = async (file: any, endpoint: keyof IPdfUploadEndpoint, cb: (data: any) => void, sq?: string, isLast = true, fileName = "file", isMultiUpload = false) => {
        try {
            if (file && file.length) {
                const accumulatedStatements: Array<{ fileUrl: string, originalName: string }> = [];
                for (let i = 0; i < file.length; i++) {
                    await myUpload(file[i], (data: any) => {
                        setSelectedFile(data);
                        if (!isImage) {
                            accumulatedStatements.push({
                                fileUrl: data.fileUrl,
                                originalName: data.originalName
                            });
                        }
                    });
                    setMyStatements(accumulatedStatements);
                }
            } else {
                if (file) {
                    await myUpload(file, (data: any) => {
                        setSelectedFile(data);
                        if (!isImage) {
                            myStatement = { fileUrl: data.fileUrl, originalName: data.originalName };
                        }
                    });
                }
            }
            setUploadProgress(true);
            return await uploadWithEndpoint(endpoint, file, myStatement, (event: any) => {
                // return await uploadWithEndpoint(endpoint, file, (event: any) => {
                const uploadRes = event.data;
                if (event.event === 'load') {
                    setOpenLoadingModal(true);
                    setImageProcessingState(t("common:uploading"));
                    editNotification(
                        notificationId,
                        `${t("common:uploading")}${event.progressValue}% ${sq || null}`
                    );
                    if (event.progressValue === '100') {
                        setImageProcessingState(t("common:ai_analysing"))
                    }
                } else if (event.event === 'complete') {
                    setUploadProgress(false);
                    if (isLast) {
                        dismissNotification(notificationId);
                    }
                    if (uploadRes.status === 200 || uploadRes.status === 504 || uploadRes.status === 400) {
                        setOpenLoadingModal(false);
                        cb(uploadRes.data);
                        if (isImage) {
                            // navigate('/transactions-form', { state: { transactions: uploadRes.data.transactions } })
                            if (uploadRes?.error) {
                                setErrorMessage(uploadRes?.error);
                            }
                            const newTransactionsArray = uploadRes?.data?.transactions.map((item: any) => ({ ...item, isChecked: true }));
                            setStatementInfo(uploadRes?.data?.statement);
                            setImageTransactionsData(newTransactionsArray);
                            setTransactionsToReview(true);
                            console.log(transactionToReview);
                        }
                        return uploadRes.data;
                    } else {
                        setTypeSelector('');
                        showNotification('error', uploadRes.error);
                        throw new Error(uploadRes.error);
                    }
                } else if (event.event === 'error') {
                    setOpenLoadingModal(false);
                    setUploadProgress(false);
                    dismissNotification(notificationId);
                    throw new Error(event.error);
                }
            }, fileName, isMultiUpload);
        } catch (err: any) {
            setTypeSelector('');
            showNotification('error', err.message);
        }
    };

    const uploadFileToServer = (files: Array<File>, endpoint: keyof IPdfUploadEndpoint, index = 0) => {
        const total = files.length;
        if (index === 0) {
            showNotification('info', t("common:start_uploads"), notificationId);
        }
        // if(files) {
        //     myUpload(files[index], (data: any) => {
        //         setSelectedFile(data);
        //     });
        // }
        upload(files[index], endpoint, () => {
            if (total !== index + 1) {
                uploadFileToServer(files, endpoint, index + 1);
            } else {
                if (isImage) {
                    showNotification('success', `${t("common:upload_process")}${total} ${t("common:files")}.`);
                    setIsImageProcessed(true);
                    setTypeSelector('');
                } else {
                    fetchData(1);
                    showNotification('success', `${t("common:upload_process")}${total} ${t("common:files")}.`);
                    setTypeSelector('');
                }
            }
        }, `${index + 1}/${total}`, total === index + 1);
    };

    const onDelete = useCallback(async (id: string) => {
        const notificationUid = 'DeleteStatement';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const saveStatementRes = await deleteStatement(id);
            dismissNotification(notificationUid);
            if (saveStatementRes.status === 200) {
                showNotification('success', saveStatementRes.message);
                fetchData(1);
            } else {
                throw new Error(saveStatementRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    }, [fetchData]);

    const handleSearch = (event: any) => {
        const value = event.target.value;
        if (distinctUntilChange) {
            clearTimeout(distinctUntilChange);
            distinctUntilChange = null;
        }
        distinctUntilChange = setTimeout(() => {
            currentPagination = 1;
            setQuery(value);
            setPage({ ...page, current: currentPagination });
            fetchData(1, value.trim());
        }, DEBOUNCE_TIME);
    };

    const downloadFile = (fileUrl: any, fileName: string) => {
        saveAs(fileUrl, fileName);
    };

    const columns = useMemo(
        () => [
            {
                Header: t("common:file_name"),
                // accessor: 'fileName'
                accessor: (originalRow: any) => {
                    return <><span className="text-primary hand" onClick={() => navigate('/bank-transactions?statementId=' + originalRow._id)}>{originalRow.fileName}</span></>;
                }
            },
            {
                Header: t("common:bank"),
                accessor: 'bank'
            },
            {
                Header: t("common:added_on"),
                accessor: 'addedOnValue'
            },
            {
                Header: t("common:action"),
                accessor: (originalRow: any) => {
                    const fileId = originalRow._id;
                    const file = originalRow;
                    // console.log('File is: ', originalRow, 'And', file);
                    const isUrl = file?.fullFileUrl;
                    return <>
                        <div className='d-flex justify-content-around' >
                            <button className='btn p-0' disabled={!fileId || !isUrl} onClick={() => { downloadFile(file.fullFileUrl, file.fileName) }}>
                                <i className="bi bi-download ms-3" style={{ fontSize: '1.3rem', color: (fileId && isUrl) ? 'green' : 'grey' }} />
                            </button>
                            <InplaceConfirm
                                HTMLComponent={<i className="bi bi-trash text-end hand" style={{ fontSize: '1.3rem', color: 'red' }}></i>}
                                action={() => {
                                    onDelete(originalRow._id);
                                }}
                                target={`del-icon-${originalRow._id}`}
                                text={t("common:are_you_sure")}
                            />
                        </div>
                    </>;
                }
            }
        ],
        [onDelete]
    );

    return (
        <div className='transation-list'>
            {!isImageProcessed &&
                <>
                    <CustomSearchBox onChange={handleSearch} />
                    <input accept={isImage ? "image/jpeg,image/png,application/pdf" : "application/pdf"} multiple={isFileMultiple} disabled={uploadInProgress} type="file" hidden={true} id="form-upload" onChange={processFile} />
                </>}
            {apiStatus.inProgress
                ? (
                    t("common:load")
                )
                : (
                    <>
                        <div className='page-body'>
                            {/* In start show this fragment */}
                            {!isImageProcessed
                                ? <>
                                    {/* <Popup
                                isOpen={isImageProcessed}
                                onCloseModal={() => setIsImageProcessed(false)}
                                customStyles={{
                                    content: {
                                        margin: '50px',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 'auto',
                                        textAlign: 'center',
                                        height: "80%"
                                    }
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsImageProcessed(false) } }>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>
                                //  {imageTransactionsData.map((item) => <text key={item}>{`${item}`}</text>)}
                                {imageTransactionsData
                                ? <TransactionsForm transactions={imageTransactionsData}/>
                                // <table className="table">
                                // <thead>
                                //     <tr>
                                //     <th>Transaction Date</th>
                                //     <th>Effective Date</th>
                                //     <th>Description</th>
                                //     <th>Deposit</th>
                                //     <th>WithDrawal</th>
                                //     <th>Balance</th>
                                //     <th>Account</th>
                                //     </tr>
                                // </thead>
                                // <tbody style={{ overflowY: "scroll" }}>
                                //     {imageTransactionsData && imageTransactionsData.map((item: any) => (
                                //     <tr key={item}>
                                //         <td>{item.transactionDate}</td>
                                //         <td>{item.effectiveDate}</td>
                                //         <td>{item.description}</td>
                                //         <td>{item.deposit}</td>
                                //         <td>{item.withDrawal}</td>
                                //         <td>{item.balance}</td>
                                //         <td>{item.accountName}</td>
                                //     </tr>
                                //     ))}
                                // </tbody>
                                // </table>
                                : <div className="text-center py-3">
                                <h4>Data Not Found</h4>
                                </div>
                                }
                                </div>
                            </Popup> */}
                                    <Popup
                                        isOpen={popupIsOpen}
                                        onCloseModal={() => closePopup()}
                                        customStyles={{
                                            content: {
                                                margin: '50px 10px',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 'auto',
                                                textAlign: 'center',
                                                overflowY: "scroll",
                                                height: "90%"
                                            }
                                        }}
                                    >
                                        <div className='login-page-1 commom-page'>
                                            {openPopUpType === "NoAccount"
                                                ? <div>
                                                    <h3 className="text-center w-100">{t("common:no_accounts")}</h3>
                                                    <h5 className='w-100 my-3'>{popupText}</h5>
                                                    <div className="d-flex justify-content-around">
                                                        <button className="btn btn-secondary app-primary-btn me-2" onClick={() => goto('/category/new')}>
                                                            {t("common:open_coa")}
                                                        </button>
                                                        <button className='btn btn-secondary login-btn app-cancel-btn w-100 ms-2' onClick={() => closePopup()}>
                                                            {t("common:cancel")}
                                                        </button>
                                                    </div>
                                                </div>
                                                : null}
                                            {openPopUpType === "Multi"
                                                ? <div className="p-4">
                                                    <h3 className="text-center w-100">{popupTitle}</h3>
                                                    <h5 className='w-100 my-3'>{popupSubTitle}</h5>
                                                    <div className="my-4">
                                                        {multipleBankAccounts.map((item: any, idx: number) => {
                                                            return <div className="text-start d-flex my-2" key={idx}>
                                                                <input id={`custom-checkbox-${idx}`} className="py-2 mx-2" type="checkbox" aria-label="Checkbox" checked={item.isChecked} disabled={item.isMatchCOA} onChange={() => onChangeCheckbox(idx)} style={{
                                                                    minWidth: 24,
                                                                    minHeight: 24
                                                                }} />
                                                                <label htmlFor={`custom-checkbox-${idx}`} className="fs-4">{item.label} {!item.isMatchCOA ? <span> | <span className="text-danger"> {t("common:not_found_coa")} </span> </span> : ""} </label>
                                                            </div>;
                                                        })}
                                                    </div>
                                                    <div className="d-flex justify-content-around mb-4">
                                                        <button className="btn btn-secondary app-primary-btn me-2" disabled={!multipleBankAccounts.length} onClick={() => onSaveMultiPdf()}>
                                                            {t("common:confirm")}
                                                        </button>
                                                        <button className='btn btn-secondary login-btn app-cancel-btn w-100 ms-2' onClick={() => closePopup()}>
                                                            {t("common:cancel")}
                                                        </button>
                                                    </div>
                                                </div>
                                                : null}
                                            {openPopUpType === "Single"
                                                ? <div className="p-4">
                                                    <h3 className="text-center w-100">{t("common:uploaded_bank_statements")}</h3>
                                                    <h5 className='w-100 my-3'>{popupText}</h5>
                                                    <h5 className='w-100 my-3'>
                                                        {t("common:statement_message")}
                                                    </h5>
                                                    <div className="my-4">
                                                        {statementAccountType.map((item: any, idx: number) => {
                                                            return <div className="text-start d-flex my-2" key={idx}>
                                                                <input id={`custom-checkbox-${idx}`} className="py-2 mx-2" type="checkbox" aria-label="Checkbox" disabled={statementBankInfo && statementBankInfo?.isMatchCOA} checked={item.isChecked} onChange={() => onCkeckAccountType(idx)} style={{
                                                                    minWidth: 24,
                                                                    minHeight: 24
                                                                }} />
                                                                <label htmlFor={`custom-checkbox-${idx}`} className="fs-4">{item.label} </label>
                                                            </div>;
                                                        })}
                                                    </div>
                                                    {statementBankInfo && !statementBankInfo.isMatchCOA ? <div className="text-danger fs-2 my-4">{t("common:not_found_coa")} </div> : ""}
                                                    <div className="d-flex justify-content-around mb-4">
                                                        <button className="btn btn-secondary app-primary-btn me-2" disabled={statementBankInfo && statementBankInfo?.isMatchCOA} onClick={() => onSaveAccountTypes()}>
                                                            {t("common:confirm")}
                                                        </button>
                                                        <button className='btn btn-secondary login-btn app-cancel-btn w-100 ms-2' onClick={() => closePopup()}>
                                                            {t("common:cancel")}
                                                        </button>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </Popup>
                                    {/* Modal for getting input either take scanned or pdf file */}
                                    <Modal isOpen={openFileTypePopup} toggle={() => closeFileInputPopup()} backdrop="static" keyboard={false} centered size='lg'>
                                        <ModalHeader toggle={() => closeFileInputPopup()}>{selectedBank?.label}</ModalHeader>
                                        <ModalBody>
                                            <h5>{t("common:upload_message")}</h5>
                                            <Form>
                                                {selectedBank && selectedBank.value !== 'pao' && selectedBank.value !== 'scb' && (
                                                    <FormGroup
                                                        check
                                                    >
                                                        <Input type="radio" name="inputFileType" value="pdf" onChange={handleFileTypeCheck} />
                                                        <Label check>
                                                            {t("common:pdf_files")}
                                                        </Label>
                                                    </FormGroup>
                                                )}
                                                {(selectedBank && (selectedBank.value === 'pdf' || selectedBank.value === 'pao' || selectedBank.value === 'scb')) &&
                                                    <FormGroup
                                                        check
                                                    >
                                                        <Input type="radio" name="inputFileType" value="scanned" onChange={handleFileTypeCheck} />
                                                        <Label check>
                                                            {t("common:scanned_copy")}
                                                        </Label>
                                                    </FormGroup>
                                                }
                                                {fileError && <Alert color="danger"> {t("common:no_file_type")} </Alert>}
                                            </Form>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={() => closeFileInputPopup()}>
                                                {t("common:cancel")}
                                            </Button>{' '}
                                            <Button color="primary" type='submit' onClick={handleFileTypeFormSubmit}>
                                                {t("common:upload")}
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                    {/* Modal showing wile loading */}
                                    <Modal isOpen={openLoadingModal} toggle={() => setOpenLoadingModal(false)} backdrop="static" keyboard={false} centered>
                                        <ModalBody>
                                            <div className="text-center py-3">
                                                <h4>{imageProcessingState}</h4>
                                            </div>
                                            <div className="text-center py-3">
                                                <Spinner color="secondary" style={{
                                                    height: '3rem',
                                                    width: '3rem'
                                                }}>
                                                    {t("common:load")}
                                                </Spinner>
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                    <ScrollToTop
                                        smooth
                                        className='ScrollToTop'
                                        component={
                                            <i className='bi bi-arrow-up-circle'></i>
                                        }
                                    />
                                    <br />
                                    <Table columns={columns} data={statements} />
                                </>
                                : <>
                                    {/* If image processed then show the form component  */}
                                    {imageTransactionsData
                                        ? <>
                                            {bankName === 'image' &&
                                                <TransactionsForm transactions={imageTransactionsData} statement={statementInfo} setImageProcessed={setIsImageProcessed} setWarningModal={setOpenWarningModal} setSelectedTransactions={setSelectedTransactions} />
                                            }
                                            {bankName === 'pao' &&
                                                <PaoTransactionsForm transactions={imageTransactionsData} statement={statementInfo} setImageProcessed={setIsImageProcessed} setWarningModal={setOpenWarningModal} setSelectedTransactions={setSelectedTransactions} />
                                            }
                                            {bankName === 'scb' &&
                                                <ScbTransactionsForm transactions={imageTransactionsData} statement={statementInfo} setImageProcessed={setIsImageProcessed} setWarningModal={setOpenWarningModal} setSelectedTransactions={setSelectedTransactions} />
                                            }
                                        </>
                                        : <>
                                            <div className="text-center py-3">
                                                <h4>{errorMessage?.message || errorMessage?.reason || t("common:something_went_wrong")}</h4>
                                                <h6>{errorMessage?.details || t("common:no_data_found")}</h6>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                        {/* Warning Modal To Review Changes */}
                        <Modal isOpen={openWarningModal} toggle={() => setOpenWarningModal(false)} backdrop="static" keyboard={false} centered>
                            <ModalHeader toggle={() => setOpenWarningModal(false)}>{t("common:are_you_sure")}</ModalHeader>
                            <ModalBody>
                                {t("common:ai_message")}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={() => { setOpenWarningModal(false); }}>
                                    {t("common:cancel")}
                                </Button>{' '}
                                <Button color="primary" disabled={isTransactionReviewed} onClick={() => { saveTransactionsData(); }}>
                                    {t("common:confirm")}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </>
                )}
        </div>

    );
};

export default UploadedBankStatements;

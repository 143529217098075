import React, { useEffect, useState } from 'react';
import { IApiCallStatus } from '../../interfaces';
import { getAllCategory, getAllCategoryType } from '../../services';
import { DEFAULT_PAGE_SIZE, groupArray, showNotification } from '../../utils';
import { useNavigate } from 'react-router-dom';
// import CustomHeader from '../../components/CustomHeader';
import ScrollToTop from 'react-scroll-to-top';
import useAppbar from '../../hooks/useAppbar';
import usePagination from "../../hooks/usePagination";
import { useTranslation } from 'react-i18next';
const Category = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [accounts, page, onUpdateData] = usePagination({
        pageSize: DEFAULT_PAGE_SIZE
    })

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const fetchData = async (
        pageNumber = page.current,
        pageSize = page.pageSize,
        search = ''
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                pageNumber,
                pageSize,
                filter: search
                // type: type,
            };
            const usersResponse = await getAllCategory(data);
            if (usersResponse.status === 200) {
                const groupArrayResults = await groupArray(usersResponse.data.items, 'typeLabel', 'categories');
                onUpdateData({
                    ...usersResponse.data,
                    items: groupArrayResults,
                    current: pageNumber
                })
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const getCategoryType = async () => {
        try {
            changeApiStatus(true, '');
            // showNotification('info', 'Fetching...');
            const getAllCategoryTypeRes = await getAllCategoryType();
            changeApiStatus(false, '');
            if (getAllCategoryTypeRes.status === 200) {
                const items = getAllCategoryTypeRes.data || [];
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    tempList.push({
                        label: item.label,
                        value: item.value
                    });
                    return item;
                });
                // setCategoryType(tempList);
            } else {
                throw new Error(getAllCategoryTypeRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const gotoDetailsPage = (id: string) => {
        navigate('/category/' + id);
    };

    useEffect(() => {
        (async () => {
            await getCategoryType();
            await fetchData();
        })();
        updateAppbar({
            leftLink: '/transaction',
            leftIcon: 'Menu',
            title: t("common:chart_of_accounts"),
            showAddIcon: true,
            addLink: '/category/new'
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className=' category-list'>
            {apiStatus.inProgress
? (
    t("common:load")
            )
: (
                <>
                    <div className='page-body'>
                        <ScrollToTop smooth className="ScrollToTop" component={<i className="bi bi-arrow-up-circle"></i>} />
                        {accounts.length
? accounts.map((account: any, idx1: number) => {
                            return <div key={idx1}>

                                <h3>{account.heading}</h3>
                                {account.categories.length
? account.categories.map((item: any, idx: number) => {
                                    return (
                                        <div className='row' key={idx}>
                                            <div
                                                className=' col-md-12 mb-3'
                                                onClick={() =>
                                                    gotoDetailsPage(item._id)
                                                }
                                            >
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='d-flex justify-content-between'>
                                                            <p className='m-0'>
                                                                {item.category}
                                                            </p>
                                                            <p className='m-0'>
                                                                {item.parentCategory}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
: <div className='text-center h5'>{t("common:no_data")}</div>}
                            </div>;
                        })
: <div className='text-center h5'>{t("common:no_data")}</div>}

                    </div>
                </>
            )}
        </div>
    );
};

export default Category;

import { UserDataKey } from '.';

export const IsLoggedIn = () => {
    return ReadLocalStorage(UserDataKey) !== undefined;
};

export const ValueFromUserData = (key: string) => {
    let UserData: any = ReadLocalStorage(UserDataKey);
    if (UserData !== undefined && UserData !== null) {
        UserData = JSON.parse(UserData);
        return UserData[key];
    } else {
        return '';
    }
};

export const SetLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
};

export const ReadLocalStorage = (key: string) => {
    const result = localStorage.getItem(key);
    if (result === undefined || result === null) {
        return undefined;
    } else {
        return result;
    }
};

export const RemoveFromLocalStorage = (key: string) => [
    localStorage.removeItem(key)
];

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { allowedRoleNames } from '../constants';
import useAuth from '../hooks/useAuth';
// import { LANDING_VIDEO_URL, isLoggedIn } from '../utils';

const Landing = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    // const [videoStarted, setVideoStarted] = useState<boolean>(false);
    // const [isMute, setIsMute] = useState<boolean>(true);

    // const gotoLoginPage = () => {
    //     navigate('/login');
    // };

    // const checkIsloggedIn = useCallback(() => {
    //     if (auth.user) {
    //         if (allowedRoleNames.includes(auth.user.roleName)) {
    //             navigate('/transaction');
    //         }
    //     }
    // }, [auth.user, navigate]);

    // const autoPlay = () => {
    //     if (document && !videoStarted) {
    //         const video = document.querySelector('video');
    //         // console.log(video);
    //         if (video) {
    //             const playPromise = video.play();
    //             playPromise.then(() => {
    //                 console.log('Video Started');
    //                 setVideoStarted(true);
    //             }).catch((error) => {
    //                 console.log(error);
    //                 setIsMute(false);
    //             });
    //         }
    //     }
    // };

    // const clickedByUser = () => {
    //     setIsMute(false);
    //     autoPlay();
    // };

    useEffect(() => {
        if (auth.user) {
            if (allowedRoleNames.includes(auth.user.roleName)) {
                navigate('/transaction');
            }
        } else {
            navigate('/login');
        }
    }, [auth.user, navigate]);

    // useEffect(() => {
    //     checkIsloggedIn();
    //     autoPlay();
    //     setTimeout(() => {
    //         autoPlay();
    //     }, 500);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [checkIsloggedIn]);

    return null;
    //     <>
    //         <video
    //             id='video1'
    //             style={{
    //                 position: 'absolute',
    //                 bottom: 0,
    //                 left: 0,
    //                 right: 0,
    //                 top: 0,
    //                 width: '100%',
    //                 height: '100%'
    //             }}
    //             autoPlay
    //             muted={isMute}
    //             // poster='./images/poster.png'
    //             onClick={clickedByUser}
    //             onEnded={gotoLoginPage}>
    //             <source src={LANDING_VIDEO_URL} type="video/mp4" />
    //             Your browser does not support the video tag.
    //         </video>
    //     </>
    // );
};

export default Landing;

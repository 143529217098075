import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { financialMonthList } from "../../constants";
// import InplaceConfirm from '../../components/InplaceConfirm';
// import InputCtrl from '../../controllers/InputCtrl';
import SelectCtrl from '../../controllers/SelectCtrl';
import useAppbar from '../../hooks/useAppbar';
import { IApiCallStatus } from '../../interfaces';
import { getUserDetailsUsingToken, updateUserDetails } from '../../services';
import { dismissNotification, showNotification, updateLocalUserData } from '../../utils';
import { useTranslation } from 'react-i18next';

const defaultValues = {
    financialYearEndDay: '',
    financialYearEndMonth: ''
};

interface TFinancialYear {
    financialYearEndDay: string,
    financialYearEndMonth: string;
}

const Settings = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        watch
    } = useForm<TFinancialYear>({
        mode: 'onBlur',
        defaultValues
    });
    const [userDetails, setUserDetails] = useState();
    const [financialDayList, setFinancialDayList] = useState<{label: string, value: string}[]>([])
    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const showError = (_fieldName: string) => {
        const error = (errors as any)[_fieldName];
        return error
            ? (
                <div className='error-block text-danger mt-2'>
                    {error.message || t("common:field_require")}
                </div>
            )
            : null;
    };

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };
    const getUserDetails = async () => {
        const notificationUid = 'GetCategoryDetails';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:load"), notificationUid);
            const getUserDetailsRes = await getUserDetailsUsingToken();
            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getUserDetailsRes.status === 200) {
                setUserDetails(getUserDetailsRes.data);
                reset({
                    ...getUserDetailsRes.data
                });
            } else {
                throw new Error(getUserDetailsRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const onSubmit = async (data: TFinancialYear) => {
        const notificationUid = 'SaveCategoryDetails';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const tempData = {
                financialYearEndDay: data.financialYearEndDay,
                financialYearEndMonth: data.financialYearEndMonth
            }
            const saveCategoryRes = await updateUserDetails(tempData);
            dismissNotification(notificationUid);
            if (saveCategoryRes.status === 200) {
                showNotification('success', saveCategoryRes.message);
                updateLocalUserData(tempData)
                navigate('/transaction');
            } else {
                throw new Error(saveCategoryRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const watchMonth = watch("financialYearEndMonth");

    useEffect(() => {
        if (watchMonth) {
            const month = financialMonthList.find((item) => item.value === watchMonth);
            if (month && month.lastDay) {
                const tempDays: Array<any> = [];
                [...Array(month.lastDay).keys()].map((i: number) => {
                    const count = i + 1;
                    tempDays.push({
                        label: `${count < 10 ? "0" + count : count}`,
                        value: `${count < 10 ? "0" + count : count}`
                    });
                    return i;
                });
                setFinancialDayList(tempDays)
            }
        }
    }, [watchMonth]);

    useEffect(() => {
        (async () => {
            await getUserDetails();
        })();
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: t("common:settings"),
            showAddIcon: false,
            addLink: ''
        });
        console.log(userDetails);
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className='login-page'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 col-sm-12 offset-md-4 p-0 text-center'>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className='mt-4'
                        >
                            <div>
                                <h6> {t("common:financial_year_settings")} </h6>
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:month")}
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:select_month")}
                                    required
                                    control={control}
                                    name='financialYearEndMonth'
                                    disabled={false}
                                    selectClassNamePrefix='search-select'
                                    options={financialMonthList}
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                    {t("common:day")}
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:select_day")}
                                    required
                                    control={control}
                                    name='financialYearEndDay'
                                    disabled={false}
                                    selectClassNamePrefix='search-select'
                                    options={financialDayList}
                                />
                            </div>
                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className='btn btn-secondary login-btn'
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:save")}
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-secondary login-btn cancel-btn mt-3'
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:cancel")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;

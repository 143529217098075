import { getDataWithQuery, deleteData } from '../api';

import { API_END_POINT } from '../constants';

export const getAllStatement = (data: any) => {
    return getDataWithQuery(API_END_POINT.statement.getAll, data);
};

export const deleteStatement = (id: string) => {
    return deleteData(API_END_POINT.statement.delete, id);
};

import { postData, getData, getDataWithQuery, deleteData } from '../api';

import { API_END_POINT } from '../constants';

export const saveCategory = (data: any) => {
    return postData(API_END_POINT.category.save, data);
};

export const getCategoryById = (id: string) => {
    return getData(API_END_POINT.category.getById, id);
};

export const getAllCategory = (data: any) => {
    return getDataWithQuery(API_END_POINT.category.getAll, data);
};

export const deleteCategory = (id: string) => {
    return deleteData(API_END_POINT.category.delete, id);
};

export const getAllCategoryType = () => {
    return getDataWithQuery(API_END_POINT.category.type, {});
};

export const getAllParentCategory = (parent?: string) => {
    return getDataWithQuery(API_END_POINT.category.parent, { parent });
};

export const getAllBank = () => {
    return getDataWithQuery(API_END_POINT.category.banks, {});
};

import React from 'react';
import { Controller } from 'react-hook-form';
import { InputCtrlProps } from '../interfaces';
import { useTranslation } from 'react-i18next';
const InputCtrl = ({
    control,
    name,
    placeholder,
    rootClass,
    showError,
    type,
    required,
    disabled,
    className,
    id,
    defaultValue,
    readOnly = false,
    maxDate,
    minDate,
    onFocus,
    handleChange
}: InputCtrlProps) => {
    const { t } = useTranslation();
    const inputProps: any = {}
    if(type === "date") {
        if(maxDate) {
            inputProps.max = new Date(maxDate).toISOString().split("T")[0]
        }
        if(minDate) {
            inputProps.min = new Date(minDate).toISOString().split("T")[0]
        }
    }
    const rules: any = {
        // valueAsNumber: true,
        required,
        pattern: {
            value: /[A-Za-z0-9]{1,20}/,
            message: t("common:invalid")
        },
        maxLength: { value: 250, message: t("common:max_char") },
        minLength: { value: 0, message: '' }
    };
    if (type === 'email') {
        // MinLength
        rules.minLength.value = 4;
        rules.minLength.message = t("common:min_char");
        delete rules.maxLength;
        // Pattern Check
        rules.pattern.value = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        rules.pattern.message = t("common:invalid_email");
    }

    return (
        <div className={rootClass || 'input-ctrl'}>
            <section>
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field: { value, onChange, ...all } }) => (
                        <input
                            {...all}
                            {...inputProps}
                            onChange={(e) => {
                                // console.log(e);
                                const name = e.target.name;
                                const val = e.target.value;
                                // console.log(name, val);
                                if(handleChange) {
                                    handleChange(name, val, e, onChange)
                                } else {
                                    onChange(e);
                                }
                            }}
                            value={value}
                            readOnly={readOnly}
                            defaultValue={defaultValue}
                            name={name}
                            id={id}
                            type={type}
                            className={className}
                            placeholder={placeholder}
                            disabled={disabled}
                            onFocus={() => {
                                if(onFocus) {
                                    onFocus({
                                        name,
                                        value,
                                        id
                                    })
                                }
                            }}
                            // max={new Date().toISOString()}
                            // required={required}
                        />
                    )}
                />
                {showError(name)}
            </section>
        </div>
    );
};

export default InputCtrl;

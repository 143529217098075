import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { menuList } from "../constants";
// import { menuList } from '../constants';
// import useAppbar from '../hooks/useAppbar';
import useAuth from '../hooks/useAuth';
import { IAppHeader, IMenuItem } from '../interfaces';
import { $updateAppHeader, clearCookies, valueFromUserData } from '../utils';
import meta from '../utils/metadata.json';
import { t } from 'i18next';
// import Sidebar from "./Sidebar";

const Appbar = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const [logo, setLogo] = useState<string>('/images/logo.png');
    const [appHeader, setAppHeader] = useState<IAppHeader>({
        leftIcon: 'Menu',
        leftLink: '',
        title: '',
        showAddIcon: true,
        addLink: ''
    });

    const menuList: IMenuItem[] = [
        {
            label: t('common:menu_transactions'),
            link: '/transactions'
        },
        {
            label: t('common:chart_of_accounts'),
            link: '/category'
        },
        {
            label: t('common:invoices'),
            link: '/invoices'
        },
        {
            label: t('common:uploaded_bank_statements'),
            link: '/uploaded-bank-statements'
        },
        {
            label: t('common:bank_transactions'),
            link: '/bank-transactions'
        },
        {
            label: t('common:manual_journals'),
            link: '/manual-journals'
        },
        {
            label: t('common:reconciliation'),
            link: '/reconciliation'
        },
        {
            label: t('common:financial_reports'),
            link: '/financial-reports'
        },
        {
            label: t('common:company_documents'),
            link: '/company-docs'
        },
        {
            label: t('common:contacts'),
            link: '/contacts'
        },
        {
            label: t('common:settings'),
            link: '/settings'
        }
    ];

    const [metaData, setMetaData] = useState<any>({});
    const logout = () => {
        clearCookies();
        auth.signout(() => {
            navigate('/login');
        });
    };

    const goto = (path: string) => {
        // navigate(path, {
        //     replace: true
        // });
        navigate(path);
    };

    useEffect(() => {
        const tempLogo = valueFromUserData('logoFullUrl') || '/images/logo.png';
        setLogo(tempLogo);
        setMetaData(meta);
        $updateAppHeader.on('updateAppHeader', (val: IAppHeader) => {
            // console.log(new Date(), val);
            setAppHeader(val);
        });
        return () => {
            $updateAppHeader.off('updateAppHeader');
        };
    }, []);

    const goBack = () => {
        navigate(-1);
    };

    //
    //   const gotoDetailsPage = (id: string) => {
    //     navigate('/category/' + id);
    // };
    return (
        <>
            <nav>
                <div className="container-fluid print-hide">
                    <div className="d-flex align-items-center justify-content-between">
                        {appHeader.leftIcon === 'Back' && <button onClick={goBack} className="btn p-0" type="button">
                            <i className="bi bi-chevron-left"></i>
                        </button>}
                        {appHeader.leftIcon === 'Menu' && <button className="btn p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <i className="bi bi-list"></i>
                        </button>}
                        <label className='text-blue h6 mt-2'>
                            {appHeader.title}
                        </label>
                        {appHeader.rightComponent
? appHeader.rightComponent
: <button type='button' className='btn p-0 1'>
      {appHeader.showAddIcon && <i onClick={() => goto(appHeader.addLink)} className="bi bi-plus-lg"></i>}
  </button>}
                        <div className="offcanvas offcanvas-start" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                            <div className="offcanvas-header justify-content-end">
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body position-relative">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                       {menuList.map((item: IMenuItem, idx) => <div key={idx} onClick={() => goto(item.link)}>{item.label}</div>)}
                                        <div onClick={logout}>
                                            {t("common:logout")}
                                        </div>
                                    </li>
                                </ul>
                                <div className='version-box text-center position-absolute'>
                                    <div className='m-auto'>
                                        <strong>{t("common:version")}: {metaData.Version}</strong>
                                    </div>
                                </div>
                                <div className="sidebar-img-main position-absolute">
                                    <div className="sidebar-logo m-auto">
                                        <img src={logo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Appbar;

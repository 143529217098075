import React, { useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import '../styles/Layout.scss';
import Appbar from './Appbar';
// import Footer from './Footer';
// import Sidebar from './Sidebar';
import useAuth from "../hooks/useAuth";
import { allowedRoleNames } from '../constants';
import { clearCookies, getLocalUserData } from '../utils';

const PrivateLayout = () => {
    const auth = useAuth();
    const location = useLocation();
    useEffect(() => {
        if(auth.user) {
            if(!allowedRoleNames.includes(auth.user.roleName)) {
                clearCookies();
            }
            const cookie = getLocalUserData();
            document.title = cookie.companyName ? cookie.companyName : 'AIFA Client Portal';
        }
    }, [auth])
	return (
        (auth.user && auth.user.verifyStatus === true && auth.user.verify2FA === true) ||
        auth.user.verifyStatus === false || auth.user.verifyStatus === null ||
        auth.user.verifyStatus === undefined || auth.user.verify2FA === undefined
        ? (
            <>
                <Appbar />
                <main className='container mt-4'>
                    <Outlet />
                </main>
            </>
        )
        : auth.user.verifyStatus === true && auth.user.verify2FA === false
        ? (
            <Navigate to="/send" state={{ from: location }} replace />
        )
        : (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    );
};

export default PrivateLayout;

import React, { useEffect, useMemo, useState } from 'react';
import { IApiCallStatus } from '../../interfaces';
import { getAllContact } from '../../services';
import { DEFAULT_PAGE_SIZE, showNotification, summarizeText } from '../../utils';
import { useNavigate } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import useAppbar from '../../hooks/useAppbar';
import Table from "../../components/Table";
import CustomTooltip from "../../components/CustomTooltip";
import usePagination from "../../hooks/usePagination";
import { useTranslation } from 'react-i18next';

const Contacts = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [contacts, page, onUpdateData] = usePagination({
        pageSize: DEFAULT_PAGE_SIZE
    })

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const fetchData = async (
        pageNumber = page.current,
        pageSize = page.pageSize,
        search = ''
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                pageNumber,
                pageSize,
                filter: search
            };
            const usersResponse = await getAllContact(data);
            if (usersResponse.status === 200) {
                onUpdateData(usersResponse.data)
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const gotoDetailsPage = (id: string) => {
        navigate('/contacts/' + id);
    };

    const columns = useMemo(
        () => [
            {
                Header: t("common:s_no"),
                accessor: (originalRow: any, index: any, c: any, d: any) => {
                    return <>{index + 1}</>;
                }
            },
            {
                Header: t("common:contact_name"),
                accessor: 'contactName'
            },
            {
                Header: t("common:billing_address"),
                accessor: (originalRow: any, index: any, c: any, d: any) => {
                    const val = originalRow.billingFullAddress || '';
                    const label = summarizeText(val, 20);
                    return <CustomTooltip label={label}>{val || ''}</CustomTooltip>
                }
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: t("common:contacts"),
            showAddIcon: true,
            addLink: '/contacts/new'
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className='contact-list'>
            {apiStatus.inProgress
                ? t("common:load")
                : (
                    <>
                        <div className='page-body'>
                            <ScrollToTop smooth className="ScrollToTop" component={<i className="bi bi-arrow-up-circle"></i>} />
                            <Table columns={columns} data={contacts} onRow={gotoDetailsPage} />
                        </div>
                    </>
                )}
        </div>
    );
};

export default Contacts;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import InplaceConfirm from '../../components/InplaceConfirm';
import InputCtrl from '../../controllers/InputCtrl';
import SelectCtrl from '../../controllers/SelectCtrl';
import useAppbar from '../../hooks/useAppbar';
import { IApiCallStatus } from '../../interfaces';
import { saveContact, getContactById, deleteContact, getAllBank } from '../../services';
import { dismissNotification, showNotification } from '../../utils';
import { useTranslation } from 'react-i18next';

const defaultValues = {
    contactName: "",
    firstName: "",
    lastName: "",
    email: "",

    phoneNumber: "",
    websiteUrl: "",
    registrationNumber: "",
    bank: "",
    accountNumber: "",

    billingAddressLine1: "",
    billingAddressLine2: "",
    billingCity: "",
    billingState: "",
    billingCountry: "",
    billingZipCode: "",

    deliveryAddressLine1: "",
    deliveryAddressLine2: "",
    deliveryCity: "",
    deliveryState: "",
    deliveryCountry: "",
    deliveryZipCode: ""

};
const ContactDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const params = useParams();
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        mode: 'onBlur',
        defaultValues
    });
    const [contactDetails, setContactDetails] = useState<any>();
    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });
    const [banks, setBanks] = useState<Array<{ label: string, value: string; }>>([]);

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const onDelete = async () => {
        const notificationUid = 'DeleteContact';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            console.log('contactDetails', contactDetails);
            const deleteContactRes = await deleteContact(params?.id || '');
            dismissNotification(notificationUid);
            if (deleteContactRes.status === 200) {
                showNotification('success', deleteContactRes.message);
                navigate('/contacts');
            } else {
                throw new Error(deleteContactRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const onSubmit = async (data: any) => {
        const notificationUid = 'SaveContactDetails';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const saveContactRes = await saveContact({
                id: data._id || undefined,
                ...data
            });
            dismissNotification(notificationUid);
            if (saveContactRes.status === 200) {
                showNotification('success', saveContactRes.message);
                navigate('/contacts');
            } else {
                throw new Error(saveContactRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const getContactDetails = async (id: any) => {
        const notificationUid = 'GetContactDetails';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getContactByIdRes = await getContactById(id);

            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getContactByIdRes.status === 200) {
                setContactDetails(getContactByIdRes.data);
                reset({
                    ...getContactByIdRes.data
                });
            } else {
                throw new Error(getContactByIdRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const showError = (_fieldName: string) => {
        const error = (errors as any)[_fieldName];
        return error
            ? (
                <div className='error-block text-danger mt-2'>
                    {error.message || t("common:field_require")}
                </div>
            )
            : null;
    };

    const getBanks = async () => {
        const notificationUid = 'GetBank';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getAllBanksRes = await getAllBank();

            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getAllBanksRes.status === 200) {
                const items = getAllBanksRes.data || [];
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    tempList.push({
                        label: item.label,
                        value: item.value
                    });
                    return item;
                });
                tempList.push({
                    label: t("common:none"),
                    value: ''
                });
                setBanks(tempList);
            } else {
                throw new Error(getAllBanksRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    useEffect(() => {
        (async () => {
            // await getContactType();
            // await getParentContact();
            await getBanks();
        })();
        if (params.id && params.id !== 'new') {
            getContactDetails(params.id);
        }
        updateAppbar({
            leftLink: '/contact',
            leftIcon: 'Back',
            title: `${params.id !== 'new' ? t("common:edit") : t("common:new")} ${t("common:contact")}`,
            showAddIcon: false,
            addLink: '',
            rightComponent: params.id !== 'new'
                ? <InplaceConfirm
                    HTMLComponent={<i className="bi bi-trash text-end" style={{ fontSize: '1.5rem', color: 'red' }}></i>}
                    action={() => {
                        console.log('onAction');
                        onDelete();
                    }}
                    target="del-icon"
                    text={t("common:are_you_sure")}
                />
                : null
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='login-page'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 col-sm-12 offset-md-4 p-0 text-center'>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className='mt-4 mb-4'
                        >
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                    {t("common:contact_name")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:enter_contact_name")}
                                    required
                                    control={control}
                                    name='contactName'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className="col-12 d-block">
                                <h5>{t("common:primary_person")}</h5>
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:first_name")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:enter_first_name")}
                                    required={false}
                                    control={control}
                                    name='firstName'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:last_name")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:enter_last_name")}
                                    required={false}
                                    control={control}
                                    name='lastName'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:email")}
                                </label>
                                <InputCtrl
                                    type='email'
                                    showError={showError}
                                    placeholder={t("common:enter_email")}
                                    required={false}
                                    control={control}
                                    name='email'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className="col-12 d-block">
                                <h5>{t("common:business_info")}</h5>
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:phone_no")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:enter_phone_no")}
                                    required={false}
                                    control={control}
                                    name='phoneNumber'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:website_url")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:enter_url")}
                                    required={false}
                                    control={control}
                                    name='websiteUrl'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:registration_no")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:enter_reg_no")}
                                    required={false}
                                    control={control}
                                    name='registrationNumber'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:bank")}
                                </label>
                                <SelectCtrl
                                    showError={showError}
                                    placeholder={t("common:select_bank")}
                                    required={false}
                                    control={control}
                                    name='bank'
                                    disabled={false}
                                    selectClassNamePrefix='search-select'
                                    options={banks}
                                />
                            </div>
                            <div className='col-12 input-group d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:account_no")}
                                </label>
                                <InputCtrl
                                    type='text'
                                    showError={showError}
                                    placeholder={t("common:enter_account_no")}
                                    required={false}
                                    control={control}
                                    name='accountNumber'
                                    disabled={false}
                                    className='form-control'
                                />
                            </div>
                            <div className='col-12 d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:billing_address")}
                                </label>
                                <div className="row">
                                    <div className='col-12 input-group d-block'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:address_line")}
                                            required
                                            control={control}
                                            name='billingAddressLine1'
                                            disabled={false}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-12 input-group d-block'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:address_line_two")}
                                            required={false}
                                            control={control}
                                            name='billingAddressLine2'
                                            disabled={false}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:select_city")}
                                            required={true}
                                            control={control}
                                            name='billingCity'
                                            disabled={false}
                                        // selectClassNamePrefix='search-select'
                                        // options={[]}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:select_state")}
                                            required={true}
                                            control={control}
                                            name='billingState'
                                            disabled={false}
                                        // selectClassNamePrefix='search-select'
                                        // options={[]}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:select_country")}
                                            required={true}
                                            control={control}
                                            name='billingCountry'
                                            disabled={false}
                                        // selectClassNamePrefix='search-select'
                                        // options={[]}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:enter_zip_code")}
                                            required
                                            control={control}
                                            name='billingZipCode'
                                            disabled={false}
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 d-block'>
                                <label className='form-label text-start d-block'>
                                {t("common:delivery_address")}
                                </label>
                                <div className="row">
                                    <div className='col-12 input-group d-block'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:address_line")}
                                            required
                                            control={control}
                                            name='deliveryAddressLine1'
                                            disabled={false}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-12 input-group d-block'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:address_line_two")}
                                            required={false}
                                            control={control}
                                            name='deliveryAddressLine2'
                                            disabled={false}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:select_city")}
                                            required={true}
                                            control={control}
                                            name='deliveryCity'
                                            disabled={false}
                                        // selectClassNamePrefix='search-select'
                                        // options={[]}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:select_state")}
                                            required={true}
                                            control={control}
                                            name='deliveryState'
                                            disabled={false}
                                        // selectClassNamePrefix='search-select'
                                        // options={[]}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:select_country")}
                                            required={true}
                                            control={control}
                                            name='deliveryCountry'
                                            disabled={false}
                                        // selectClassNamePrefix='search-select'
                                        // options={[]}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputCtrl
                                            type='text'
                                            showError={showError}
                                            placeholder={t("common:enter_zip_code")}
                                            required
                                            control={control}
                                            name='deliveryZipCode'
                                            disabled={false}
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <button
                                    type='submit'
                                    className='btn btn-secondary login-btn'
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:save")}
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-secondary login-btn cancel-btn mt-3 mb-5'
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:cancel")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactDetails;

import React from 'react';
import { Controller } from 'react-hook-form';
import { TextareaCtrlProps } from '../interfaces';

const TextareaCtrl = ({
    control,
    name,
    placeholder,
    rootClass,
    showError,
    required,
    disabled,
    className,
    id,
    rows,
    cols
}: TextareaCtrlProps) => {
    const rules: any = {
        // valueAsNumber: true,
        required
        // pattern: {
        //     value: /[A-Za-z0-9]{1,20}/,
        //     message: 'Field is invalid',
        // },
        // maxLength: { value: 250, message: 'Should be max 250 char long' },
        // minLength: { value: 0, message: '' },
    };
    return (
        <div className={rootClass || 'input-ctrl'}>
            <section>
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field }) => (
                        <textarea
                            {...field}
                            rows={rows}
                            cols={cols}
                            name={name}
                            id={id}
                            className={className}
                            placeholder={placeholder}
                            disabled={disabled}
                            // required={required}
                        />
                    )}
                />
                {showError(name)}
            </section>
        </div>
    );
};

export default TextareaCtrl;

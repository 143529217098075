// Here we have added our translations

export default {

  // Globals
  dev_env: '開發環境', // Development Environment ---- Google Translate: 'Development environment'  ***/ English Translation is: "Development" \**
  version: '版本',
  logout: '登出',
  search: '搜尋',
  all: '全部',
  coming_soon: '即將推出...',
  and: ' 和 ',
  year: '年',
  ai_analysing: '人工智慧分析',
  success: '成功',
  company_documents: '公司文件庫',

  // fileName
  file_names: '文件名',
  file_url: '文件地址',
  uploadfile: '上传文件',
  download_pdf: '下載PDF',
  upload_new_docs: '上傳新文件',

  // Messages
  messages: {
    'Not Found': '未找到'
  },

  // Constants
  menu_transactions: '損益交易',
  // chart_of_accounts: '會計科目表',
  invoices: '發票',
  uploaded_bank_statements: '上傳的銀行對帳單',
  reconciliation: '對賬', // Reconciliation --- Google Translate: 'Reconcile'

  // Form Fields
  field_require: '欄位為必填項',
  empty: '欄位不能為空',
  select_option: '請選擇一個選項!',
  char_length: '必須為四個字符長', // Character Length
  select: '選擇',
  no_clients: '沒有可用的客戶',
  max_length: '請輸入最大長度 30 的值', // Maximum Length
  min_length: '請輸入最小長度 3 的值', // Minimum Length
  not_found: '未找到',
  invalid: '欄位無效',
  max_char: '最長應為 250 個字符',
  min_char: '長度應至少為 4 個字符',
  invalid_email: '電子郵件地址無效', // Email address is invalid
  are_you_sure: '你確定嗎',
  yes: '是的',
  no: '不',
  confirm: '確認',
  cancel: '取消',
  submit: '提交',
  save: '保存', // Save ---- Google Translate: 'Keep'

  // Table Data
  fetch: '正在獲取...',
  load: '載入中...',
  fetching: '正在獲取...',
  no_data: '沒有數據',

  // Date Range Selection Constants
  from_date: '從日期',
  to_date: '迄今',
  select_from: '選擇自',
  select_to: '選擇到',
  max_date: '最大日期',
  close: '關閉',
  apply: '應用', // Apply --- Google Translate: 'application'

  // Notifications
  file_upload: '開始文件上傳',
  start_uploads: '開始上傳您的檔案...',
  upload_logo: '請上傳徽標',
  upload_process: '已上傳並處理',
  files: '文件。',
  pdf_file: '請選擇 pdf 或圖像文件',
  request_process: '處理您的請求',
  uploading: '上傳中',

  // Login
  welcome: '歡迎',
  client_portal: 'AIFA 用戶端',
  email: '電子郵件',
  email_address: '電子郵件地址', // Email Address
  password: '密碼',
  button: '記住我', // Remember me
  login: '登錄',
  unauthorized_access: '未授權訪問', // Unauthorized access --- Google Translate: 'Unauthorized visit'

  // Uploaded Bank Statements
  not_found_coa: 'COA 中未找到',
  download_file: '下載文件',
  downloaded: '已下載',
  transactions: '交易',
  report: '生成報告',
  date_range: '日期範圍',
  date_range_selector: '日期範圍選擇器',
  expense_breakdown: '費用明細',
  category_name: '類別名稱',
  expense_count: '費用數量', // Expense Count, Google Translation: 'Cost'
  amount: '金額', // Amount
  tx_date: '交易日期', // Tx Date --- Google Translate: 'transaction date'
  tx_type: '交易類型',
  account: '帳戶',
  paid_from: '付款方式',
  date_paid: '付款日期',
  amount_paid: '支付金额',
  make_payment: '付款',
  tans_description: '描述',
  trans_details_heading: '交易',
  make_payment_heading: '付款',
  status: '狀態', // Status --- Google Translation: 'State'
  payment_completed_message: '这笔交易的付款已经完成',

  // Invoices
  chart_of_accounts: '會計科目表',
  invoice_no: '發票號碼。',
  invoice_contact: '發票聯絡方式',
  invoice_date: '發票日期',
  due_date: '到期日',
  total_amount: '總金額',
  action: '行動',
  // invoices: '發票',
  invoice: '發票',
  description: '描述',
  quantity: '數量',
  unit_price: '單價',
  subtotal: '小計',
  others: '其他',
  notes: '筆記',
  item_name: '項目名...',

  // Balance Sheet
  balance_sheet: '資產負債表',
  no_transaction_found: '未發現交易', // No Transactions Found --- Googole Translate: 'No transaction'

  // Uploaded Bank Statements
  multiple: '多種的',
  // uploaded_bank_statements: '上傳的銀行對帳單',
  text_message: '請使用 COA 新增銀行帳戶。我們支持',
  file_name: '檔案名稱',
  bank: '銀行',
  added_on: '添加於',
  open_coa: '開啟COA', // Open COA
  statement_message: '此報表包含儲蓄帳戶和往來帳戶的項目，請選擇您想要的項目類型',
  upload_message: '請從以下支援的文件類型中選擇要上傳的文件類型。',
  pdf_files: 'pdf文件',
  scanned_copy: '掃描件',
  upload: '上傳',
  ai_message: '這些是人工智慧產生的結果，可能會產生不正確的結果。請查看結果，確認後這些交易將會保存到我們的系統中。',
  no_accounts: '未找到銀行帳戶',
  no_file_type: '未選擇文件類型！',
  bank_name: '銀行名稱', // Bank Name
  multiple_accounts: '發現多個銀行帳戶',
  account_upload: '上傳的對帳單包含多個帳戶，請選擇與您要上傳的帳戶相符的帳戶',
  not_supported: '不支持', // Support not available --- Google Translate: 'not support'
  file_support: '不支援上傳文件。',
  something_went_wrong: '出了點問題',
  try_again: '出了點問題，請重試',
  no_data_found: '在此文件中找不到數據，請嘗試使用其他文件。',

  // Bank Transactions
  bank_transactions: '銀行交易',
  index: '指數',
  date: '日期',
  summary: '摘要', // Summary
  deposit: '存款', // Deposit --- Google Translate: 'deposit'
  withdrawl: '提款', // Withdrawal --- Google Translate: 'Withdraw'
  balance: '餘額', // Balance
  // bank: '銀行',
  reconcile: '對賬', // Reconcile
  actions: '行動',
  view: '查看', // View --- Google Translate: 'Check'
  amount_hkd: '金額（港幣）', // Amount (HKD)
  payment_method: '付款方式',
  transaction_date: '交易日期',
  description_: '描述...',
  withdrawl_: '提款 ..', // Withdrawal.. --- Google Translate: 'A Withdrawl'
  deposit_: '存款 ..', // Deposit --- Google Translate: 'deposit..'
  balance_: '餘額...', // Balance
  account_: '帳戶 ...',
  date_: '日期..',
  effective_date: '生效日期',

  // Reconcile
  reference: '參考',
  spent: '花費',
  recieved: '已收到', // Received --- Google Translate: 'recieved'
  reconcile_amount: '銀行交易和手動交易金額應匹配才能繼續對賬',
  match: '匹配',
  discuss: '討論',
  matching_transactions: '尋找並選擇下面的匹配交易',
  recieved_items: '顯示收到的項目', // Received Items
  show_hkd: '僅顯示港幣項目', // Show HKD Items only --- Google Translate: 'Only Hong Kong dollars project'
  search_by_name: '按名稱或參考搜尋',
  search_by_amount: '按金額搜尋',
  go: '去',
  transaction_not_found: '未找到損益交易',
  manage: '管理',
  select_all: '選擇該頁面上的所有內容',
  showing: '顯示中',
  of: '的',
  sum_of_transactions: '您選擇的交易金額必須與金額相符',
  must_match: '必須匹配金額', // Must match money
  undo_reconcile: '撤銷對賬', // Undo Reconcile --- Google Translate: 'Revoke'

  // Manual Journals
  manual_journals: '手工日誌', // Manual Journals --- Google Translate: 'Handmade log'
  narration: '描述', // Narration --- Google Translate: 'Describe'
  debit: '借方',
  credit: '貸方', // Credit --- Google Translate: 'Loan'
  default_narration: '默認描述', // Default narration to journal line description --- Google Translation: 'Default description'
  new_line: '新增行', // Add a new line --- Google Translate: 'New and new'
  supporting_docs: '支援文件',
  send_otp: '發送一次性密碼',
  otp_mobile: 'OTP 將會發送至您以以下結尾的手機號碼',
  get_otp: '取得您的一次性密碼',
  verify_otp: '驗證一次性密碼',
  otp_mobile_verify: '輸入手機號碼收到的 OTP 結尾',
  download_my_file: '下載文件',
  page: '頁',
  // Reconcilation
  reconcilation: '對賬', // Reconciliation --- Google Translate: 'Reconcile'
  reconciled: '已對賬', // Reconciled
  unreconciled: '未對賬', // Unreconciled --- Google Translation: 'Unsatisfactory reconciliation'

  // Financial Reports
  financial_reports: '財務報告',
  income_statement: '損益表', // Income Statement --- Google Translate: 'Loser'
  // balance_sheet: '資產負債表',
  end_date: '結束日期',
  start_date: '開始日期',
  all_reports: '所有報告',
  financial_statements: '財務報表',

  // Contacts
  contacts: '聯絡人', // Contacts --- Google Translate: 'contact person'
  s_no: '序號', // S. No. --- Google Translate: 'Serial number'
  contact_name: '聯絡人姓名',
  billing_address: '帳單地址',

  // New Contact Modal
  enter_contact_name: '輸入聯絡人姓名',
  primary_person: '主要人員', // Primary Person --- Google Translate: 'Main staff'
  first_name: '名',
  enter_first_name: '輸入名字',
  last_name: '姓',
  enter_last_name: '輸入姓氏',
  enter_email: '輸入電子郵件',
  business_info: '業務信息', // Business Information
  phone_no: '電話號碼',
  enter_phone_no: '輸入電話號碼',
  website_url: '網站URL', // Website URL
  enter_url: '輸入網址',
  registration_no: '註冊號碼',
  enter_reg_no: '輸入註冊號碼', // Enter Reg. Number --- Google Translate: 'Enter the registration number'
  select_bank: '選擇銀行',
  account_no: '帳號', // Account Number
  enter_account_no: '輸入帳號',
  billing_address_line: '帳單地址行1',
  address_line_two: '地址行2', // Address Line 2
  select_city: '選擇城市',
  select_state: '選擇省份', // Select State --- Google Translate: 'Province'
  select_country: '選擇國家',
  enter_zip_code: '輸入郵政編碼', // Enter Zip Code --- Google Translate: 'Enter the postal code'
  delivery_address: '送貨地址', // Delivery Address --- Google Translate: 'Shipping Address'
  address_line: '地址行1', // Address Line 1

  // Contact Details
  edit: '編輯',
  new: '新增', // Add New --- Google Translate: 'Increase'
  contact: '聯絡人', // Contact --- Google Translate: 'contact person'
  none: '無', // None

  // Transaction Details
  transaction: '交易',
  payment_type: '付款方式',
  assign_contact: '指定聯絡人',
  select_contact: '選擇聯絡人',
  upload_file: '上傳文件',
  camera: '相機',
  gallery: '相冊', // Gallery --- Google Translate: 'Album'

  // Category Details
  type: '類型',
  transaction_type: '交易類型',
  bank_type: '銀行類型',
  name: '名稱', // Name

  // Invoice Details
  company_name: '公司名稱',
  req_company_name: '公司名稱為必填項',
  req_invoice_date: '需要發票日期',
  req_due_date: '到期日為必填項', // Due date is required --- Google Translate: 'The expiration date is a required item'
  req_status: '狀態為必填項',
  req_contact: '聯絡人為必填項', // Contact is required --- Google Translate: 'Contact person as a must -fill item'
  allow_decimal: '僅允許十進制數字', // Only decimal numbers are allowed --- Google Translate: 'Only allowed decimal numbers'
  items_list: '項目清單不能為空', // Items list can not be empty --- Google Translate: 'The project list cannot be empty'
  items_description: '項目描述不能為空', // Items description can not be empty
  invalid_price: '發現記錄中存在無效的價格值', // Found invalid price values in record
  invalid_quantity: '發現記錄中存在無效的數量值', // Found invalid quantity values in record --- Google Translate: 'Discovery the invalid quantity value in the record'
  invalid_invoice: '發現無效的發票項目', // Found invalid invoice items --- Google Translate: 'Find invalid invoices'
  new_: '新的',
  draft: '草稿',
  approved: '已批准', // Approved
  sent: '已發送', // Sent --- Google Translate: 'Has been sent'
  void: '無效', // Void --- Google Translate: 'invalid'
  add_new_item: '新增項目', // Add new item --- Google Translate: 'New project'
  add_notes: '在此新增您的註釋...', // Add your notes here ... --- Google Translate: 'Add your comment here ...'
  add_new_contact: '新增聯絡人', // Add New Contact --- Google Translate: 'New contact person'
  invoice_id: '發票編號',
  qty: '數量',

  // Manual Journal Details
  debit_and_credit: '借方和貸方金額應匹配', // Debit and Credit amount should match --- Google Translate: 'The amount of the debit and the loan should be matched'
  _new: '新的',
  manual_journal: '手工日誌', // Manual Journal --- Google Translate: 'Handmade log'
  journal_description: '默認描述', // Default narration to journal line description --- Google Translate: 'Default description'
  // new_line: '新增行', // Add a new line --- 'New and new'
  total: '總計', // Total

  // Settings
  settings: '設置', // Settings --- Google Translate: 'set up'
  financial_year_settings: '財政年度設置',
  month: '月',
  select_month: '選擇月份', // Select Month --- Google Translate: 'Choose month'
  day: '日',
  select_day: '選擇日期', // Select Day --- Google Translate: 'Selection date'

  // Roles
  roles: {
    FAKEROLE: '假角色', // 0
    SUPERADMIN: '超級管理員', // 1
    LEGANCYADMIN: '舊版管理員', // 2
    CPAADMIN: '註冊會計師管理員', // 3, This is CPA Admin
    CPAAUDITOR: '註冊會計師審計師', // 4, This is CPA User
    CPACLERK: '會計師事務所職員', // 5 This is CPA User --- Google Translate: 'Accounting Firm Employees'
    CLIENTADMIN: '客戶管理員', // 6 This is CPA Client --- Google Translate: 'Client administrator'
    CLIENTTEAMMEMBER: '客戶團隊成員', // 7, old user
    BankAdmin: '銀行管理員', // 8
    BankUSER: '銀行用戶', // 9
    BANKCLIENTADMIN: '銀行客戶管理員' // 10 This is Bank Client
  }
}

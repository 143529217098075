import React from 'react';

const LabelAndValue = ({ label, value }: { label: string | number, value: string | number; }) => {
    return (
        <div>
            <label>{label}</label>
            <p><strong>{value}</strong></p>
        </div>
    );
};

export default LabelAndValue;

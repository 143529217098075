import React, { useRef, useState } from 'react';
import { downloadFile } from '../api';

interface DownloadFileProps {
	readonly preDownloading: (notificationId: string) => void;
	readonly postDownloading: (notificationId: string) => void;
	readonly onError: (notificationId: string, message: string) => void;
	// readonly getFileName: () => string;
}

interface DownloadedFileInfo {
	readonly download: (fileUrl: string, fileName: string) => Promise<void>;
	readonly downloadPdf: (fileUrl: string, fileName: string) => Promise<void>;
	readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
	readonly name: string | undefined;
	readonly url: string | undefined;
    readonly isDownloading: boolean
}

export const useDownloadFile = ({
	preDownloading,
	postDownloading,
	onError
}: // getFileName,
DownloadFileProps): DownloadedFileInfo => {
	const ref = useRef<HTMLAnchorElement | null>(null);
	const [url, setFileUrl] = useState<string>();
	const [name, setFileName] = useState<string>();
    const [inprogress, setInprogress] = useState<boolean>(false)

    const downloadPdf = async (fileUrl: string, fileName: string) => {
        const notificationId = 'download-pdf-file-' + new Date().toISOString();
        try {
            preDownloading(notificationId);
            setInprogress(true);
            const { data } = await downloadFile(fileUrl);
            setInprogress(false);
            const blob = new Blob([data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName || 'download.pdf';
            document.body.appendChild(link);
            link.click();
			postDownloading(notificationId);
            setTimeout(function () {
              document.body.removeChild(link);
          }, 100);
        } catch (error: any) {
            onError(
				notificationId,
				error.message || 'Something went wrong. Please try again!'
			);
        }
    }

	const download = async (fileUrl: string, fileName: string) => {
		const notificationId = 'download-file-' + new Date().toISOString();
		try {
			preDownloading(notificationId);
            setInprogress(true);
			const { data } = await downloadFile(fileUrl);
            setInprogress(false);
			const url = URL.createObjectURL(new Blob([data]));
			setFileUrl(url);
			setFileName(fileName);
			ref.current?.click();
			postDownloading(notificationId);
			URL.revokeObjectURL(url);
		} catch (error: any) {
			onError(
				notificationId,
				error.message || 'Something went wrong. Please try again!'
			);
		}
	};

	return { downloadPdf, download, ref, url, name, isDownloading: inprogress };
};

export {};

import axios from 'axios';
import { BASE_URL as API_BASE, valueFromUserData } from '../utils';
import { DeleteParams } from '../interfaces';
// Axios Interceptor //
// Request
axios.interceptors.request.use(
	function (config) {
		console.log('intercept-req');
		const token = valueFromUserData('token');
        if(config && config.headers) {
            config.headers['Content-Type'] = 'application/json';
            config.headers.Authorization = `${token}`;
        }
		return config;
	},
	function (err) {
		return Promise.reject(err);
	}
);

export const responseHandler = (response: any) => {
    return { data: response.data.data, message: response.data.message, error: response.data.error || response.data.message || "Something went wrong, please try again", status: response.data.status };
}

// GET REQUEST
export const getData = async (END_POINT: string, id?: any) => {
	let response: any;

	if (id) {
		response = await axios.get<any>(API_BASE + END_POINT, {
			params: { id }
		});
	} else {
		response = await axios.get<any>(API_BASE + END_POINT);
	}

	return responseHandler(response);
};

// POST REQUEST
export const postData = async (END_POINT: string, body: any, config?: any) => {
	// console.log(END_POINT, body);
	const response = await axios.post<any>(API_BASE + END_POINT, body, config);

	return responseHandler(response);
};

// POST REQUEST for file upload
export const fileUpload = async (END_POINT: string, body: any, config?: any) => {
	return await axios.post<any>(API_BASE + END_POINT, body, config);
};

// DELETE REQUEST
export const deleteData = async (END_POINT: string, id: string) => {
	const response = await axios.delete<any>(`${API_BASE}${END_POINT}`, {
		params: { id }
	});
	return responseHandler(response);
};

// DELETE by Param Request
export const deleteDatabyParam = async (END_POINT: string, params: DeleteParams) => {
	const response = await axios.delete<any>(`${API_BASE}${END_POINT}`, {
		params
	});
	return responseHandler(response);
};

export const deleteDataWithBody = async (END_POINT: string, body: any) => {
	console.log('body', body);

	const response = await axios.delete<any>(`${API_BASE}${END_POINT}`, {
		data: body
	});
	return responseHandler(response);
};

// get request with support of query
export const getDataWithQuery = async (END_POINT: string, queries?: any) => {
	let response: any;

	if (queries) {
		response = await axios.get<any>(API_BASE + END_POINT, {
			params: queries
		});
	} else {
		response = await axios.get<any>(API_BASE + END_POINT);
	}

	return responseHandler(response);
};

export const downloadFile = (fullUrl: string) => {
	return axios.get<any>(fullUrl, {
		responseType: 'blob'
	});
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardText, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import CustomSearchBox from '../../components/CustomSearchBox';
import Table from '../../components/Table';
import { IPagination, IApiCallStatus } from '../../interfaces';
import { getAllTransaction, getBankTransactionById, reconcileBankTransaction } from '../../services';
import { updateAppbar, resetAppbar, showNotification, dismissNotification, DEFAULT_PAGE_SIZE } from '../../utils';
import { useTranslation } from 'react-i18next';

let unCtrlAmountSearch = '';
let unCtrlQuerySearch = '';

const Reconcile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [seletedIds, setSeletedIds] = useState<Array<string>>([]);
    const [bankTransAmount, setBankTransAmount] = useState<{
        amount: number,
        amountValue: string,
        amountType: string,
    }>({ amount: 0.00, amountValue: '0.00', amountType: 'received' });
    const [totalAmount, setTotalAmount] = useState<number>(0.00);
    const [amountSearch, setAmountSearch] = useState<string>('');
    const [querySearch, setQuery] = useState<string>('');
    const [isAlreadyReconcile, setIsAlreadyReconcile] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState('1');
    const params = useParams();
    const [uploadedTransactions, setUploadedTransactions] = useState<any[]>([]);
    const [page, setPage] = useState<IPagination>({
        totalItems: 0,
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: 0,
        query: ''
    });

    const [transactionDetails, setTransactionDetails] = useState<{
        [x: string]: any;
    }>();

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const getTransactionDetails = useCallback(async (id: any, cb?: (data: any) => void) => {
        const notificationUid = 'GetTransactionDetails';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getTransactionByIdRes = await getBankTransactionById(id);
            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getTransactionByIdRes.status === 200) {
                const tempData = getTransactionByIdRes.data;
                const finalReconcileIds = tempData.finalReconcileIds || [] as Array<any>;
                const tempTotalAmount = tempData.totalReconcileAmount || 0.00;
                if (cb) {
                    cb(tempData);
                }
                // console.log('finalReconcileIds', finalReconcileIds);
                if (tempData.transactionDebitAmount) {
                    setBankTransAmount({
                        amount: tempData.transactionDebitAmount,
                        amountValue: '$' + tempData.transactionDebitAmount,
                        amountType: 'spent'
                    });
                }
                if (tempData.transactionCreditAmount) {
                    setBankTransAmount({
                        amount: tempData.transactionCreditAmount,
                        amountValue: '$' + tempData.transactionCreditAmount,
                        amountType: 'received'
                    });
                }
                if (finalReconcileIds.length) {
                    setIsAlreadyReconcile(true);
                }
                setTransactionDetails(tempData);
                setSeletedIds(finalReconcileIds);
                setTotalAmount(tempTotalAmount);
            } else {
                throw new Error(getTransactionByIdRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    }, []);

    const fetchData = useCallback(async (
        pageNumber = page.current,
        pageSize = page.pageSize,
        query = unCtrlQuerySearch ?? '',
        amount = amountSearch || unCtrlAmountSearch,
        bankTransactionId = params.id || '',
        cb?: (data: any) => void
    ) => {
        try {
            // console.log('searchAmount', amountSearch);
            changeApiStatus(true, '');
            const data = {
                query,
                pageNumber,
                pageSize,
                downloadPdf: false,
                amount: amount || undefined,
                isForReconcile: true,
                bankTransactionId
            };
            const transRes = await getAllTransaction(data);
            if (transRes.status === 200) {
                setPage({
                    ...page,
                    current: pageNumber,
                    totalItems: transRes.data.totalItems
                });
                const resData = transRes.data;

                if (cb) {
                    cb(transRes.data);
                }
                // upTrans = resData.items;
                setUploadedTransactions(resData.items);
                if (bankTransactionId) {
                    const tempIds: Array<string> = [];
                    resData.items.filter((item: any) => {
                        if (item.reconcileId && (item.reconcileId === bankTransactionId)) {
                            tempIds.push(item._id || '');
                            return item._id;
                        }
                        return false;
                    });
                    setSeletedIds(tempIds);
                    setTimeout(() => {
                        checkAll(true, tempIds);
                        calcAmount(tempIds, resData.items);
                    }, 400);
                }
                changeApiStatus(false, '');
            } else {
                throw new Error(transRes.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAll = (isChecked: boolean, ids?: Array<string>) => {
        if (document) {
            const checkBoxLists = document.querySelectorAll(".transaction-checkbox");
            if (checkBoxLists.length) {
                for (let index = 0; index < checkBoxLists.length; index++) {
                    const element: any = checkBoxLists[index];
                    if (ids) {
                        const id = element.id;
                        if (id && ids.includes(id)) {
                            element.checked = isChecked;
                        }
                    } else {
                        element.checked = isChecked;
                    }
                }
            }
        }
    };

    const goto = (path: any) => {
        navigate(path);
    };

    const onReconcile = async () => {
        // console.log(seletedIds);
        const notificationUid = 'ReconcileBankTransaction';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const reconcileBankTransactionRes = await reconcileBankTransaction({
                id: params.id || '',
                transIds: seletedIds || []
            });
            // console.log('reconcileBankTransactionRes', reconcileBankTransactionRes);
            dismissNotification(notificationUid);
            if (reconcileBankTransactionRes.status === 200) {
                showNotification('success', reconcileBankTransactionRes.message);
                navigate(-1);
            } else {
                throw new Error(reconcileBankTransactionRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const calcAmount = useCallback((transArr: Array<string>, upTrans: Array<any>) => {
        if (!transArr) {
            return 0;
        }
        const tempTotalAmount = upTrans.reduce((accumulator, object: any) => {
            if (transArr.includes(object._id)) {
                return accumulator + +object.amount;
            }
            return accumulator;
        }, 0);
        setTotalAmount(tempTotalAmount);
        return tempTotalAmount;
    }, []);

    const onChangeCheckbox = useCallback((id: string, isChecked: boolean, isMulti: boolean) => {
        if (id === undefined) {
            return false;
        }
        let arr = seletedIds;
        if (isChecked) {
            if (isMulti) {
                arr = uploadedTransactions.map((item: any) => item._id);
                checkAll(true);
            } else {
                arr.push(id);
            }
        } else {
            if (isMulti) {
                arr = [];
                checkAll(false);
            } else {
                arr = seletedIds.filter((item: string) => item !== id);
            }
        }
        setSeletedIds(arr);
        calcAmount(arr, uploadedTransactions);
    }, [calcAmount, seletedIds, uploadedTransactions]);

    const columns = useMemo(
        () => [
            {
                Header: t("common:date"),
                // accessor: 'dateValue', // accessor is the "key" in the data
                accessor: (originalRow: any) => {
                    return <div className="form-check">
                        <input disabled={isAlreadyReconcile} className="form-check-input transaction-checkbox" type="checkbox" value={originalRow._id} id={originalRow._id} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const isChecked = e.target.checked;
                            const value = e.target.value;
                            onChangeCheckbox(value, isChecked, false);
                        }} />
                        <label className="form-check-label" htmlFor={originalRow._id}>
                            {originalRow.dateValue}
                        </label>
                    </div>;
                }
            },
            {
                Header: t("common:name"),
                accessor: 'category'
            },
            {
                Header: t("common:reference"),
                accessor: 'reference'
            },
            {
                Header: t("common:spent"),
                // accessor: 'amountValue',
                accessor: (originalRow: any) => {
                    return <span>{originalRow.type === 'Expense' ? originalRow.amountValue : ''}</span>;
                }
            },
            {
                Header: t("common:recieved"),
                // accessor: 'amountValue',
                accessor: (originalRow: any) => {
                    return <span>{originalRow.type === 'Income' ? originalRow.amountValue : ''}</span>;
                }
            }
        ],
        [onChangeCheckbox, isAlreadyReconcile]
    );

    const validateAmount = (cb: () => void) => {
        const tempCalcAmount = calcAmount(seletedIds, uploadedTransactions);
        console.log(tempCalcAmount, totalAmount);
        const tempTotalAmount = bankTransAmount.amount || 0.00;
        if (tempCalcAmount === tempTotalAmount) {
            cb();
        } else {
            showNotification('error', t("common:reconcile_amount"), undefined, 10);
        }
    };

    useEffect(() => {
        // seletedIds = [];
        // upTrans = [];
        // unCtrlTotalAmount = 0.00;
        unCtrlAmountSearch = '';
        unCtrlQuerySearch = '';
        if (params.id) {
            getTransactionDetails(params.id, () => {
                fetchData();
            });
        }
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: '',
            showAddIcon: false,
            addLink: '',
            rightComponent: null
        });
        return () => {
            resetAppbar();
        };
    }, [calcAmount, fetchData, getTransactionDetails, params.id]);

    return (
        <div>
            <div className='row'>
                <div className='col-md-6 d-flex mb-3 '>
                    <div className='border p-2 w-100 text-start'>
                        {transactionDetails?.transactionFromDateValue}
                        <br />
                        {transactionDetails?.accountName}
                    </div>
                    <div className='border p-2 w-100 text-end'>
                        {t("common:spent")}
                        <br />
                        {transactionDetails?.transactionDebitAmount && '$'}{transactionDetails?.transactionDebitAmount}
                        {/* 910 */}
                    </div>
                    <div className='border p-2 w-100 text-end'>
                        {t("common:recieved")}
                        <br />
                        {transactionDetails?.transactionCreditAmount && '$'}{transactionDetails?.transactionCreditAmount}
                        {/* 100 */}
                    </div>
                </div>
                <div className='col-md-6'>
                    <Nav tabs className='border-0'>
                        <NavItem onClick={() => setActiveTab('1')} className={activeTab === '1' ? 'border-bottom border-3 border-primary text-center px-3 hand' : 'px-3 hand'} >
                            <div >
                                {t("common:match")}
                            </div>
                        </NavItem>
                        <NavItem onClick={() => setActiveTab('1')} className={activeTab === '2' ? 'border-bottom border-3 border-primary text-center px-3 hand' : 'px-3 hand'}>
                            <div>
                            {t("common:discuss")}
                            </div>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Card body className='bg-light text-center'>
                                <CardText>{t("common:matching_transactions")}</CardText>
                            </Card>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
            <div className='border-bottom border-3 my-3' />
            <div className='row'>
                <div className='col-md-3'>
                    <p className="fs-6">
                        <b>
                            1. {t("common:matching_transactions")}
                        </b>
                    </p>
                </div>
                <div className='col-md-3'>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                        {t("common:recieved_items")}
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                            {t("common:show_hkd")}
                        </label>
                    </div>

                </div>
                <div className='col-md-6 d-md-flex mb-3'>
                    <div className='me-2'>
                        <label className='' htmlFor="search-by-name">{t("common:search_by_name")}</label>
                        <CustomSearchBox
                            name='name'
                            defaultValue={querySearch}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                unCtrlQuerySearch = value;
                                setQuery(value);
                            }}
                            customStyles={{
                                icon: {
                                    display: 'none'
                                }
                            }} id='search-by-name' />
                    </div>
                    <div className='me-2'>
                        <label className='' htmlFor="search-by-amount">{t("common:search_by_amount")}</label>
                        <CustomSearchBox
                            defaultValue={amountSearch}
                            name='amount'
                            onChange={(e: any) => {
                                const value = e.target.value;
                                unCtrlAmountSearch = value;
                                // console.log(value);
                                setAmountSearch(value);
                            }}
                            customStyles={{
                                icon: {
                                    display: 'none'
                                }
                            }} id='search-by-amount' />
                    </div>
                    <div className='me-2'>
                        <label>&nbsp;</label>
                        <button disabled={apiStatus.inProgress} onClick={() => {
                            fetchData(1);
                        }} type="button" className="btn btn-primary">{t("common:go")}</button>
                    </div>
                </div>
            </div>
            <div className='border rounded border-1 p-1'>
                <Table
                    marginPaddingClass="my-2 py-2"
                    columns={columns}
                    data={uploadedTransactions}
                    emptyMessage={<span>
                        {t("common:transaction_not_found")}
                        <Link
                            to='/transaction'
                            className='text-decoration-none'
                        >
                            {t("common:manage")}
                        </Link>
                    </span>}
                />
                {uploadedTransactions.length
                    ? <div className='d-flex justify-content-between pb-2 px-2'>
                        <div className="form-check">
                            <input disabled={apiStatus.inProgress} className="form-check-input" type="checkbox" value="" id='selectAllOnThisPage' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const isChecked = e.target.checked;
                                const value = e.target.value;
                                onChangeCheckbox(value, isChecked, true);
                            }} />
                            <label className="form-check-label" htmlFor="selectAllOnThisPage">
                                {t("common:select_all")}
                            </label>
                        </div>
                        <span className="">
                            {t("common:showing")} 1 - {page.totalItems} {t("common:of")} {page.totalItems}
                        </span>
                    </div>
                    : null}
            </div>
            <div className='mt-4' />
            <div>
                <span className="fs-6">
                    <b>
                        3. {t("common:sum_of_transactions")} {bankTransAmount.amountType}
                    </b>
                </span>
                <div className='border-bottom border-3 mb-2 mt-1' />
                <div className='d-flex justify-content-between'>
                    <p className="fs-6">
                        <b>
                            {t("common:must_match")} {bankTransAmount.amountType}: {bankTransAmount.amountValue}
                        </b>
                    </p>
                    <p className="fs-6">
                        <b>
                            {'$'}{totalAmount}
                        </b>
                    </p>
                </div>
            </div>
            <div className='my-3 d-flex justify-content-end'>
                {isAlreadyReconcile
                    ? <button
                        type="button"
                        className={`btn  btn-sm mx-3 btn-danger`}
                        disabled={apiStatus.inProgress}
                        onClick={() => setIsAlreadyReconcile(false)}
                    >
                        {t("common:undo_reconcile")}
                    </button>
                    : <button
                        type="button"
                        className={`btn  btn-sm mx-3 ${(bankTransAmount.amount === totalAmount) ? 'btn-success' : 'btn-secondary'}`}
                        disabled={apiStatus.inProgress}
                        onClick={() => validateAmount(onReconcile)}
                    >
                        {t("common:reconcile")}
                    </button>}
                <button type='button' className='btn btn-outline-secondary btn-sm' onClick={() => goto('/bank-transactions')}>{t("common:cancel")}</button>
            </div>
            <div className='mb-5' />
        </div>
    );
};

export default Reconcile;

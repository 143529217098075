import { IMenuItem } from '../interfaces';
import i18n from '../i18n';
export * from './apiEndPoint';
export * from './styles';
export * from "./accountTypesData"
export * from './bdsBankTestData'
export const text = {
    test: 'test'
};

export const paymentMethodOptions = [{
    label: 'Cash',
    value: 'Cash'
},
{
    label: 'Credit Card',
    value: 'Credit Card'
}, {
    label: 'Bank Transfer',
    value: 'Bank Transfer'
}, {
    label: 'Cheque',
    value: 'Cheque'
}];

export const parentCategoryOptions = [{
    label: 'Sales Income',
    value: 'Sales Income'
},
{
    label: 'Other Income',
    value: 'Other Income'
}, {
    label: 'Costs of Sales',
    value: 'Costs of Sales'
}, {
    label: 'Operating Expense',
    value: 'Operating Expense'
}];

export const allowedRoleNames = ['CLIENTADMIN', 'CLIENTTEAMMEMBER', 'LEGANCYADMIN'];
export const DEBOUNCE_TIME = 700;
export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

export const menuList: IMenuItem[] = [
    {
        label: i18n.t('common:menu_transactions'),
        link: '/transactions'
    },
    {
        label: i18n.t('common:chart_of_accounts'),
        link: '/category'
    },
    {
        label: i18n.t('common:invoices'),
        link: '/invoices'
    },
    {
        label: i18n.t('common:uploaded_bank_statements'),
        link: '/uploaded-bank-statements'
    },
    {
        label: i18n.t('common:bank_transactions'),
        link: '/bank-transactions'
    },
    {
        label: i18n.t('common:manual_journals'),
        link: '/manual-journals'
    },
    {
        label: i18n.t('common:reconciliation'),
        link: '/reconciliation'
    },
    {
        label: i18n.t('common:financial_reports'),
        link: '/financial-reports'
    },
    {
        label: i18n.t('common:company_documents'),
        link: '/company-docs'
    },
    {
        label: i18n.t('common:contacts'),
        link: '/contacts'
    },
    {
        label: i18n.t('common:settings'),
        link: '/settings'
    }
];

export const financialMonthList: { label: string, value: string, lastDay: number; }[] = [
    {
        label: "January",
        value: "01",
        lastDay: 31
    },
    {
        label: "February",
        value: "02",
        lastDay: 28
    },
    {
        label: "March",
        value: "03",
        lastDay: 31
    },
    {
        label: "April",
        value: "04",
        lastDay: 30
    },
    {
        label: "May",
        value: "05",
        lastDay: 31
    },
    {
        label: "June",
        value: "06",
        lastDay: 30
    },
    {
        label: "July",
        value: "07",
        lastDay: 31
    },
    {
        label: "August",
        value: "08",
        lastDay: 31
    },
    {
        label: "September",
        value: "09",
        lastDay: 30
    },
    {
        label: "October",
        value: "10",
        lastDay: 31
    },
    {
        label: "November",
        value: "11",
        lastDay: 30
    },
    {
        label: "December",
        value: "12",
        lastDay: 31
    }
];

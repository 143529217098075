import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Container, List, Table as StrapTable, Card } from 'reactstrap';
import { IApiCallStatus } from '../../interfaces';
import { getAllInvoices } from '../../services';
import { DEFAULT_PAGE_SIZE, showNotification } from '../../utils';
import { useNavigate } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import useAppbar from '../../hooks/useAppbar';
import Table from "../../components/Table";
import html2pdf from 'html2pdf.js';
// import CustomTooltip from "../../components/CustomTooltip";
import usePagination from "../../hooks/usePagination";
import './Invoice.scss';
import { useTranslation } from 'react-i18next';

const Invoices = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const [selectedInvoice, setSelectedInvoice]: any = useState(null);

    const [invoices, page, onUpdateData] = usePagination({
        pageSize: DEFAULT_PAGE_SIZE
    })

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const columns = useMemo(
        () => [
            {
                Header: t("common:invoice_no"),
                accessor: 'invoiceNumber'
            },
            {
                Header: t("common:invoice_contact"),
                accessor: 'invoiceContact'
            },
            {
                Header: t("common:invoice_date"),
                accessor: 'invoiceDateValue'
            },
            {
                Header: t("common:due_date"),
                accessor: 'dueDateValue' || 'N/A'
            },
            {
                Header: t("common:total_amount"),
                accessor: 'totalAmountValue'
            },
            {
                Header: t("common:action"),
                accessor: (originalRow: any) => {
                    return <>
                        <div className='d-flex justify-content-around' >
                            <button className='btn p-0' onClick={(e) => handlePrint(e, originalRow)}>
                                <i className="bi bi-printer" style={{ fontSize: '1.5rem', color: '#243A77' }} />
                            </button>
                            <button className="btn p-0" onClick={(e) => handlePreDownload(e, originalRow) } >
                                <i className="bi bi-file-earmark-arrow-down" style={{ fontSize: '1.5rem', color: '#243A77' }} />
                            </button>
                        </div>
                    </>;
                }
            }
        ],
        []
    );

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const roundFloatValue = (originalNumber: number) => {
        if (Number.isFinite(originalNumber) && Math.floor(originalNumber) !== originalNumber) {
            // Check if the number is finite and has a decimal part
            const roundedNumber = originalNumber.toFixed(2);
            return parseFloat(roundedNumber);
          } else {
           return originalNumber;
          }
      }
    // Function to fetch all invoices
    const fetchData = async (
        pageNumber = page.current,
        pageSize = page.pageSize,
        search = ''
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                pageNumber,
                pageSize,
                filter: search
            };
            const usersResponse = await getAllInvoices(data);
            if (usersResponse.status === 200) {
                onUpdateData(usersResponse.data)
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const gotoDetailsPage = (id: string) => {
        navigate('/invoices/' + id);
    };

    const handlePrint = (e: any, rowData: any) => {
        e.stopPropagation();
        setSelectedInvoice(rowData);
        setTimeout(() => {
            window.print(); // Trigger the print dialog after a short delay
            setSelectedInvoice(null);
          }, 0);
        // window.print(); // Trigger the print dialog
      };

      const handlePreDownload = (e: any, rowData: any) => {
        e.stopPropagation();
        setSelectedInvoice(rowData);
        setTimeout(() => {
            handleDownload(rowData);
        }, 0)
      }

    const handleDownload = (rowData: any) => {
        const content = document.getElementById('downloading-content');
        if (content) {
          const opt = {
            margin: 10,
            filename: `${rowData.invoiceNumber}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
          };
          // Old monolithic-style usage:
          html2pdf(content, opt);
          setSelectedInvoice(null);
        }
    };

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: t("common:invoices"),
            showAddIcon: true,
            addLink: '/invoices/new'
        });
        return () => {
            resetAppbar();
        };
    }, []);

    return (
        <div className='contact-list'>
            {apiStatus.inProgress
                ? t("common:load")
                : (
                    <>
                        {/* For Browser Only */}
                        <div className='page-body print-hide'>
                            <ScrollToTop smooth className="ScrollToTop" component={<i className="bi bi-arrow-up-circle"></i>} />
                            <Table columns={columns} data={invoices} onRow={gotoDetailsPage} />
                            {/* <Table columns={columns} data={invoices} /> */}
                        </div>
                        {/* For printing Only */}
                        <Container className="print-only py-1">
                            {selectedInvoice &&
                            <Card className="p-2" style={{ width: '85vw', marginLeft: '-2.8rem' }}>
                                <Container className="mb-2 mt-3">
                                    <Row className="d-flex align-items-baseline">
                                    <Col xl="9">
                                        <p style={{ color: "#7e8d9f", fontSize: "18px" }}>
                                        <strong>{t("common:invoice")} #:  {selectedInvoice?.invoiceNumber}</strong>
                                        </p>
                                    </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Col md="12" className="text-center">
                                    <h5 className="pt-0">{ selectedInvoice?.companyName }</h5>
                                    </Col>
                                </Container>
                                <Row>
                                    <Col xl="8">
                                    <List type="unstyled">
                                        <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">{t("common:invoice_contact")}:   </span>{selectedInvoice?.invoiceContact}
                                        </li>
                                        <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">{t("common:invoice_date")}  </span>{selectedInvoice?.invoiceDateValue}
                                        </li>
                                        <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">{t("common:due_date")}:  </span>{selectedInvoice?.dueDateValue}
                                        </li>
                                        <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">{t("common:status")}:  </span>
                                        <span className="badge bg-warning text-black fw-bold ms-1">
                                        {selectedInvoice?.status?.charAt(0).toUpperCase() + selectedInvoice?.status?.slice(1)}
                                        </span>
                                        </li>
                                    </List>
                                    </Col>
                                </Row>
                                <Row className="my-2 mx-1 justify-content-center">
                                    <StrapTable striped>
                                    <thead
                                        className="text-white"
                                        style={{ backgroundColor: "#84B0CA" }}
                                    >
                                        <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{t("common:description")}</th>
                                        <th scope="col">{t("common:quantity")}</th>
                                        <th scope="col">{t("common:unit_price")}</th>
                                        <th scope="col">{t("common:amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedInvoice?.items?.map((item: any, index: number) => (
                                        <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                        {item.description}
                                            </td>
                                        <td>{item.quantity}
                                            </td>
                                        <td>{item.unitPrice}</td>
                                        <td>{roundFloatValue(item.unitPrice * item.quantity)}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </StrapTable>
                                </Row>
                                <Row>
                                <Col xl="7">
                                    <List type="unstyled" className="float-end">
                                        <li className="text-muted ms-3">
                                        <span className="text-black me-3">{t("common:subtotal")}  </span>{selectedInvoice?.subTotal}
                                        </li>
                                        <li className="text-muted ms-3 mt-2">
                                        <span className="text-black me-4">{t("common:others")}   </span> {selectedInvoice?.others}
                                        </li>
                                        <li className="text-black fw-bold ms-3 mt-2">
                                        <span className="text-black me-3">{t("common:total_amount")}   </span> HK$ {selectedInvoice?.totalAmount}
                                        </li>
                                    </List>
                                    </Col>
                                    <Col xl="12">
                                    <p className="fw-bold">{t("common:notes")}:</p>
                                    <p style={{ fontSize: '10px' }}>
                                    {selectedInvoice?.notes}
                                    </p>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xl="10">
                                    {/* <p>Invoice Contact : 09090908080</p> */}
                                    </Col>
                                </Row>
                            </Card>
                            }
                        </Container>
                         {/* For Download Only */}
                        <Container className="print-hide py-1" id="downloading-content">
                            {selectedInvoice &&
                            <Card className="p-2">
                                <Container className="mb-2 mt-3">
                                    <Row className="d-flex align-items-baseline">
                                    <Col xl="9">
                                        <p style={{ color: "#7e8d9f", fontSize: "18px" }}>
                                        <strong>{t("common:invoice")} #:  {selectedInvoice?.invoiceNumber}</strong>
                                        </p>
                                    </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Col md="12" className="text-center">
                                    <h5 className="pt-0">{ selectedInvoice?.companyName }</h5>
                                    </Col>
                                </Container>
                                <Row>
                                    <Col xl="8">
                                    <List type="unstyled">
                                        {/* <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">ID:  </span>#123-456
                                        </li> */}
                                        <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">{t("common:invoice_contact")}:   </span>{selectedInvoice?.invoiceContact}
                                        </li>
                                        <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">{t("common:invoice_date")}:  </span>{selectedInvoice?.invoiceDateValue}
                                        </li>
                                        <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">{t("common:due_date")}:  </span>{selectedInvoice?.dueDateValue}
                                        </li>
                                        <li className="text-muted mb-1">
                                        <span className="fw-bold ms-1">{t("common:status")}:  </span>
                                        <span className="badge bg-warning text-black fw-bold ms-1">
                                        {selectedInvoice?.status?.charAt(0).toUpperCase() + selectedInvoice?.status?.slice(1)}
                                        </span>
                                        </li>
                                    </List>
                                    </Col>
                                </Row>
                                <Row className="my-2 mx-1 justify-content-center">
                                    <StrapTable striped>
                                    <thead
                                        className="text-white"
                                        style={{ backgroundColor: "#84B0CA" }}
                                    >
                                        <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{t("common:description")}</th>
                                        <th scope="col">{t("common:quantity")}</th>
                                        <th scope="col">{t("common:unit_price")}</th>
                                        <th scope="col">{t("common:amount")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedInvoice?.items?.map((item: any, index: number) => (
                                        <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                        {item.description}
                                            </td>
                                        <td>{item.quantity}
                                            </td>
                                        <td>{item.unitPrice}</td>
                                        <td>{roundFloatValue(item.unitPrice * item.quantity)}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </StrapTable>
                                </Row>
                                <Row>
                                <Col xl="12">
                                    <List type="unstyled" className="float-end">
                                        <li className="text-muted ms-3">
                                        <span className="text-black me-3">{t("common:subtotal")}  </span>{selectedInvoice?.subTotal}
                                        </li>
                                        <li className="text-muted ms-3 mt-2">
                                        <span className="text-black me-4">{t("common:others")}   </span> {selectedInvoice?.others}
                                        </li>
                                        <li className="text-black fw-bold ms-3 mt-2">
                                        <span className="text-black me-3">{t("common:total_amount")}   </span> HK$ {selectedInvoice?.totalAmount}
                                        </li>
                                    </List>
                                    </Col>
                                    <Col xl="12">
                                    <p className="fw-bold">{t("common:notes")}:</p>
                                    <p style={{ fontSize: '10px' }}>
                                    {selectedInvoice?.notes}
                                    </p>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xl="10">
                                    {/* <p>Invoice Contact : 09090908080</p> */}
                                    </Col>
                                </Row>
                            </Card>
                            }
                        </Container>
                    </>
                )}
        </div>
    );
};

export default Invoices;

import React, { createContext, useEffect, useState } from "react";
import { fakeAuthProvider } from '../auth';
import { getCookie, isLoggedIn, UserDataKey } from '../utils';

interface AuthContextType {
    user: any;
    signin: (user: {[x: string]: any}, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
  }

  export const AuthContext = createContext<AuthContextType>(null!);

  const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<any>(isLoggedIn());

    const signin = (newUser: {[x: string]: any}, callback: VoidFunction) => {
      return fakeAuthProvider.signin(() => {
        setUser(newUser);
        callback();
      });
    };

    const signout = (callback: VoidFunction) => {
      return fakeAuthProvider.signout(() => {
        setUser(null);
        callback();
      });
    };

    useEffect(() => {
        const isLog = isLoggedIn();
        if(isLog) {
         setUser(getCookie(UserDataKey))
        }
    }, [])

    // console.log('AuthProvider')

    const value = { user, signin, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
export default AuthProvider;

import React, { useState } from 'react';
import { Button, Card, Form, Input, Table, Row, Col } from 'reactstrap';
import InplaceConfirm from './InplaceConfirm';
import { useTranslation } from 'react-i18next';
interface ScbTransactionsFormProps {
    // transactions: Array<{}>,
    transactions: any,
    statement: any,
    setSelectedTransactions: any,
    setImageProcessed: (val: boolean) => void,
    setWarningModal: (val: boolean) => void,
}

const ScbTransactionsForm = ({ transactions, setImageProcessed, setWarningModal, statement, setSelectedTransactions }: ScbTransactionsFormProps) => {
    const { t } = useTranslation();
    const [items, setItems]: any = useState(transactions);
    const initialFormData = {
        items: transactions
       };
       const [formData, setFormData]: any = useState(initialFormData);
       const [hoveredRow, setHoveredRow]: any = useState(null);
       const handleMouseEnter = (index: number) => {
         setHoveredRow(index);
       };
       const handleMouseLeave = () => {
        setHoveredRow(null);
       };
       const handleInputChange = (field: string, value: any) => {
        // console.log('in input change:', field, value);
            setFormData((prevData: any) => ({
               ...prevData,
               [field]: value
            }));
       };

       const handleItemChange = (index: number, field: string, value: any) => {
        // console.log(index, field, value);
        const newItems: any = [...items];
        newItems[index][field] = value;
        setItems(newItems);
        handleInputChange('items', newItems);
       };

       const addItem = () => {
         setItems([...items, { isChecked: false, transactionDate: '', description: '', deposit: '', withDrawal: '', balance: '' }]);
         handleInputChange('items', [...items, { isChecked: false, transactionDate: '', description: '', deposit: '', withDrawal: '', balance: '' }]);
       };

       const deleteItem: any = (index: number) => {
         const newItems = [...items];
         newItems.splice(index, 1);
         setItems(newItems);
         handleInputChange('items', newItems);
       };

       const addItemInline: any = (index: number) => {
        const newItems = [...items];
        newItems.splice(index + 1, 0, { isChecked: false, transactionDate: '', description: '', deposit: '', withDrawal: '', balance: '', accountName: '' });
        setItems(newItems);
        handleInputChange('items', newItems);
      };

       const handleSubmit = (e: any) => {
         e.preventDefault();
         const selectedTransactionss = items.filter((item: any) => item.isChecked);
         console.log("Selected Transactions are ===========>", selectedTransactionss);
         setSelectedTransactions(selectedTransactionss);
         setWarningModal(true);
        //  setImageProcessed(false);
       };

    return (
        <div className='py-3'>
        <Card className='pb-3'>
          <Form onSubmit={handleSubmit}>
            {/* <Container>
              <Col md="12" className="text-center">
                  <Input style={{ width: '100%', minWidth: '8rem' }} plaintext={true} className="border-0 text-center" bsSize="lg" type = "text" placeholder = "Company Name" value = {formData.companyName} onChange={(e: any) =>
                            handleInputChange('companyName', e.target.value)
                        }/>
              </Col>
            </Container> */}
            <Row className="justify-content-center">
              <Table responsive striped hover>
                <thead
                  className="text-white"
                  style={{ backgroundColor: '#2A74B5' }}
                >
                  <tr>
                    <th scope="col" ></th>
                    <th scope="col" style={{ width: '9rem' }}>{t("common:transaction_date")}</th>
                    <th scope="col" style={{ width: '16rem' }}>{t("common:description")}</th>
                    <th scope="col">{t("common:withdrawl")}</th>
                    <th scope="col">{t("common:deposit")}</th>
                    <th scope="col">{t("common:balance")}</th>
                    <th scope="col" style={{ width: '18rem' }}>{t("common:account")}</th>
                    <th scope="col" ></th>
                    <th scope="col" ></th>
                  </tr>
                </thead>
                <tbody style={{ verticalAlign: 'middle' }}>
                  {formData.items.map((item: any, index: number) => (
                     <tr key={index}
                     onMouseEnter={() => handleMouseEnter(index)}
                     onMouseLeave={handleMouseLeave}>
                      <td>
                      <Input type="checkbox" checked={item.isChecked} onChange={(e: any) =>
                        handleItemChange(index, 'isChecked', e.target.checked)
                        } />
                     </td>
                     <td><Input placeholder = {t("common:date_")} value = {item.transactionDate} onChange={(e: any) =>
                        handleItemChange(index, 'transactionDate', e.target.value)
                        }/>
                     </td>
                      <td>
                      <Input type="textarea" placeholder = {t("common:description_")} value = {item.description} onChange={(e: any) =>
                        handleItemChange(index, 'description', e.target.value)
                        }/>
                      </td>
                     <td><Input placeholder = {t("common:withdrawl_")} value = {item.withDrawal} onChange={(e: any) =>
                        handleItemChange(index, 'withDrawal', e.target.value)
                        }/></td>
                     <td><Input placeholder = {t("common:deposit_")} value = {item.deposit} onChange={(e: any) =>
                        handleItemChange(index, 'deposit', e.target.value)
                        }/>
                      </td>
                     <td><Input placeholder = {t("common:balance_")} value = {item.balance} onChange={(e: any) =>
                        handleItemChange(index, 'balance', e.target.value)
                        }/></td>
                      <td>
                      <Input type = "text" placeholder = {t("common:account_")} value = {item.accountName} onChange={(e: any) =>
                        handleItemChange(index, 'accountName', e.target.value)
                        }/>
                      </td>
                      <td>
                      {hoveredRow === index && (
                    //    <Button close onClick={() => { deleteItem(index) }}/>
                        <div className="col-md-1">
                            <InplaceConfirm
                                HTMLComponent={<i id='del-icon' className="bi bi-trash text-end" style={{ fontSize: '1.2rem', color: 'red', cursor: 'pointer' }}></i>}
                                action={ () => { deleteItem(index) }}
                                text={t("common:are_you_sure")}
                                target="del-icon"
                            />
                        </div>
                      )}
                      </td>
                      <td>
                      {hoveredRow === index && (
                       <i className="bi bi-plus text-end" style={{ fontSize: '1.3rem', color: '#333', cursor: 'pointer' }} onClick={() => { addItemInline(index); }}></i>
                      //  <Button close onClick={() => { addItemInline(index) }}/>
                      )}
                      </td>
                   </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            <Row>
            <div onClick={addItem} className="mt-0 mb-2 ms-3" style={{ cursor: 'pointer' }}>
                    + {t("common:add_new_item")}
              </div>
            </Row>
            <Row>
              {/* <Col xl="8">
              <Input type="textarea" placeholder="Add your notes here ..." id="notes" name="notes" value={formData.notes} onChange={(e: any) => handleInputChange('notes', e.target.value)}>
              </Input>
              </Col> */}
            </Row>
            <hr />
            <Row>
              <Col xl="9">
                {/* <p className="ms-2">Invoice Contact : 0000000000000</p> */}
              </Col>
              <Col xl="3">
              <div className='float-end'>
              <Button className='mx-3 btn btn-secondary' onClick={() => setImageProcessed(false)}>{t("common:cancel")}</Button>
              <Button style={{ background: '#2A74B5', color: '#ffffff' }} className='me-3' type="submit">{t("common:save")}</Button>
              </div>
              </Col>
            </Row>
            </Form>
        </Card>
      </div>
    );
};

export default ScbTransactionsForm;

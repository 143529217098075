import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import '../../styles/App.scss'
// import CustomHeader from '../../components/CustomHeader';
import InplaceConfirm from '../../components/InplaceConfirm';
import CustomHeader from '../../components/CustomHeader';
import Popup from '../../components/Popup';
// import Popup from '../../components/Popup';
import InputCtrl from '../../controllers/InputCtrl';
import SelectCtrl from '../../controllers/SelectCtrl';
import { IApiCallStatus, IFile } from '../../interfaces';
import {
    saveTransaction,
    getTransactionById,
    getAllCategory,
    uploadFile,
    deleteTransaction,
    getAllContact,
    deleteManualJournalByParams
} from '../../services';
import {
    dateFormatForInput,
    DEFAULT_PAGE_SIZE,
    dismissNotification,
    editNotification,
    groupArray,
    readFileAsDataUrl,
    showNotification
} from '../../utils';
import useAppbar from '../../hooks/useAppbar';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import PaymentDetails from '../paymentDetails/paymentDetails';
const defaultValues = {
    categoryId: '',
    contactId: '',
    date: '',
    amount: '',
    description: '',
    paymentType: undefined
};
const TransactionDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateAppbar, resetAppbar } = useAppbar({});
    // const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<{
        [x: string]: any;
    }>();

    const [openFile, setOpenFile] = useState<IFile>();
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    const [contacts, setContacts] = useState<Array<{ label: string, value: string; }>>([]);
    const [transactionDet, setTransactionDet] = useState<{
        [x: string]: any;
    }>();
    const params = useParams();
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues
    } = useForm({
        mode: 'onBlur',
        defaultValues
    });

    const [categories, setCategories] = useState<any[]>([]);
    const [showPaymentForm, setShowPaymentForm] = useState(false)
    const [categoriesPayment, setCategoriesPayment] = useState<any[]>([]);
    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    // const [image, setImage] = useState<{ blob: any; url: string; }>({
    //     blob: null,
    //     url: '',
    // });

    const [images, setImages] = useState<IFile[]>([]);

    const onImageChange = async (e: any) => {
        try {
            // const [blob] = e.target.files;
            const files = [...e.target.files];
            const tempFiles = [];
            for (const file of files) {
                const valueOf = new Date().valueOf();
                const tempId = '' + valueOf;
                // console.log(tempId);
                const url = await readFileAsDataUrl(file);
                tempFiles.push({ tempId, blob: file, fileUrl: url, fileName: file.name, fileId: '' });
            }
            setImages([...images, ...tempFiles]);
            // if (blob) {
            //     // check the type
            //     if (blob.type.includes('image')) {
            //         // read the image file
            //         const url = await readFileAsDataUrl(blob);
            //         // setImage({ blob, url });
            //     } else {
            //         showNotification('error', 'Please choose a image file');
            //     }
            // }
        } catch (err: any) {
            console.log(err);
        }
    };

    const onSubmit = async () => {
        const values = getValues();
        console.log(values)
        changeApiStatus(true, '');
        const fileIds = [];
        if (images.length) {
            for (const img of images) {
                if (img.tempId) {
                    await upload(img.blob, (data: any) => {
                        fileIds.push(data._id);
                    });
                } else {
                    fileIds.push(img.fileId);
                }
            }
        }
        onSave({ ...values, fileIds });
    };

    const upload = (file: any, cb: (data: any) => void) => {
        const notificationUid = 'SaveFileUpload';
        try {
            showNotification('info', t("common:file_upload"), notificationUid);
            return uploadFile(file, (event: any) => {
                const uploadRes = event.data;
                if (event.event === 'laod') {
                    editNotification(
                        notificationUid,
                        `${t("common:uploading")} ${event.progressValue}%`
                    );
                } else if (event.event === 'complete') {
                    dismissNotification(notificationUid);
                    if (uploadRes.status === 200) {
                        cb(uploadRes.data);
                        return uploadRes.data;
                    } else {
                        throw new Error(uploadRes.error);
                    }
                }
            });
        } catch (err: any) {
            showNotification('error', err.message);
        }
    };

    const onDelete = async () => {
        const notificationUid = 'DeleteTransactionDetails';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const saveTransactionRes = await deleteTransaction(transactionDetails?._id || transactionDet?._id);

            dismissNotification(notificationUid);
            if (saveTransactionRes.status === 200) {
                showNotification('success', saveTransactionRes.message);
                try {
                    const notificationUid = 'DeleteTransactionDetails';
                    changeApiStatus(true, '');
                    showNotification(
                        'info',
                        t("common:request_process"),
                        notificationUid
                    );
                    const deleteManualJournalRes = await deleteManualJournalByParams(
                        { transId: transactionDetails?._id || transactionDet?._id }
                    );
                    dismissNotification(notificationUid);

                    if (deleteManualJournalRes.status === 200) {
                        showNotification('success', deleteManualJournalRes.message);
                    } else {
                        throw new Error(deleteManualJournalRes.error);
                    }
                } catch (err: any) {
                    showNotification('error', err.message);
                    changeApiStatus(false, err.message);
                }
                navigate('/transaction');
            } else {
                throw new Error(saveTransactionRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const onSave = async (data: any) => {
        const notificationUid = 'SaveTransactionDetails';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const saveTransactionRes = await saveTransaction({
                id: data._id || undefined,
                categoryId: data.categoryId,
                date: data.date,
                amount: data.amount,
                paymentType: data.paymentType,
                description: data.description || '',
                contactId: data.contactId || '',
                fileIds: data.fileIds || []
            });
            dismissNotification(notificationUid);
            if (saveTransactionRes.status === 200) {
                showNotification('success', saveTransactionRes.message);
                setTransactionDet(saveTransactionRes.data)
                setShowPaymentForm(true)
                changeApiStatus(false, '');
                reset({
                    ...saveTransactionRes.data,
                    date: dateFormatForInput(saveTransactionRes.data?.date)
                });

                // navigate(-1);
            } else {
                throw new Error(saveTransactionRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const fetchAllCategory = async (
        pageNumber = 1,
        query = '',
        pageSize = DEFAULT_PAGE_SIZE
    ) => {
        try {
            changeApiStatus(true, '');
            const data = {
                query,
                pageNumber,
                pageSize
            };
            const usersResponse = await getAllCategory(data);
            if (usersResponse.status === 200) {
                const filtered = usersResponse.data.items?.reduce((result: { trans: any[], payment: any[] }, { type, category, _id, typeLabel }: any) => {
                    if (!result.trans) result.trans = [];
                    if (!result.payment) result.payment = [];
                    if (['Income', 'Expense'].includes(type)) {
                        result.trans.push({
                            label: category,
                            value: _id,
                            typeLabel: typeLabel
                        });
                    } else if (['Asset', 'Equity', 'Liability'].includes(type)) {
                        result.payment.push({
                            label: category,
                            value: _id,
                            typeLabel: typeLabel
                        });
                    }
                    return result;
                }, { trans: [], payment: [] });
                const groupLisTrans = groupArray(filtered.trans, 'typeLabel', 'options', 'label');
                const groupLisPayment = groupArray(filtered.payment, 'typeLabel', 'options', 'label');
                setCategories(groupLisTrans);
                setCategoriesPayment(groupLisPayment)
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };

    const getContacts = async () => {
        // const notificationUid = 'GetBank';
        try {
            changeApiStatus(true, '');
            // showNotification('info', 'Fetching...', notificationUid);
            const data = {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE
            };
            const getAllContactsRes = await getAllContact(data);
            // dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getAllContactsRes.status === 200) {
                const items = getAllContactsRes.data.items || [];
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    tempList.push({
                        label: item.contactName,
                        value: item._id
                    });
                    return item;
                });
                setContacts(tempList);
            } else {
                throw new Error(getAllContactsRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const getTransactionDetails = async (id: any) => {
        const notificationUid = 'GetTransactionDetails';
        try {
            changeApiStatus(true, '');
            showNotification('info', t("common:fetching"), notificationUid);
            const getTransactionByIdRes = await getTransactionById(id);
            dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getTransactionByIdRes.status === 200) {
                const tempData = getTransactionByIdRes.data;
                setTransactionDetails(tempData);
                tempData?.isPaymentComplete === true ? setShowPaymentForm(false) : setShowPaymentForm(true)
                console.log(tempData)
                if (tempData.files) {
                    const tempFiles = tempData.files.map((item: any) => ({
                        blob: null,
                        fileUrl: item.fullFileUrl ? item.fullFileUrl : '',
                        fileName: item.originalName,
                        fileId: item.fileId
                    }));
                    setImages(tempFiles);
                }
                reset({
                    ...tempData,
                    date: dateFormatForInput(tempData.date)
                });
            } else {
                throw new Error(getTransactionByIdRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const viewImage = (file: IFile) => {
        setOpenFile(file);
        setPopupIsOpen(true);
    };

    const removeFile = (file: any) => {
        const fileId = file.fileId || file.tempId;
        const tempFiles = images.filter((item: any) => {
            const itemId = item.fileId || item.tempId;
            if (itemId !== fileId) {
                return item;
            }
            return false;
        });
        setImages(tempFiles);
    };

    const showError = (_fieldName: string) => {
        const error = (errors as any)[_fieldName];
        return error
            ? (
                <div className='error-block text-danger mt-2'>
                    {error.message || t("common:field_require")}
                </div>
            )
            : null;
    };

    useEffect(() => {
        fetchAllCategory();
        getContacts();
        if (params.id && params.id !== 'new') {
            getTransactionDetails(params.id);
        }
        updateAppbar({
            leftLink: '/transaction',
            leftIcon: 'Back',
            title: `${params.id !== 'new' ? t("common:edit") : t("common:new")} ${t("common:transaction")}`,
            showAddIcon: false,
            addLink: ''
        });
        window.scrollTo(0, 0);
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadFile = (fileUrl: any, fileName: string) => {
        saveAs(fileUrl, fileName);
    };

    useEffect(() => {
        if (popupIsOpen && openFile && openFile?.fileName && (openFile?.fileName?.split(".").pop() === 'pdf')) {
            const pdfWindow = window.open("");
            pdfWindow?.document.write("<iframe width='100%' height='100%' src='" + openFile.fileUrl + "'></iframe>");
            setPopupIsOpen(false);
        }
    }, [popupIsOpen, openFile]);

    return (

        <div className='login-page'>
            <div className='container'>
                <div className='row'>
                    <div className={'col-md-4 col-sm-12 offset-md-4 text-center'}>
                    <h4>{t("common:trans_details_heading")}</h4>
                        {/* <CustomHeader title={`${transactionDetails?._id ? 'Edit' : 'Add New'} Transaction`} backFn={() => navigate('/transaction')} /> */}
                        <input
                            type='file'
                            accept='image/*'
                            name='camera'
                            id='opencamera'
                            onChange={onImageChange}
                            capture="environment"
                            hidden
                            disabled={showPaymentForm || images.length === 1 || transactionDetails?.isPaymentComplete === true}
                        // multiple
                        />
                        <input
                            type='file'
                            accept='*'
                            name='upload'
                            id='openupload'
                            onChange={onImageChange}
                            hidden
                            disabled={showPaymentForm || images.length === 1 || transactionDetails?.isPaymentComplete === true}
                        // multiple
                        />
                        <Popup
                            isOpen={popupIsOpen}
                            onCloseModal={() => setPopupIsOpen(false)}
                        >
                            <CustomHeader title="" backFn={() => setPopupIsOpen(false)} />
                            <div style={{ width: "100%", height: "100%" }}>
                                {openFile && openFile.fileUrl && (
                                    <>
                                        <div style={{ width: "100%", textAlign: "right", marginRight: '50px' }}>
                                            <button className="btn p-0" style={{ color: "blue" }} onClick={() => { downloadFile(openFile.fileUrl, openFile.fileName) }}>
                                                {t("common:download_my_file")}
                                            </button>
                                        </div>
                                        <div style={{ width: "100%", height: "100%" }}>
                                            <img width="100%" height="auto" src={openFile.fileUrl} alt="text" className="mt-4 mb-3" />
                                        </div>
                                    </>
                                )}
                            </div>
                        </Popup>

                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className='mt-4'
                        >
                            <div className={'col'}>
                                <div className={'col-12 input-group d-block'} >
                                    <label className='form-label text-start d-flex justify-content-between'>
                                        {t("common:account")}
                                        <Link
                                            to='/category'
                                            className='text-decoration-none'
                                        >
                                            {t("common:manage")}
                                        </Link>
                                    </label>
                                    <SelectCtrl
                                        showError={showError}
                                        placeholder={t("common:account")}
                                        required
                                        control={control}
                                        name='categoryId'
                                        disabled={showPaymentForm || transactionDetails?.isPaymentComplete === true}
                                        selectClassNamePrefix='search-select'
                                        options={categories}
                                    />
                                </div>
                                <div className={'col-12 input-group d-block'} >
                                    <label className='form-label text-start d-block'>
                                        {t("common:date")}
                                    </label>
                                    <InputCtrl
                                        type='date'
                                        showError={showError}
                                        placeholder={t("common:date")}
                                        required
                                        control={control}
                                        name='date'
                                        disabled={showPaymentForm || transactionDetails?.isPaymentComplete === true}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className={'col'}>
                                <div className={'col-12 input-group d-block'}>
                                    <label className='form-label text-start d-block'>
                                        {t("common:amount_hkd")}
                                    </label>
                                    <InputCtrl
                                        type='text'
                                        showError={showError}
                                        placeholder={t("common:amount")}
                                        required
                                        control={control}
                                        name='amount'
                                        disabled={showPaymentForm || transactionDetails?.isPaymentComplete === true}
                                        className='form-control'
                                    />
                                </div>
                                {/* <div className={showPaymentForm ? 'col-6' : 'col-12 input-group d-block'}>
                                    <label className='form-label text-start d-block'>
                                        {t("common:payment_type")}
                                    </label>
                                    <SelectCtrl
                                        showError={showError}
                                        placeholder={t("common:payment_type")}
                                        required
                                        control={control}
                                        name='paymentType'
                                        disabled={showPaymentForm || transactionDetails?.isPaymentComplete === true}
                                        // className='form-control'
                                        selectClassNamePrefix='search-select'
                                        options={paymentMethodOptions}
                                    />
                                </div> */}
                                <div className={'col-12 input-group d-block'}>
                                    <label className='form-label text-start d-block'>
                                        {t("common:tans_description")}
                                    </label>
                                    <InputCtrl
                                        type='text'
                                        showError={showError}
                                        placeholder={t("common:tans_description")}
                                        required
                                        control={control}
                                        name='description'
                                        disabled={showPaymentForm || transactionDetails?.isPaymentComplete === true}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className={'col'}>
                                <div className={'col-12 input-group d-block'}>
                                    <label className='form-label text-start d-flex justify-content-between'>
                                        {t("common:assign_contact")}
                                        <Link
                                            to='/contacts'
                                            className='text-decoration-none'
                                        >
                                            {t("common:manage")}
                                        </Link>
                                    </label>
                                    <SelectCtrl
                                        showError={showError}
                                        placeholder={t("common:select_contact")}
                                        required={false}
                                        control={control}
                                        name='contactId'
                                        disabled={showPaymentForm || transactionDetails?.isPaymentComplete === true}
                                        selectClassNamePrefix='search-select'
                                        options={contacts}
                                    />
                                </div>
                                {!showPaymentForm && <div className={'col-12 input-group d-block'} style={{
                                    zIndex: 0
                                }}>
                                    <label className='form-label text-start d-flex justify-content-between'>
                                        {t("common:upload_file")}

                                    </label>
                                    <div className='text-start mt-2'>
                                        <label
                                            className='w-25 btn btn-secondary login-btn mr-4 hand'
                                            style={{
                                                marginRight: 10
                                            }}
                                            htmlFor='opencamera'
                                        >
                                            {t("common:camera")}
                                        </label>
                                        <label
                                            className='w-25 btn btn-secondary login-btn cancel-btn hand'
                                            htmlFor='openupload'
                                        >
                                            {t("common:upload")}
                                        </label>
                                    </div>
                                </div>}
                            </div>
                            <div className='mt-3'>
                                {images?.map((file: any, idx: number) => {
                                    return <div className='mt-1' key={idx}>
                                        <div className='d-flex justify-content-between mb-4'>
                                            <div className='text-blue hand' style={{ pointerEvents: (file?.blob?.type?.split('/')[0] === 'image' || (file?.fileName?.split('.').pop() !== "zip")) ? "auto" : "none" }} onClick={() => viewImage(file)}>{file.fileName}</div>
                                            <div>
                                                <img
                                                    className='hand'
                                                    width={18}
                                                    height={18}
                                                    src='/icons/xmark.svg'
                                                    alt='img-icon'
                                                    style={{ pointerEvents: (showPaymentForm || transactionDetails?.isPaymentComplete === true) ? 'none' : 'auto' }}

                                                    onClick={() => removeFile(file)}
                                                />
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                            {!showPaymentForm && <div className='mt-4'>
                                <button
                                    type='submit'
                                    className='btn btn-secondary login-btn'
                                    disabled={apiStatus.inProgress || transactionDetails?.isPaymentComplete === true}
                                >
                                    {t("common:save")}
                                </button>
                                <button
                                    type='reset'
                                    className='btn btn-secondary login-btn cancel-btn mt-3'
                                    disabled={apiStatus.inProgress || transactionDetails?.isPaymentComplete === true}
                                    onClick={() => navigate('/transaction')}
                                >
                                    {t("common:cancel")}
                                </button>
                            </div>}
                            {(transactionDetails?._id || transactionDet?._id) && <div className={showPaymentForm ? 'd-flex justify-content-center flex mt-4' : 'mt-4 mr-3'}>
                                <InplaceConfirm
                                    HTMLComponent={<i className="bi bi-trash text-end" style={{ fontSize: '2rem', color: 'red', marginRight: 20 }}></i>}
                                    action={() => {
                                        onDelete();
                                    }}
                                    target="del-icon"
                                    text="Are you sure"
                                />
                                {showPaymentForm && <i onClick={() => setShowPaymentForm(false)} className="bi bi-pencil-square text-end" style={{ fontSize: '2rem', color: 'green' }}></i>}

                            </div>}
                            {transactionDetails?.isPaymentComplete === true && <Alert>{t("common:payment_completed_message")}</Alert>}
                        </form>
                    </div>
                </div>
            </div>
            {showPaymentForm && <PaymentDetails categories={categoriesPayment} transactionDetails={transactionDet || transactionDetails}></PaymentDetails>}
        </div>
    );
};

export default TransactionDetails;

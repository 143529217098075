import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { allowedRoleNames } from '../constants';
import InputCtrl from '../controllers/InputCtrl';
import useAuth from '../hooks/useAuth';
import { IApiCallStatus } from '../interfaces';
import { userLogin, validateAdminUsingToken } from '../services';
import { clearCookies, dismissNotification, SHAREDCOOKIE, showNotification, UserDataKey, createCookie } from '../utils';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

// import { useTranslation } from 'react-i18next';
const defaultValues = {
    email: '',
    password: ''
};
const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    // setting title for webpage
    document.title = t("common:client_portal");
    const { handleSubmit, control, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues
    });

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false
	});
    const [loading, setLoading] = useState<Boolean>(false);

	const changeApiStatus = (progress: boolean, message: string) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message
		});
	};

    const onSubmit = async (data: any) => {
        const notificationUid = "Login"
        try {
			changeApiStatus(true, '');
			showNotification('info', t("common:request_process"), notificationUid);
			const loginRes = await userLogin(data);
			dismissNotification(notificationUid);
			if (loginRes.status === 200) {
                const verifyStatus = loginRes.data.verifyStatus;
                const resData = loginRes.data;
                if (allowedRoleNames.includes(resData.roleName)) {
                    showNotification('success', loginRes.message);
                    // createCookie(UserDataKey, JSON.stringify(loginRes.data), SHAREDCOOKIE);
                    createCookie(UserDataKey, JSON.stringify({ ...loginRes.data, fileIds: undefined }), SHAREDCOOKIE);
                    localStorage.setItem(UserDataKey, JSON.stringify(loginRes.data));
                    auth.signin(loginRes.data, () => {
                        if (verifyStatus === true) {
                            loginRes.data.verify2FA = false;
                            navigate('/send', { replace: true });
                        } else {
                            navigate('/transaction', { replace: true });
                        }
                    });
                } else {
                    throw new Error(t("common:unauthorized_access"))
                }
			} else {
				throw new Error(loginRes.error);
			}
		} catch (err: any) {
			showNotification('error', err.message);
			changeApiStatus(false, err.message);
		}
    };

    const showError = (_fieldName: string) => {
        const error = (errors as any)[_fieldName];
        return error
    ? (
            <div className="error-block text-danger">{error.message || t("common:field_require")}</div>
        )
    : null;
    };

    const checkIsloggedIn = () => {
        if(auth.user) {
            if(allowedRoleNames.includes(auth.user.roleName)) {
                navigate('/transaction', { replace: true })
            } else {
                clearCookies();
            }
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        if(!urlParams) {
            checkIsloggedIn();
        }
    }, [])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        const notificationUid = "Login";
        const validateAdmin = async (token: string) => {
            try{
                setLoading(true);
                changeApiStatus(true, '');
                showNotification('info', t("common:request_process"), notificationUid);
                const loginRes = await validateAdminUsingToken(token);
                dismissNotification(notificationUid);
                if (loginRes.status === 200) {
                    const resData = loginRes.data;
                    const langCode = resData.lang;
                    if(allowedRoleNames.includes(resData.roleName)) {
                        if(langCode) {
                        i18n.changeLanguage(langCode);
                        localStorage.setItem('selectedLanguage', langCode);
                        }
                        showNotification('success', loginRes.message);
                        // createCookie(UserDataKey, JSON.stringify(loginRes.data), SHAREDCOOKIE);
                        createCookie(UserDataKey, JSON.stringify({ ...loginRes.data, fileIds: undefined }), SHAREDCOOKIE);
                        auth.signin(loginRes.data, () => {
                            setLoading(false);
                            navigate('/transaction', { replace: true })
                        })
                    } else {
                        throw new Error(t("common:unauthorized_access"))
                    }
                } else {
                    throw new Error(loginRes.error);
                }
                // setLoading(false);
            }catch(err: any) {
                showNotification('error', err.message);
                changeApiStatus(false, err.message);
                setLoading(false);
            }
        }
        if(urlParams) {
            const token = urlParams.get('data');
            if(token) {
                validateAdmin(token);
            }
        }
    }, [])

    return (
        <div className='login-page'>
            <div className="container h-100">
                {
                loading
                ? (
                <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                    <Spinner type="grow" />
                </div>
                )
                : (
                    <div className="row h-100 align-items-center">
                    <div className="col-md-4 col-sm-12 offset-md-4 text-center">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className=''
                        >
                            <div className="d-logo">
                                <img alt="img" src="./images/logo.png" width="200px" height="100%" />
                            </div>
                            <h4 className="wel-txt mb-4">{t("common:welcome")}</h4>
                            <div className="col-12 input-group d-block">
                                <label className="form-label text-start d-block">{t("common:email")}</label>
                                <InputCtrl
                                    type='email'
                                    showError={showError}
                                    placeholder={t("common:email_address")}
                                    required
                                    control={control}
                                    name='email'
                                    disabled={false}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 input-group d-block">
                                <label className="form-label text-start d-block">{t("common:password")}</label>
                                <InputCtrl
                                    type='password'
                                    showError={showError}
                                    placeholder={t("common:password")}
                                    required
                                    control={control}
                                    name='password'
                                    disabled={false}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-check text-start">
                                <input className="form-check-input checkbox-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {t("common:button")}
                                </label>
                            </div>
                            <div className='mt-4'>
                                <button type='submit' className='btn btn-secondary login-btn' disabled={apiStatus.inProgress}>{t("common:login")}</button>
                            </div>
                        </form>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

export default Login;

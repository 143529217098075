import { useState } from 'react';
import { IApiCallStatus, IApiResponse, IPagination } from '../interfaces';
import { showNotification } from '../utils';

export type Results = Array<any> | Record<string, any>;
export interface ApiParameter {
    [x: string]: any;
}

export interface UseTestProps<
    TResults extends Results = Results,
    TApiParameter extends ApiParameter = ApiParameter
> {
    api: (data: TApiParameter | Partial<TApiParameter> | any) => Promise<IApiResponse>;
    apiParam: Partial<TApiParameter>,
    results?: Partial<TResults>;
    autoApiHit: boolean;
    afterApiResponse?: (data: any) => void
}

export type UseTestReturn<
    TResults extends Results = Results,
    TApiParameter extends ApiParameter = ApiParameter
    > = [
        TResults,
        (parameter?: TApiParameter) => void,
        IApiCallStatus,
        (apiStatus: Partial<IApiCallStatus>) => void,
        IPagination,
        (pageData: Partial<TApiParameter>) => void,
    ];

export function useList<
    TResults extends Results = Results,
    TApiParameter extends ApiParameter = ApiParameter
> (
	props: UseTestProps<TResults, TApiParameter>

): UseTestReturn<TResults> {
	const [parameter, setParameter] = useState<any>({
		...props.apiParam
	});

	const [results, setResults] = useState<any>(props.results);

	// controls at which state api is
	const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
		failMessage: '',
		failed: false,
		inProgress: false,
		isFullLoaded: false
	});

	// for changing api statuses
	const changeApiStatus = (progress: boolean, message: string, isFullLoaded = apiStatus.isFullLoaded) => {
		setApiStatus({
			inProgress: progress,
			failed: !!message,
			failMessage: message,
			isFullLoaded
		});
	};
	const onUpdatePage = (pageData: any) => {
		const tempData = {
			...parameter,
			...pageData
		};
		setParameter(tempData);
	};

	const apiHit = async (param = parameter) => {
		const tempParam = {
			...parameter,
			...param
		};
		try {
			changeApiStatus(true, '');
            console.log('tempParam', tempParam);
			const apiResponse = await props.api(tempParam);
            console.log('apiResponse', apiResponse);
			if (apiResponse.status === 200) {
				const data = apiResponse.data;
                if(props.afterApiResponse) {
                    props.afterApiResponse(data)
                }
				if (data && Array.isArray(data.items)) {
					setResults(data.items);
					setParameter({
						...tempParam,
						current: data.pageNumber,
						totalItems: data.totalItems
					});
				}
				changeApiStatus(false, '', true);
			} else {
				throw new Error(apiResponse.error);
			}
		} catch (error: any) {
			changeApiStatus(false, error.message, true);
			showNotification('error', error.message);
		}
	};

    const updateApiStatus = (apiS: Partial<IApiCallStatus>) => {
		setApiStatus({
			...apiStatus,
			...apiS
		});
	};

	return [results, apiHit, apiStatus, updateApiStatus, parameter, onUpdatePage];
}

import React from 'react';
import { Controller } from 'react-hook-form';
import { SelectCtrlProps } from '../interfaces';
import Select from 'react-select';

const SelectCtrl = ({
    control,
    name,
    placeholder,
    rootClass,
    showError,
    required,
    disabled,
    className,
    id,
    options,
    selectClassNamePrefix
}: SelectCtrlProps) => {
    const rules: any = {
        required
    };

    const customStyles = {
        groupHeading: (provided: any, state: any) => ({
            ...provided,
            color: '#000000',
            fontSize: 18
        }),
        singleValue: (provided: any, state: any) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    };

    const tempOptions = options.flatMap((elem: any) => elem.options ? elem.options : elem);

    return (
        <div className={rootClass || 'input-ctrl'}>
            <section>
                <Controller
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field: { onChange, value, ...all } }) => (
                        <Select
                            {...all}
                            styles={customStyles}
                            name={name}
                            id={id}
                            value={tempOptions.find(c => c.value === value)}
                            // value={options.find(c => {
                            //     if(c.options?.length){
                            //         // return c.options.some((item:any) => item.value === value)
                            //         return false;
                            //     } else if(c.value === value) {
                            //            return true;
                            //     } else {
                            //         return false;
                            //     }
                            // })}
                            onChange={(val: any) => onChange(val.value)}
                            className={className}
                            placeholder={placeholder}
                            isDisabled={disabled}
                            options={options}
                            classNamePrefix={selectClassNamePrefix}
                        />
                    )}
                />
                {showError(name)}
            </section>
        </div>
    );
};

export default SelectCtrl;

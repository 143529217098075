import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { IApiCallStatus, IFile } from '../../interfaces';
import {
    getAllCategory,
    saveManualJournal,
    getManualJournalById,
    deleteManualJournal,
    getAllContact,
    uploadFile
} from '../../services';
import {
    updateAppbar,
    resetAppbar,
    showNotification,
    dismissNotification,
    dateFormatForInput,
    groupArray,
    DEFAULT_PAGE_SIZE,
    readFileAsDataUrl,
    editNotification
} from '../../utils';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import InputCtrl from '../../controllers/InputCtrl';
import SelectCtrl from '../../controllers/SelectCtrl';
import InplaceConfirm from '../../components/InplaceConfirm';
import TextareaCtrl from '../../controllers/TextareaCtrl';
import { useTranslation } from 'react-i18next';
import Popup from '../../components/Popup';
import CustomHeader from '../../components/CustomHeader';
import { saveAs } from 'file-saver';
interface FormValues {
    _id: string;
    narration: string;
    date: string;
    totalDebitAmount: string;
    totalCreditAmount: string;
    contactId: string;
    fileIds: string[];
    manualJournalEntries: {
        _id: string;
        description: string;
        accountId: string;
        debitAmount: number;
        creditAmount: number;
    }[];
}

const defaultValues = [
    { description: '', creditAmount: 0, debitAmount: 0, accountId: '' },
    { description: '', creditAmount: 0, debitAmount: 0, accountId: '' }
];

const sumArrayObject = (array: Array<any>, key: string) => {
    return array.reduce((acc, curr: any) => acc + Number(curr[key]) || 0, 0);
};

interface ICalcField {
    label: string;
    name: string;
    classes: string;
}

const ManualJournalDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [openFile, setOpenFile] = useState<IFile>();
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    // const [manualJournalEntries, setManualJournalEntries] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [contacts, setContacts] = useState<Array<{ label: string, value: string; }>>([]);

    const downloadFile = (fileUrl: any, fileName: string) => {
        saveAs(fileUrl, fileName);
    };

    const calcFields: ICalcField[] = [
        { label: '', name: 'fake-1', classes: 'visually-hidden' },
        { label: t("common:subtotal"), name: 'fake-2', classes: 'text-start text-dark' },
        { label: '', name: 'totalDebitAmount', classes: 'text-end text-dark' },
        { label: '', name: 'totalCreditAmount', classes: 'text-end text-dark' }
    ];
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        getValues,
        watch
    } = useForm<FormValues>({
        defaultValues: {
            date: '',
            narration: '',
            manualJournalEntries: defaultValues
        },
        mode: 'onBlur'
    });
    const { fields, append, remove, update } = useFieldArray({
        name: 'manualJournalEntries',
        control
    });

    const watchNarration = watch('narration');
    const prefillDesc = useCallback(async (val: string) => {
        if (fields && fields.length) {
            for (let i = 0; i < fields.length; i++) {
                await update(i, {
                    ...fields[i],
                    description: val
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (watchNarration || watchNarration === '') {
            // prefillDesc(watchNarration);
        }
    }, [prefillDesc, watchNarration]);

    const [manualJournalDetails, setManualJournalDetails] = useState<{
        [x: string]: any;
    }>();

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };

    const goto = (path: any) => {
        navigate(path);
    };

    const getManualJournalDetails = useCallback(
        async (id: any, cb?: (data: any) => void) => {
            const notificationUid = 'GetManualJournalById';
            try {
                changeApiStatus(true, '');
                showNotification('info', t("common:fetching"), notificationUid);
                const getManualJournalByIdRes = await getManualJournalById(id);
                dismissNotification(notificationUid);
                changeApiStatus(false, '');
                if (getManualJournalByIdRes.status === 200) {
                    const tempData = getManualJournalByIdRes.data;
                    if (tempData.files) {
                        const tempFiles = tempData.files.map((item: any) => ({
                            blob: null,
                            fileUrl: item.fullFileUrl ? item.fullFileUrl : '',
                            fileName: item.originalName,
                            fileId: item.fileId
                        }));
                        setImages(tempFiles);
                    }
                    if (cb) {
                        cb(tempData);
                    }
                    let tempEntries = tempData.manualJournalEntries || [];
                    if (!tempEntries.length) {
                        tempEntries = defaultValues;
                    }
                    reset({
                        ...tempData,
                        date: dateFormatForInput(tempData.date),
                        manualJournalEntries: tempEntries
                    });
                    setManualJournalDetails(tempData);
                } else {
                    throw new Error(getManualJournalByIdRes.error);
                }
            } catch (err: any) {
                showNotification('error', err.message);
                changeApiStatus(false, err.message);
            }
        },
        [reset]
    );

    const onDelete = async () => {
        const notificationUid = 'DeleteManualJournal';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const deleteManualJournalRes = await deleteManualJournal(
                manualJournalDetails?._id
            );
            dismissNotification(notificationUid);
            if (deleteManualJournalRes.status === 200) {
                showNotification('success', deleteManualJournalRes.message);
                goto('/manual-journals');
            } else {
                throw new Error(deleteManualJournalRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const fetchAllCategory = useCallback(async () => {
        try {
            changeApiStatus(true, '');
            const data = {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE
            };
            const usersResponse = await getAllCategory(data);
            if (usersResponse.status === 200) {
                const tempList = usersResponse.data.items.map((cat: any) => ({
                    label: cat.category,
                    value: cat._id,
                    typeLabel: cat.typeLabel
                }));
                const groupLis = groupArray(tempList, 'typeLabel', 'options', 'label');
                setCategories(groupLis);
                if (usersResponse.data.files) {
                    const tempFiles = usersResponse.data.files.map((item: any) => ({
                        blob: null,
                        fileUrl: item.fullFileUrl ? item.fullFileUrl : '',
                        fileName: item.originalName,
                        fileId: item.fileId
                    }));
                    setImages(tempFiles);
                }
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    }, []);

    const getContacts = async () => {
        // const notificationUid = 'GetBank';
        try {
            changeApiStatus(true, '');
            // showNotification('info', 'Fetching...', notificationUid);
            const data = {
                pageNumber: 1,
                pageSize: DEFAULT_PAGE_SIZE
            };
            const getAllContactsRes = await getAllContact(data);
            // dismissNotification(notificationUid);
            changeApiStatus(false, '');
            if (getAllContactsRes.status === 200) {
                const items = getAllContactsRes.data.items || [];
                const tempList: Array<any> = [];
                items.map((item: any) => {
                    tempList.push({
                        label: item.contactName,
                        value: item._id
                    });
                    return item;
                });
                setContacts(tempList);
            } else {
                throw new Error(getAllContactsRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };
    const [images, setImages] = useState<IFile[]>([]);

    const onImageChange = async (e: any) => {
        try {
            // const [blob] = e.target.files;
            const files = [...e.target.files];
            const tempFiles = [];
            for (const file of files) {
                const valueOf = new Date().valueOf();
                const tempId = '' + valueOf;
                // console.log(tempId);
                const url = await readFileAsDataUrl(file);
                tempFiles.push({ tempId, blob: file, fileUrl: url, fileName: file.name, fileId: '' });
            }
            setImages([...images, ...tempFiles]);
            // if (blob) {
            //     // check the type
            //     if (blob.type.includes('image')) {
            //         // read the image file
            //         const url = await readFileAsDataUrl(blob);
            //         // setImage({ blob, url });
            //     } else {
            //         showNotification('error', 'Please choose a image file');
            //     }
            // }
        } catch (err: any) {
            console.log(err);
        }
    };

    const viewImage = (file: IFile) => {
        setOpenFile(file);
        setPopupIsOpen(true);
    };

    const removeFile = (file: any) => {
        // console.log(file);
        const fileId = file.fileId || file.tempId;
        const tempFiles = images.filter((item: any) => {
            const itemId = item.fileId || item.tempId;
            console.log(fileId, itemId);
            if (itemId !== fileId) {
                return item;
            }
            return false;
        });

        // console.log('tempFiles', tempFiles);

        setImages(tempFiles);
    };

    const upload = (file: any, cb: (data: any) => void) => {
        const notificationUid = 'SaveFileUpload';
        try {
            showNotification('info', t("common:file_upload"), notificationUid);
            return uploadFile(file, (event: any) => {
                const uploadRes = event.data;
                if (event.event === 'laod') {
                    editNotification(
                        notificationUid,
                        `${t("common:uploading")} ${event.progressValue}%`
                    );
                } else if (event.event === 'complete') {
                    dismissNotification(notificationUid);
                    if (uploadRes.status === 200) {
                        cb(uploadRes.data);
                        return uploadRes.data;
                    } else {
                        throw new Error(uploadRes.error);
                    }
                }
            });
        } catch (err: any) {
            showNotification('error', err.message);
        }
    };

    const onSubmit = async (data: FormValues) => {
        const notificationUid = 'SaveManualJournals';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );
            const fileIds = [];
        if (images.length) {
            for (const img of images) {
                if (img.tempId) {
                    await upload(img.blob, (data: any) => {
                        fileIds.push(data._id);
                    });
                } else {
                    fileIds.push(img.fileId);
                }
            }
        }
            const saveCategoryRes = await saveManualJournal({
                id: data._id || undefined,
                narration: data.narration,
                date: data.date,
                contactId: data.contactId || "",
                fileIds: fileIds || [],
                manualJournalEntries: data.manualJournalEntries.map(item => {
                    return {
                        ...item,
                        id: null
                    };
                }),
                totalDebitAmount: sumArrayObject(
                    data.manualJournalEntries,
                    'debitAmount'
                ),
                totalCreditAmount: sumArrayObject(
                    data.manualJournalEntries,
                    'creditAmount'
                )
            });
            dismissNotification(notificationUid);
            if (saveCategoryRes.status === 200) {
                showNotification('success', saveCategoryRes.message);
                goto('/manual-journals');
            } else {
                throw new Error(saveCategoryRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    const validateAmount = (
        data: FormValues,
        cb: (formData: FormValues) => void
    ) => {
        const totalDebitAmount = sumArrayObject(
            data.manualJournalEntries,
            'debitAmount'
        );
        const totalCreditAmount = sumArrayObject(
            data.manualJournalEntries,
            'creditAmount'
        );
        if (totalDebitAmount === totalCreditAmount) {
            cb(data);
        } else {
            showNotification(
                'error',
                t("common:debit_and_credit"),
                undefined,
                10
            );
        }
    };

    const showErrorForArray = (mainKey: string, index: number, _fieldName: string) => {
        const tempErrors: any = errors;
        let error: any = null;
        if (tempErrors && tempErrors[mainKey]) {
            const tempArray = tempErrors[mainKey];
            if (tempArray && tempArray[index]) {
                error = tempArray[index][_fieldName];
            }
        }
        return error
            ? (
                <div className='error-block text-danger mt-2'>
                    {error.message || t("common:field_require")}
                </div>
            )
            : null;
    };

    const showError = (_fieldName: string) => {
        const error = (errors as any)[_fieldName];
        // console.log('errors', errors);
        return error
            ? (
                <div className='error-block text-danger mt-2'>
                    {error.message || t("common:field_require")}
                </div>
            )
            : null;
    };

    const formValues = useWatch({ name: 'manualJournalEntries', control });

    useEffect(() => {
        if (formValues && formValues.length) {
            const tempTotalDebitAmount = sumArrayObject(formValues, 'debitAmount');
            const tempTotalCreditAmount = sumArrayObject(formValues, 'creditAmount');
            setValue('totalDebitAmount', tempTotalDebitAmount);
            setValue('totalCreditAmount', tempTotalCreditAmount);
        }
    }, [formValues, setValue]);

    useEffect(() => {
        fetchAllCategory();
        getContacts();
        if (params.id && params.id !== 'new') {
            getManualJournalDetails(params.id, () => {
                // fetchData();
            });
        }
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: `${params.id !== 'new' ? t("common:edit") : t("common:_new")} ${t("common:manual_journal")}`,
            showAddIcon: false,
            addLink: '',
            rightComponent: null
        });
        return () => {
            resetAppbar();
        };
    }, [fetchAllCategory, getManualJournalDetails, params.id]);

    useEffect(() => {
        if (popupIsOpen && openFile && openFile?.fileName && (openFile?.fileName?.split(".").pop() === 'pdf')) {
            const pdfWindow = window.open("");
            console.log(openFile)
            pdfWindow?.document.write("<iframe width='100%' height='100%' src='" + openFile.fileUrl + "'></iframe>");
            setPopupIsOpen(false);
        }
    }, [popupIsOpen, openFile]);

    return (
        <div>
            <div className='p-1'>
                <div>
                    {/* <CustomHeader title={`${transactionDetails?._id ? 'Edit' : 'Add New'} Transaction`} backFn={() => navigate('/transaction')} /> */}
                        <input
                            type='file'
                            accept='image/*'
                            name='camera'
                            id='opencamera'
                            onChange={onImageChange}
                            capture="environment"
                            hidden
                            // multiple
                            disabled={images.length === 1}
                        />
                        <input
                            type='file'
                            accept='*'
                            name='upload'
                            id='openupload'
                            onChange={onImageChange}
                            hidden
                            // multiple
                            disabled={images.length === 1}
                        />
                        <Popup
                        isOpen={popupIsOpen}
                        onCloseModal={() => setPopupIsOpen(false)}
                        >
                        <CustomHeader title="" backFn={() => setPopupIsOpen(false)} />
                        <div style={{ width: "100%", height: "100%" }}>
                            {openFile && openFile.fileUrl && (
                                <>
                                    <div style={{ width: "100%", textAlign: "right", marginRight: '50px' }}>
                                        <button className="btn p-0" style={{ color: "blue" }} onClick={() => { downloadFile(openFile.fileUrl, openFile.fileName) }}>
                                            {t("common:download_my_file")}
                                        </button>
                                    </div>
                                    <div style={{ width: "100%", height: "100%" }}>
                                    <img width="100%" height="auto" src={openFile.fileUrl} alt="text" className="mt-4 mb-3" />
                                    </div>
                                </>
                            )}
                        </div>
                        </Popup>

                    <form
                        onSubmit={handleSubmit((data: FormValues) =>
                            validateAmount(data, onSubmit)
                        )}
                    >
                        <div className='row'>
                            <div className='col-md-6'>
                                <label htmlFor='narration'>{t("common:narration")}</label>
                                <TextareaCtrl
                                    showError={showError}
                                    id='narration'
                                    name='narration'
                                    required
                                    control={control}
                                    placeholder={t("common:narration")}
                                    className='form-control'
                                    disabled={false}
                                    rows={3}
                                    cols={10}
                                />
                            </div>
                            <div className='col-md-6 d-block'>
                                    <label className='form-label text-start d-block'>
                                        {t("common:date")}
                                    </label>
                                    <InputCtrl
                                        type='date'
                                        showError={showError}
                                        placeholder={t("common:date")}
                                        required
                                        control={control}
                                        name='date'
                                        disabled={false}
                                        className='form-control'
                                        // maxDate={new Date()}
                                    />
                                </div>
                                <div>
                                <label className='form-label text-start d-block'>
                                    {t("common:supporting_docs")}
                                </label>
                            </div>
                            <div className='col-12 input-group d-block' style={{
                                zIndex: 0
                            }}>
                                <div className='text-start mt-3'>
                                    <label
                                        className='w-25 btn btn-secondary login-btn mr-4 hand'
                                        style={{
                                            marginRight: 10
                                        }}
                                        htmlFor='opencamera'
                                    >
                                        {t("common:camera")}
                                    </label>
                                    <label
                                        className='w-25 btn btn-secondary login-btn cancel-btn hand'
                                        htmlFor='openupload'
                                    >
                                        {t("common:upload")}
                                    </label>
                                </div>
                            </div>
                              <label className='form-label text-start d-flex justify-content-between mt-3'>
                                {t("common:supporting_docs")}
                              </label>
                            <div className='mt-3'>
                                {images?.map((file: any, idx: number) => {
                                    return <div className='mt-1' key={idx}>
                                        <div className='d-flex justify-content-between mb-4'>
                                        <div className='text-blue hand' style={{ pointerEvents: (file?.blob?.type?.split('/')[0] === 'image' || (file?.fileName?.split('.').pop() !== "zip")) ? "auto" : "none" }} onClick={() => viewImage(file)}>{file.fileName}</div>
                                            <div>
                                                <img
                                                    className='hand'
                                                    width={18}
                                                    height={18}
                                                    src='/icons/xmark.svg'
                                                    alt='img-icon'
                                                    onClick={() => removeFile(file)}
                                                />
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                            { /* End working here */ }

                                <div className='col-md-6 input-group d-block'>
                                    <label className='form-label text-start d-flex justify-content-between'>
                                        {t("common:assign_contact")}
                                        <Link
                                        to='/contacts'
                                        className='text-decoration-none'
                                    >
                                        {t("common:manage")}
                                    </Link>
                                    </label>
                                    <SelectCtrl
                                        showError={showError}
                                        placeholder={t("common:select_contact")}
                                        required={false}
                                        control={control}
                                        name='contactId'
                                        disabled={false}
                                        selectClassNamePrefix='search-select'
                                        options={contacts}
                                    />
                                </div>
                        </div>

                        <div className='row mb-3'>
                            <div>
                                <div className='form-check mt-2'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexCheckDefault'
                                        checked
                                    />
                                    <label
                                        className='form-check-label'
                                        htmlFor='flexCheckDefault'
                                    >
                                        {t("common:journal_description")}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <section className={'section d-flex'}>
                                {[
                                    t("common:description"),
                                    t("common:account"),
                                    t("common:debit"),
                                    t("common:credit")
                                ].map((key, index) => {
                                    return (
                                        <input
                                            readOnly
                                            key={index}
                                            className='w-100 cursor-default'
                                            type='text'
                                            name={key}
                                            style={{
                                                border: 'none'
                                                // textAlign: 'left',
                                            }}
                                            onChange={() => null}
                                            value={key}
                                        />
                                    );
                                })}
                            </section>
                        </div>
                        {fields.map((field, index) => {
                            return (
                                <div key={field.id}>
                                    <section
                                        className={'section d-flex'}
                                        key={field.id}
                                    >
                                        <InputCtrl
                                            type='text'
                                            showError={() => null}
                                            placeholder={t("common:description")}
                                            required
                                            control={control}
                                            name={`manualJournalEntries.${index}.description`}
                                            disabled={false}
                                            rootClass='w-100'
                                            onFocus={(tempData) => {
                                                // console.log('tempData', index, tempData);
                                                if (!tempData.value) {
                                                    update(index, {
                                                        ...fields[index],
                                                        description: getValues('narration')
                                                    });
                                                }
                                            }}
                                            className={`form-control rounded-0 ${showErrorForArray('manualJournalEntries', index, 'description') ? 'border-danger' : ''}`}
                                        />
                                        <SelectCtrl
                                            showError={() => null}
                                            placeholder={t("common:account")}
                                            required
                                            control={control}
                                            name={`manualJournalEntries.${index}.accountId`}
                                            disabled={false}
                                            rootClass='w-100 rounded-0'
                                            className={`select-icon-hide rounded-0 ${showErrorForArray('manualJournalEntries', index, 'accountId') ? 'border-danger' : ''}`}
                                            // className='select-icon-hide rounded-0'
                                            selectClassNamePrefix='search-select-1'
                                            options={categories}
                                        />
                                        <InputCtrl
                                            type='text'
                                            showError={() => null}
                                            placeholder={t("common:debit")}
                                            required
                                            control={control}
                                            name={`manualJournalEntries.${index}.debitAmount`}
                                            disabled={false}
                                            rootClass='w-100'
                                            handleChange={(name: string, val: any, e, fn) => {
                                                if (val === '' || val === 0 || val === '0') {
                                                    fn(e);
                                                } else if (val && +val) {
                                                    fn(e);
                                                    setValue(`manualJournalEntries.${index}.creditAmount`, 0);
                                                }
                                            }}
                                            className={`form-control rounded-0 ${showErrorForArray('manualJournalEntries', index, 'debitAmount') ? 'border-danger' : ''}`}
                                        // className='form-control rounded-0'
                                        />
                                        <InputCtrl
                                            type='text'
                                            showError={() => null}
                                            placeholder={t("common:credit")}
                                            required
                                            control={control}
                                            name={`manualJournalEntries.${index}.creditAmount`}
                                            disabled={false}
                                            rootClass='w-100'
                                            handleChange={(name: string, val: any, e, fn) => {
                                                if (val === '' || val === 0 || val === '0') {
                                                    fn(e);
                                                } else if (val && +val) {
                                                    fn(e);
                                                    setValue(`manualJournalEntries.${index}.debitAmount`, 0);
                                                }
                                            }}
                                            className={`form-control rounded-0 ${showErrorForArray('manualJournalEntries', index, 'creditAmount') ? 'border-danger' : ''}`}
                                        // className='form-control rounded-0 '
                                        />
                                        <i
                                            className='bi bi-x h-auto hand'
                                            onClick={() => remove(index)}
                                            style={{
                                                fontSize: '1.5rem',
                                                color: 'grey'
                                            }}
                                        />
                                    </section>
                                </div>
                            );
                        })}
                        <section className='mt-2'>
                            <button
                                className='hand btn btn-light btn-sm text-primary'
                                onClick={() =>
                                    append({
                                        description: getValues('narration'),
                                        creditAmount: 0,
                                        debitAmount: 0
                                    })
                                }
                            >
                                {t("common:new_line")}
                            </button>
                        </section>
                        <div>
                            <section className={'section d-flex'}>
                                {calcFields.map((field: ICalcField, index) => {
                                    return (
                                        <InputCtrl
                                            key={index}
                                            type='text'
                                            showError={() => null}
                                            placeholder={field.label}
                                            required={false}
                                            control={control}
                                            name={field.name}
                                            disabled={false}
                                            rootClass='w-100'
                                            className={`bg-transparent cursor-default form-control rounded-0 border-0 ${field.classes}`} // {``}
                                            defaultValue={field.label}
                                            readOnly
                                        />
                                    );
                                })}
                                <i
                                    className='bi bi-x h-auto hand'
                                    style={{
                                        fontSize: '1.5rem',
                                        color: 'grey',
                                        visibility: 'hidden'
                                    }}
                                />
                            </section>
                            <div className='d-flex justify-content-end me-4'>
                                <hr className='w-75 p-0 m-0  fw-bold text-end' />
                            </div>
                        </div>
                        <div>
                            <section className={'section d-flex'} key={'11'}>
                                {calcFields.map((field: ICalcField, index) => {
                                    return (
                                        <>
                                            <InputCtrl
                                                type='text'
                                                showError={() => null}
                                                placeholder={field.label === t("common:subtotal") ? t("common:total") : ''}
                                                required={false}
                                                control={control}
                                                name={field.name}
                                                disabled={false}
                                                rootClass='w-100'
                                                className={`bg-transparent cursor-default form-control rounded-0 border-0 fw-bold ${field.classes}`} // {``}
                                                defaultValue={field.label === t("common:subtotal") ? t("common:total") : ''}
                                                readOnly
                                            />
                                        </>
                                    );
                                })}
                                <i
                                    className='bi bi-x h-auto hand'
                                    style={{
                                        fontSize: '1.5rem',
                                        color: 'grey',
                                        visibility: 'hidden'
                                    }}
                                />
                            </section>
                            <div className='d-flex justify-content-end me-3'>
                                <hr className='w-75 p-0 m-0  fw-bold text-end' />
                            </div>
                        </div>
                        <div className='mt-4' />
                        <div className='my-3 d-flex justify-content-between'>
                            <div>
                                {manualJournalDetails?._id && (
                                    <div className=''>
                                        <InplaceConfirm
                                            HTMLComponent={
                                                <i
                                                    className='bi bi-trash text-end'
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        color: 'red'
                                                    }}
                                                ></i>
                                            }
                                            action={() => {
                                                console.log('onAction');
                                                onDelete();
                                            }}
                                            target='del-icon'
                                            text={t("common:are_you_sure")}
                                        />
                                    </div>
                                )}
                            </div>
                            <div>
                                <button
                                    type='submit'
                                    className={`btn  btn-sm mx-3  btn-success`}
                                    disabled={apiStatus.inProgress}
                                >
                                    {t("common:save")}
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary btn-sm'
                                    onClick={() => navigate('/manual-journals')}
                                >
                                    {t("common:cancel")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ManualJournalDetails;

import EventEmitter from 'eventemitter3';
import { IAppHeader } from '../interfaces';
export const $updateAppHeader = new EventEmitter();

const appHeaderDefaultValue: IAppHeader = {
	leftIcon: 'Menu',
	leftLink: '',
	title: '',
	showAddIcon: false,
	addLink: ''
};

export const updateAppbar = (
	data: IAppHeader
) => {
	$updateAppHeader.emit('updateAppHeader', data);
};

export const resetAppbar = () => {
	$updateAppHeader.emit('updateAppHeader', appHeaderDefaultValue);
};

import React, { useEffect, useMemo, useState } from 'react';
import { IApiCallStatus, IFile } from '../../interfaces';
import { DEFAULT_PAGE_SIZE, dismissNotification, showNotification } from '../../utils';
import { useNavigate } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import useAppbar from '../../hooks/useAppbar';
import Table from "../../components/Table";
import usePagination from "../../hooks/usePagination";
import { useTranslation } from 'react-i18next';
import { deleteData, getData } from '../../api';
import { API_END_POINT } from '../../constants';
import CustomHeader from '../../components/CustomHeader';
import Popup from '../../components/Popup';
import InplaceConfirm from '../../components/InplaceConfirm';
import { useDownloadFile } from '../../hooks/useDownloadFile';
import { saveAs } from 'file-saver';
const UploadedFiles = () => {
    const { t } = useTranslation();
    const { updateAppbar, resetAppbar } = useAppbar({});
    const { downloadPdf } = useDownloadFile({
        preDownloading: (notificationUid: string | undefined) => {
            showNotification('info', t("common:download_file"), notificationUid);
        },
        postDownloading: (notificationUid: any) => {
            dismissNotification(notificationUid);
            showNotification('success', t('common:downloaded'));
        },
        onError: (notificationUid: any, message: string) => {
            dismissNotification(notificationUid);
            showNotification('error', message);
        }
    });
    const [files, page, onUpdateData] = usePagination({
        pageSize: DEFAULT_PAGE_SIZE
    })
    const [openFile, setOpenFile] = useState<any>();
    const [popupIsOpenForImage, setPopupIsOpenForImage] = useState<boolean>(false);

    const [apiStatus, setApiStatus] = useState<IApiCallStatus>({
        failMessage: '',
        failed: false,
        inProgress: false
    });

    const downloadFile = (fileUrl: any, fileName: string) => {
        saveAs(fileUrl, fileName);
    };

    const changeApiStatus = (progress: boolean, message: string) => {
        setApiStatus({
            inProgress: progress,
            failed: !!message,
            failMessage: message
        });
    };
    const navigate = useNavigate()
    const fetchData = async (
        pageNumber = page.current,
        pageSize = page.pageSize,
        search = ''
    ) => {
        try {
            changeApiStatus(true, '');
            const usersResponse = await getData('/api/user/me')
            if (usersResponse.status === 200) {
                console.log(usersResponse.data[0].files)
                onUpdateData({
                    ...usersResponse.data,
                    items: usersResponse.data[0].files,
                    current: pageNumber
                })
                changeApiStatus(false, '');
            } else {
                throw new Error(usersResponse.error);
            }
        } catch (error: any) {
            changeApiStatus(false, error.message);
            showNotification('error', error.message);
        }
    };
    const viewImage = (file: IFile) => {
        console.log(file)
        setOpenFile(file)
        setPopupIsOpenForImage(true)
    }
    const onDelete = async (id: string) => {
        const notificationUid = 'DeleteUploadedDocuments';
        try {
            changeApiStatus(true, '');
            showNotification(
                'info',
                t("common:request_process"),
                notificationUid
            );

            const deleteRes = await deleteData(API_END_POINT.file.delete, id)
            dismissNotification(notificationUid);
            if (deleteRes.status === 200) {
                changeApiStatus(false, '')
                fetchData(1);
                showNotification('success', deleteRes.message);
            } else {
                throw new Error(deleteRes.error);
            }
        } catch (err: any) {
            showNotification('error', err.message);
            changeApiStatus(false, err.message);
        }
    };

    useEffect(() => {
        // Check if the file is a PDF and open it in a new tab
        if (popupIsOpenForImage && openFile && openFile.fileUrl && openFile.fileUrl.split(".").pop() === "pdf") {
            const newTab = window.open(openFile.fullFileUrl, '_blank');
            if (newTab) newTab.opener = null;
            setPopupIsOpenForImage(false);
        } else if (popupIsOpenForImage && openFile && openFile.fileUrl && openFile.fileUrl.split(".").pop() === "zip") {
            setPopupIsOpenForImage(false);
        }
    }, [popupIsOpenForImage, openFile]);

    const columns = useMemo(
        () => [
            {
                Header: t("common:s_no"),
                accessor: (originalRow: any, index: any, c: any, d: any) => {
                    return <>{index + 1}</>;
                }
            },
            {
                Header: t("common:file_names"),
                accessor: 'originalName'
            },
            {
                Header: t("common:actions"),
                accessor: (originalRow: any) => {
                    // console.log(originalRow)
                    const fileId = originalRow?.fileId
                    const fileName = originalRow?.originalName
                    // const originalName = originalRow?.originalName
                    const isZip = originalRow?.fileName.split('.').pop() === 'zip'

                    return <>
                        <div className='d-flex justify-content-around' >
                            <button className='btn p-0' disabled={isZip} onClick={() => viewImage(originalRow)}>
                                <i className="bi bi-file-earmark-text" style={{ fontSize: '1.3rem', color: (fileId && !isZip) ? 'green' : 'grey' }} />
                            </button>
                            <button className="btn p-0" disabled={!fileId} onClick={() => { downloadPdf(originalRow.fullFileUrl, fileName) }}>
                                <i style={{ fontSize: '1.3rem', color: fileId ? 'green' : 'grey' }} className="bi bi-download"></i>
                            </button>
                            <InplaceConfirm
                                HTMLComponent={<i className="bi bi-trash text-end hand" style={{ fontSize: '1.3rem', color: 'red' }}></i>}
                                action={() => {
                                    onDelete(originalRow._id);
                                }}
                                target={`del-icon-${originalRow._id}`}
                                text={t("common:are_you_sure")}
                            />
                        </div>
                    </>;
                }
            }

        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
        updateAppbar({
            leftLink: '',
            leftIcon: 'Menu',
            title: t("common:company_documents"),
            showAddIcon: false,
            addLink: '/company-docs/new'
        });
        return () => {
            resetAppbar();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='contact-list'>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "flex-end",
                margin: 'none'
            }} >
                <button
                    onClick={() => {
                        const fileIds = files.map((val) => val._id)
                        console.log(fileIds)
                        navigate('/company-docs/new', { state: fileIds })
                    }
                    }
                    style={{
                        color: 'white',
                        textDecoration: 'none',
                        background: '#243a77',
                        border: 'none',
                        cursor: 'pointer',
                        padding: '7px',
                        borderRadius: '7px',
                        marginTop: '20px',
                        marginBottom: '30px'
                    }}>
                    {t("common:upload_new_docs")} </button>
            </div>
            <Popup isOpen={popupIsOpenForImage} onCloseModal={() => setPopupIsOpenForImage(false)}>
                <CustomHeader title="" backFn={() => setPopupIsOpenForImage(false)} />
                <div style={{
                    width: "100%",
                    height: "100%"
                }}>
                    {openFile && openFile.fileUrl && (
                        <>
                            {openFile.fileUrl.split(".").pop() === "pdf"
                                ? <iframe src={openFile.fullFileUrl} style={{
                                    width: "100%",
                                    height: "100%"
                                }} />
                                : <>
                                <div style={{ width: "100%", textAlign: "right", marginRight: '50px' }}>
                                <button className="btn p-0" style={{ color: "blue" }} onClick={() => { downloadFile(openFile.fullFileUrl, openFile.originalName) }}>
                                    {t("common:download_my_file")}
                                </button>
                                </div>
                                <div style={{ width: "100%", height: "100%" }}>
                                <img width="100%" height="auto" src={openFile.fullFileUrl} alt="text" className="mt-4 mb-3" />
                                </div>
                                </>}
                        </>)}

                </div>
            </Popup>
            {apiStatus.inProgress
                ? t("common:load")
                : (
                    <>
                        <div className='page-body'>
                            <ScrollToTop smooth className="ScrollToTop" component={<i className="bi bi-arrow-up-circle"></i>} />
                            <Table columns={columns} data={files} />
                        </div>

                    </>
                )}
        </div>
    );
};

export default UploadedFiles;

import { UserDataKey } from '.';

export const isLoggedIn = () => {
    return getCookie(UserDataKey);
};

export const createCookie = (
    name: string,
    value: string,
    domain = '/',
    days = 7
) => {
    console.log('domain', domain);
    // 1 week valid
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie =
        name + '=' + value + ';' + expires + ';path=/';
};

export const setCookie = (cname: string, cvalue: any, exdays = 7) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    const value = JSON.stringify(cvalue);
    document.cookie = cname + '=' + value + ';' + expires + ';path=/';
};

export const getCookie = (name: string) => {
    const cookie = document.cookie;
    const value =
        cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    return value ? JSON.parse(value) : null;
};

export const clearCookies = () => {
    document.cookie = UserDataKey + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const valueFromUserData = (key: string) => {
    const UserData: any = getCookie(UserDataKey);
    if (UserData !== undefined && UserData !== null) {
        return UserData[key];
    } else {
        return '';
    }
};

export const getLocalUserData = () => {
    const UserData: any = getCookie(UserDataKey);
    return UserData;
};

export const updateLocalUserData = (data: { [x: string]: any; }) => {
    const UserData: any = getCookie(UserDataKey);
    let tempData = {}
    if (UserData !== undefined && UserData !== null) {
        tempData = UserData;
    }
    tempData = {
        ...tempData,
        ...data
    }
    setCookie(UserDataKey, tempData, 7);
};

import { postData, getDataWithQuery } from '../api';

import { API_END_POINT } from '../constants';

export const userLogin = (data: any) => {
	return postData(API_END_POINT.login, data);
};

export const startOTP = (data: any) => {
    return postData(API_END_POINT.users.otpStart, data)
};

export const checkOTP = (data: any) => {
    return postData(API_END_POINT.users.otpCheck, data)
};

export const getUserDetailsUsingToken = () => {
    return getDataWithQuery(API_END_POINT.users.me, {});
}

// Validate and signin admin as client
export const validateAdminUsingToken = (token: string) => {
    return getDataWithQuery(API_END_POINT.users.adminLogin, { data: token });
}

export const updateUserDetails = (data: any) => {
    return postData(API_END_POINT.users.update, data);
}

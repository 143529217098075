import { postData, getData, getDataWithQuery, deleteData, deleteDatabyParam } from '../api';
import { DeleteParams } from '../interfaces';
import { API_END_POINT } from '../constants';

export const saveManualJournal = (data: any) => {
	return postData(API_END_POINT.manualJournal.save, data);
};

export const getManualJournalById = (id: string) => {
	return getData(API_END_POINT.manualJournal.getById, id);
};

export const getAllManualJournal = (data: any) => {
	return getDataWithQuery(API_END_POINT.manualJournal.getAll, data);
};

export const getFinancialReports = (data: any) => {
	return getDataWithQuery(API_END_POINT.manualJournal.getFinancialReports, data);
};

export const deleteManualJournal = (id: string) => {
	return deleteData(API_END_POINT.manualJournal.delete, id);
};
export const deleteManualJournalByParams = (param: DeleteParams) => {
	return deleteDatabyParam(API_END_POINT.manualJournal.delete, param);
};

import { postData, getData, getDataWithQuery, deleteData } from '../api';

import { API_END_POINT } from '../constants';

export const saveTransaction = (data: any) => {
	return postData(API_END_POINT.transaction.save, data);
};

export const getTransactionById = (id: string) => {
	return getData(API_END_POINT.transaction.getById, id);
};

export const getAllTransaction = (data: any) => {
	return getDataWithQuery(API_END_POINT.transaction.getAll, data);
};

export const getAllDateRange = () => {
	return getDataWithQuery(API_END_POINT.transaction.getAllDateRange, {});
};

export const deleteTransaction = (id: string) => {
	return deleteData(API_END_POINT.transaction.delete, id);
};

export const getBankTransactionById = (id: string) => {
	return getData(API_END_POINT.transaction.bankTranById, id);
};

export const getAllBankTransaction = (data: any) => {
	return getDataWithQuery(API_END_POINT.transaction.bankTrans, data);
};

export const deleteBankTransaction = (id: string) => {
	return deleteData(API_END_POINT.transaction.bankTranDelete, id);
};

export const reconcileBankTransaction = (data: {id: string, transIds: Array<string>}) => {
	return postData(API_END_POINT.transaction.bankTranReconcile, data);
};

export const saveBocTransactions = (data: any) => {
	return postData(API_END_POINT.saveBankTransactions.saveBocTransactions, data);
};

export const savePaoTransactions = (data: any) => {
	return postData(API_END_POINT.saveBankTransactions.savePaoTransactions, data);
};

export const saveScbTransactions = (data: any) => {
	return postData(API_END_POINT.saveBankTransactions.saveScbTransactions, data);
};
